import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Order } from 'src/app/classes/biermi';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'biermi-order-location',
  templateUrl: './order-location.component.html',
  styleUrls: ['./order-location.component.scss']
})
export class OrderLocationComponent implements OnInit, OnDestroy {

	@Input('order') order: Order;

	vehicleLocation;
	lastUpdated;
	lastUpdatedText;
	lastUpdatedInterval;
	lastUpdatedWarning: boolean;

	socketsAvailable: boolean;
	status: string = 'Loading';
	websocket;
	HEARTBEAT_INTERVAL = 30 * 1000;
	websocketHeartbeatInterval;
	stayConnected: boolean = true;

	constructor() {}

	ngOnInit() {
		this.connect();
		this.lastUpdatedInterval = setInterval(() => {
			this.updateLastUpdated();
		}, 60 *  1000);
	}

	ngOnDestroy() {
		this.stayConnected = false;
		this.websocket.close();
		if(this.websocketHeartbeatInterval) {
			clearInterval(this.websocketHeartbeatInterval);
		}

		if(this.lastUpdatedInterval) {
			clearInterval(this.lastUpdatedInterval);
		}
	}

	updateLastUpdated() {
		if(this.lastUpdated) {
			this.lastUpdatedText = moment(new Date(this.lastUpdated)).fromNow();
			if(moment(new Date(this.lastUpdated)).add(5, 'minute') < moment()) {
				this.lastUpdatedWarning = true;
			} else {
				this.lastUpdatedWarning = false;
			}
		}
	}

	/*************************************************
	 *
	 * Websocket Handling
	 *
	 *************************************************/

	async connect() {
		if ('WebSocket' in window) {
			this.socketsAvailable = true;
			this.createConnection();
		} else {
			this.socketsAvailable = false;
		}
	}

	// Creates a websocket connection and hooks up all of the events
	private createConnection() {
		this.websocket = new WebSocket(`${environment.wss}?orderId=${this.order.uuid}`);

		// Setup listeners
		this.websocket.onopen = () => {
			this.websocketConnected();
		};

		this.websocket.onmessage = (event) => {
			const message = JSON.parse(event.data);
			const subject = message.subject;
			const body = message.body;

			if(subject == 'heartbeat-response') {
				this.websocket.isAlive = true;
				return;
			}

			switch(subject) {
				case 'location-update':
					this.vehicleLocation = {
						latitude: body.location.latitude,
						longitude: body.location.longitude
					}
					if(body.location.lastUpdated) {
						this.lastUpdated = body.location.lastUpdated
						this.updateLastUpdated();
					}
					break;
			}
		};

		this.websocket.onclose = (error) => {
			this.status = 'Disconnected';
			console.log('socket connection closed', error)
			this.reconnect();
		};

		this.websocket.onerror = (error) => {
			console.log(error);
		}
	}

	websocketConnected() {
		this.status = 'Connected';
		this.websocket.isAlive = true;
		this.setupHeartbeat();
		this.sendWebsocketMessage('get-location', {});
	}

	// Setup a heartbeat to detect when we drop a websocket connection
	private setupHeartbeat() {
		// Setup heartbeat
		if(this.websocketHeartbeatInterval) {
			clearInterval(this.websocketHeartbeatInterval);
		}
		this.websocketHeartbeatInterval = setInterval(() => {
			if(this.websocket.isAlive === false) {
				this.disconnect();
				return;
			}
			this.websocket.isAlive = false;
			this.sendWebsocketMessage('heartbeat', {});
		}, this.HEARTBEAT_INTERVAL);
	}

	// Called when our heartbeat fails
	private disconnect() {
		this.websocket.close();
		if(this.websocketHeartbeatInterval) {
			clearInterval(this.websocketHeartbeatInterval);
		}
		this.reconnect();
	}

	// Called to initiate a reconnect with a guard if we're in the process of destroying
	private reconnect() {
		if(this.stayConnected) {
			this.status = 'Reconnecting';
			setTimeout(() => {
				this.createConnection();
			}, 2 * 1000);
		}
	}

	// Wraps up and sends a socket message
	private sendWebsocketMessage(subject, body) {
		this.websocket.send(JSON.stringify({
			subject: subject,
			body: body
		}));
	}
}
