import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  	providedIn: 'root'
})
export class ModalService {

	@Output() confirmation: EventEmitter<any> = new EventEmitter();
	@Output() toast: EventEmitter<any> = new EventEmitter();

	constructor() { }

	confirm(title: string, body: string, callback: Function, cancelCallback?: Function) {
		this.confirmation.emit({ title: title, body: body, type: 'confirm', callback: callback, cancelCallback: cancelCallback });
	}

	alert(title: string, body: string, callback?: Function) {
		this.confirmation.emit({ title: title, body: body, type: 'alert', callback: callback });
	}

	importUntappd(title: string, body: string, multiple: boolean, exclude: any[], callback: Function, cancelCallback?: Function) {
		this.confirmation.emit({ title: title, body: body, type: 'untappd-import', multiple: multiple, exclude: exclude, callback: callback });
	}

	showToast(message: string) {
		this.toast.emit({
			message: message
		})
	}
}
