import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'biermi-process',
	templateUrl: './process.component.html',
	styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit {

	@Input('steps') steps: [];
	@Input('step') step: number;

	constructor() { }

	ngOnInit() {}

}
