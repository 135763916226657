import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { User, Session } from 'src/app/classes/biermi';

declare var $:any;

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	authenticated: boolean;
	user: User;
	accessToken: string;
	refreshToken: string;
	redirectUrl: string;

	constructor(private router: Router, public http: HttpClient, private api: ApiService) {}

	handleApiError(error) {
		if (error.rejection.status === 401 || error.rejection.status === 403) {
			this.logout();
		}
		throw error;
	}

	setRedirectUrl(url: string) {
		// Don't redirect to weird stuff
		if(url == '' || url == '/' || url.substring(0, 5) == '/auth' || url.substring(0, 6) == '/legal' || url.substring(0, 7) == '/public') {
			this.redirectUrl = null;
		} else {
			this.redirectUrl = url;
		}
	}

	setUser(user: User) {
		this.user = user;
		localStorage.setItem('user', JSON.stringify(user));
	}

	getUser(): User {
		if(this.user) {
			return this.user;
		}
		return JSON.parse(localStorage.getItem('user'));
	}

	getRedirectUrl() {
		let url = this.redirectUrl;
		this.redirectUrl = null;
		return url;
	}

	setAccessTokens(accessToken, refreshToken?) {
		this.accessToken = accessToken;
		localStorage.setItem('accessToken', accessToken);

		if(refreshToken) {
			this.refreshToken = refreshToken;
			localStorage.setItem('refreshToken', refreshToken);
		}
	}

	getAccessToken() {
		if(this.accessToken) {
			return this.accessToken;
		}
		let token = localStorage.getItem('accessToken');
		return token ? token : '';
	}

	getRefreshToken(): Observable<string> {
		let refreshToken = this.refreshToken;
        if(!refreshToken) {
			refreshToken = localStorage.getItem('refreshToken');
		}

		return this.api.authRefreshToken(refreshToken).pipe(map((res: Session) => {
			if(res.token.length > 0 && res.refreshToken.length > 0) {
				this.setAccessTokens(res.token, res.refreshToken);
				return res.token;
			}
			return null;
		}));
    }

	isLoggedIn(): boolean {
		let token = this.getAccessToken();
		if(!token) {
			return false;
		}
		return true;
	}

	logout(saveLocation?: boolean) {
		this.authenticated = false;

		this.accessToken = null;
		this.refreshToken = null;

		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('user');

		// this.analytics.setUser('');
		$('.modal').modal('hide');

		if(saveLocation) {
			this.setRedirectUrl(this.router.url);
			this.router.navigate(['/authentication/login']);
		} else {
			this.router.navigate(['/']);
		}
	}

	hasRole(roleTitle) {
		let user = this.getUser();
		if(!user || !user.roles) {
			return false;
		}
		for(var i=0; i<user.roles.length; i++) {
			var role = user.roles[i];
			if(role.title == roleTitle) {
				return true;
			}
		}
		return false;
	}

	isManager() {
		if(this.isSuperAdministrator() || this.isAdministrator()) {
			return true;
		}
		var user = this.getUser();
		if(!user) {
			return false;
		}
		var brewery = user.breweries.find(o => o.id == this.api.breweryId);
		if(!brewery) {
			brewery = user.breweries[0];
		}
		return brewery.roleId <= 2;
	}

	isAdministrator() {
		if(this.isSuperAdministrator()) {
			return true;
		}
		var user = this.getUser();
		if(!user) {
			return false;
		}
		var brewery = user.breweries.find(o => o.id == this.api.breweryId);
		if(!brewery) {
			brewery = user.breweries[0];
		}

		return brewery.roleId == 1;
	}

	isSuperAdministrator() {
		return this.hasRole('administrator');
	}
}