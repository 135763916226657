import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'biermi-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	  constructor() { }

	ngOnInit() {
	}

}
