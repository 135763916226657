import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

	transform(time: any, ...args: any[]): any {
		var hours = parseInt(('0000' + time).slice(-4).slice(0, 2), 10);
		var minutes = parseInt(('0000' + time).slice(-4).slice(2), 10);

		if(isNaN(hours) || isNaN(minutes)) {
			return '';
		}

		var dayHalf = 'am';
		if(hours >= 12 && hours != 24) {
			dayHalf = 'pm';
		}
		if(hours > 12) {
			hours = hours % 12;
		}
		if(hours == 0) {
			hours = 12;
		}

		var minutesText = ('0' + minutes).slice(-2);
		var result = `${hours}:${minutesText}${dayHalf}`;
		if(result == '12:00am') {
			return 'Midnight';
		} else if(result == '12:00pm') {
			return 'Noon';
		}
		return result;
	}

}