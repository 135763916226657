import { Component, OnInit, Input } from '@angular/core';
import { Brewery } from 'src/app/classes/biermi';

@Component({
  selector: 'biermi-brewery-card',
  templateUrl: './brewery-card.component.html',
  styleUrls: ['./brewery-card.component.scss']
})
export class BreweryCardComponent implements OnInit {

	@Input('brewery') brewery: Brewery;

	constructor() { }

	ngOnInit() {
	}

}
