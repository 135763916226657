export class Category {
	id: number;
	breweryId: number;
	title: string = '';
	salesTax: number;
	rank: number;
	marketplaceVisible: boolean = true;
	alcohol: boolean;
	beer: boolean;
	spirits: boolean;
	wine: boolean;
	cider: boolean;

	// View
	editing: boolean;
}
