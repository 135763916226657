export class TaxRate {
	id: number;
	breweryId: number;
	dineIn: boolean;
	pickup: boolean;
	delivery: boolean;
	shipping: boolean;
	digital: boolean;

	itemCategoryId: number;
	breweryLocationId: number;
	breweryDeliveryZoneId: number;
	shippingStateCode: string;
	postalCode: string;

	rate: number;

	// View helpers
	editing: boolean;
	fulfillmentMethod: string;
	error: string;
}
