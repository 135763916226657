export class PlaceDetails {
	street_number: string;					// 1621
	route: string;							// Catharine Street
	neighborhood: string;					// Graduate Hospital
	locality: string;						// Philadelphia
	administrative_area_level_2: string;	// Philadelphia County
	administrative_area_level_1: string;	// Pennsylvania
	country: string;						// United States
	postal_code: string;					// 19146
	postal_code_suffix: string;				// 2020

	stateCode: string;	// Pennsylvania
}

export class Place {
	place_id: string;
	name: string;
	formatted_address: string;
	latitude: number;
	longitude: number;
	details: PlaceDetails = new PlaceDetails();

	constructor(result?) {
		this.name = result.name;
			this.formatted_address = result.formatted_address;
			this.place_id = result.place_id;

			if(result.geometry) {
				this.latitude = result.geometry.location.lat();
				this.longitude = result.geometry.location.lng();
			}

			var pieces = result['address_components'];
			if(!pieces) {
				return;
			}
			pieces.forEach(piece => {
				if(piece.types.find(t => t == 'street_number')) {
					this.details.street_number = piece.long_name;
				} else if(piece.types.find(t => t == 'route')) {
					this.details.route = piece.long_name;
				} else if(piece.types.find(t => t == 'neighborhood')) {
					this.details.neighborhood = piece.long_name;
				} else if(piece.types.find(t => t == 'locality')) {
					this.details.locality = piece.long_name;
				} else if(piece.types.find(t => t == 'administrative_area_level_2')) {
					this.details.administrative_area_level_2 = piece.long_name;
				} else if(piece.types.find(t => t == 'administrative_area_level_1')) {
					this.details.administrative_area_level_1 = piece.long_name;
					this.details.stateCode = piece.short_name;
				} else if(piece.types.find(t => t == 'country')) {
					this.details.country = piece.long_name;
				} else if(piece.types.find(t => t == 'postal_code')) {
					this.details.postal_code = piece.long_name;
				} else if(piece.types.find(t => t == 'postal_code_suffix')) {
					this.details.postal_code_suffix = piece.long_name;
				}
			});
	}
}
