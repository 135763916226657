import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { Brewery } from 'src/app/classes/biermi';

declare var $: any;

@Component({
	selector: 'app-features',
	templateUrl: './features.component.html',
	styleUrls: ['../public.scss', './features.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FeaturesComponent implements OnInit {

	loading;
	selectedSection: string;
	testimonal;
	testimonalIndex = 0;
	breweries: Brewery[];

	constructor(private api: ApiService) { }

	ngOnInit() {
		this.load();
	}

	load() {
		$('html, body').animate({
			scrollTop: 0
		}, 400);
		this.loading = true;
		this.api.getBreweriesLive().subscribe((breweries: Brewery[]) => {
			this.breweries = this.shuffle(breweries);
			this.loading = false;
		})
	}

	selectSection(section: string) {
		this.selectedSection = section;
		setTimeout(() => {
			$('html, body').animate({
				scrollTop: $(`.intro-section`).outerHeight(),
			}, 400);
		}, 20);
	}

	shuffle(array) {
		var currentIndex = array.length, temporaryValue, randomIndex;
		while (0 !== currentIndex) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}

		return array;
	}
}