export class CODE_TYPES {
	EARLY_ACCESS = 1;
	FREE_SHIPPING = 2;
	DISCOUNT_PERCENTAGE = 3;
	DISCOUNT_AMOUNT = 4;
}

export class Code {
	id: number;
	breweryId: number;
	codeTypeId: number;
	code: string;
	pickupEnabled: boolean = true;
	deliveryEnabled: boolean = true;
	shippingEnabled: boolean = true;
	dineInEnabled: boolean = true;
	digitalEnabled: boolean = true;
	amount: number;
	appliedLevel: number;
	appliedVolume: number;
	itemCategoryId: number;
	beerId: number;
	dayOfWeek: number;
	singleUse: boolean;
	redeemed: boolean;
	addressBound: boolean;
	address: string;
	activation;
	expiration;
	active: boolean;

	// View Helpers
	showDetails: boolean;
}
