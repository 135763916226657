import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-about',
	templateUrl: './about.component.html',
	styleUrls: ['../public.scss', './about.component.scss']
})
export class AboutComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
