import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Brewery, BreweryLocation, Order } from 'src/app/classes/biermi';
import { ApiService } from 'src/app/services/api/api.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';

@Component({
	selector: 'app-pickup',
	templateUrl: './pickup.component.html',
	styleUrls: ['../public.scss', './pickup.component.scss']
})
export class PickupComponent implements OnInit {

	loading: boolean = true;
	error;
	orderUuid: string;
	order: Order;
	brewery: Brewery;
	redemptionBarcode: string;

	pickupLocation: BreweryLocation;
	viewPickupSpot: boolean;
	checkedIn: boolean;

	makes = [];
	models = [];
	colors = [];

	submitting: boolean;
	errorSubmitting;

	constructor(private route: ActivatedRoute, private api: ApiService, private vehicleService: VehicleService) {
		this.route.params.subscribe(params => {
			this.orderUuid = params['orderUuid'];
			this.load();
			vehicleService.vehicles.forEach(vehicle => {
				if(this.makes.indexOf(vehicle.make) == -1) {
					this.makes.push(vehicle.make);
				}
			});
			this.makes.sort();
			this.colors = vehicleService.colors.sort();
		});
	}

	ngOnInit() {}

	load() {
		this.loading = true;
		setTimeout(() => {
			this.api.getOrder(this.orderUuid).subscribe((order: Order) => {
				this.order = order;
				this.api.getBreweryBasic(this.order.breweryId).subscribe((brewery: Brewery) => {
					this.brewery = brewery;
					this.pickupLocation = this.brewery.locations.find(l => l.id == this.order.pickupLocationId);
					if(this.pickupLocation.pickupSpotNumberEnabled) {
						this.viewPickupSpot = true;
					}
					this.loading = false;
				}, error => {
					this.error = error;
					this.loading = false;
				});
			}, error => {
				this.error = error;
				this.loading = false;
			});
		}, 500);
	}

	getConfirmationNumber() {
		return this.order.uuid.slice(-8).toUpperCase();
	}

	addVehicleMake(make) {
		return make;
	}

	onVehicleMakeChange() {
		this.models = [];
		this.vehicleService.vehicles.forEach(vehicle => {
			if(this.models.indexOf(vehicle.model) == -1 && vehicle.make == this.order.pickupVehicleMake) {
				this.models.push(vehicle.model);
			}
		});
	}

	addVehicleModel(model) {
		return model;
	}

	onVehicleModelChange() {

	}

	addVehicleColor(color) {
		return color;
	}

	onVehicleColorChange() {

	}

	submit() {
		this.submitting = true;
		this.errorSubmitting = null;

		this.api.putOrderCheckin(this.order).subscribe(() => {
			this.order.pickupCheckedIn = true;
			this.submitting = false;
		}, error => {
			this.submitting = false;
			this.errorSubmitting = `There was an error checking you in.`;
		});
	}
}
