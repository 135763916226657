import { Component, OnInit } from '@angular/core';
import { MediaService } from 'src/app/services/media/media.service';

@Component({
	selector: 'biermi-media',
	templateUrl: './media.component.html',
	styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

	outlets;

	constructor() {
		this.outlets = MediaService.posts;
	}

	ngOnInit() {}
}
