import { Component, OnInit, Input } from '@angular/core';
import { Order } from 'src/app/classes/biermi';

@Component({
	selector: 'biermi-order-status',
	templateUrl: './order-status.component.html',
	styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit {

	@Input('order') order: Order;

	constructor() { }

	ngOnInit() {
	}

}
