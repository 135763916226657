export class BiermiAnalyticsEvent {
	static EVENT_TYPE = {
		AddressSelect: 1,
		AgeGate: 2
	}
}

export class AnalyticsEvent {
	event_category: string;
	event_label: string;
	value: string;

	constructor(event_category?: string, event_label?: string, value?: string) {
		this.event_category = event_category;
		this.event_label = event_label;
		this.value = value;
	}

	getGTag() {
		let gtag = {};

		if(this.event_category) {
			gtag['event_category'] = this.event_category;
		}
		if(this.event_label) {
			gtag['event_label'] = this.event_label;
		}
		if(this.value) {
			gtag['value'] = this.value;
		}

		return gtag;
	}
}
