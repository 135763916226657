import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'biermi-input-percentage',
	templateUrl: './input-percentage.component.html',
	styleUrls: ['./input-percentage.component.scss']
})
export class InputPercentageComponent implements OnInit {

	@Input() percentage: number;
	@Output() percentageChange: EventEmitter<number> = new EventEmitter<number>();

	constructor() { }

	ngOnInit() {
	}

	input(targetValue) {
		this.percentage = targetValue / 100;
		this.percentageChange.emit(this.percentage);
	}

	changed() {
		this.percentageChange.emit(this.percentage);
	}
}
