import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/classes/biermi';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})
export class JoinComponent implements OnInit {

	user: User = new User();
	submitting: boolean;
	submitted: boolean;

	constructor(private api: ApiService) { }

	ngOnInit() {
	}

	valid() {
		if(this.user.firstName.length < 1) {
			return false;
		}
		if(this.user.lastName.length < 1) {
			return false;
		}
		if(this.user.email.length < 1) {
			return false;
		}
		if(this.user.phone.length < 1) {
			return false;
		}
		if(this.user.city.length < 1) {
			return false;
		}
		if(this.user.state.length < 1) {
			return false;
		}
		if(this.user.dateOfBirth.length < 1) {
			return false;
		}
		return true;
	}

	submit() {
		this.submitting = true;

		this.api.postContactApplicationsDrivers(this.user).subscribe(() => {
			this.submitted = true;
			this.submitting = false;
			this.user = new User();
			setTimeout(() => {
				this.submitted = false;
			}, 30 * 1000);
		});
	}
}
