import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';

declare var $: any;

@Component({
  selector: 'app-public-container',
  templateUrl: './public-container.component.html',
  styleUrls: ['./public-container.component.scss']
})
export class PublicContainerComponent implements OnInit {

	viewNavigation: boolean = false;
	currentPage: string;

	constructor(private router: Router, private route: ActivatedRoute, private api: ApiService) {
		router.events.subscribe(route => {
			if(route instanceof NavigationEnd) {
				this.currentPage = route.urlAfterRedirects;
				this.viewNavigation = false;
				$('html, body').animate({
					scrollTop: 0
				}, 400);
			}
		});
	}

	ngOnInit() {}

	toggleNavigation() {
		this.viewNavigation = !this.viewNavigation;
	}

	closeNavigation() {
		this.viewNavigation = false;
	}
}
