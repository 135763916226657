import { Component, OnInit, ViewChild, NgZone, PLATFORM_ID } from '@angular/core';
import { Place, Brewery } from 'src/app/classes/biermi';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { SearchService } from 'src/app/services/search/search.service';
import { DeliveryZoneService } from 'src/app/services/delivery-zone/delivery-zone.service';
import { GeographyService } from 'src/app/services/geography/geography.service';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['../public.scss', './search.component.scss']
})
export class SearchComponent implements OnInit {

	place: Place;

	showAddressField: boolean;
	viewSearch: boolean = true;
	searching: boolean;
	viewSearchResults: boolean;
	autocompleteInput: string;
	queryWait: boolean;
	breweries;

	// Filters
	searchFilterPickup: boolean = true;
	searchFilterDelivery: boolean = true;

	// Unlisted
	unlistedBreweries;

	precannedMessages = [
		`Hey, I am a fan of your brewery and I was shopping on biermi and noticed that you are not on biermi yet. I love your beer but I would love it even more if it was listed: https://biermi.com/`,
		`Hey gang, I was just cruising biermi and didn’t see you guys on there. I’d love to see you guys listed on there so I can buy your beer. Thanks and hopefully see you soon! https://biermi.com/`,
		`I love what you’re doing with your beers! While I’ve been checking out other breweries on biermi because it’s easy, I wish I could grab your beer there, too. Are you guys considering joining? https://biermi.com/`,
		`Hey, I just wanna let you know that I really love your beer and I’d love even more to see it on biermi. Have you guys thought about selling through biermi? https://biermi.com/`,
		`Hey crew, huge fan of your beer. I was checking biermi though and I noticed you’re not listed there. I’d love to support you guys by buying some of your beer through biermi. Are you planning to join it? https://biermi.com/`,
		`Hey, have you considered joining biermi? I’ve been checking out a bunch of local breweries and noticed you weren’t listed. I’d love to be able to buy your beer there, too! https://biermi.com/`,
		`I’m a fan of your brewery and would really love to be able to buy your beer on biermi. Would you consider listing some beer there? https://biermi.com/`,
		`You make great beer but it’d be a lot easier for me to buy it if you were listed on biermi. Have you thought about joining? I’d definitely buy some beer from you there if I could! https://biermi.com/`,
		`I’m thirsty as heck for your beer right now but I couldn’t find it listed on biermi! Would you consider listing it there? I’m there buying beer on biermi already and would love to add your beer to my list as well! https://biermi.com/`
	];

	@ViewChild('addresstext', { static: false }) addresstext: any;

	constructor(private route: ActivatedRoute, private router: Router, private api: ApiService, private geographyService: GeographyService, private searchService: SearchService, private deliveryZoneService: DeliveryZoneService) {}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			if(params.filterPickup != null) {
				this.searchFilterPickup = params.filterPickup == 'true';
			}
			if(params.filterDelivery != null) {
				this.searchFilterDelivery = params.filterDelivery == 'true';
			}

			if(!this.place && params.pid) {
				this.searching = true;
				this.geographyService.getPlaceFromId(params.pid).then((place: Place) => {
					this.deliveryAddressEntered(place);
				}, error => {
					this.searching = false;
				});
			}
		});
	}

	deliveryAddressEntered(place: Place) {
		this.place = place;
		this.search();
	}

	search() {
		this.searching = true;
		this.viewSearch = false;

		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: {
				pid: this.place.place_id,
				filterPickup: this.searchFilterPickup,
				filterDelivery: this.searchFilterDelivery
			},
			queryParamsHandling: 'merge'
		});

		this.searchService.setPlace(this.place);

		this.api.search(this.place.latitude, this.place.longitude, this.place.details.stateCode).subscribe((response: any) => {
			this.breweries = response.live.filter((b: Brewery) => {
				return (
					(this.searchFilterPickup && b.pickupEnabled) ||
					(this.searchFilterDelivery && (b.deliveryEnabled || b.shippingEnabled))
				);
			});
			this.unlistedBreweries = response.unlisted;
			this.unlistedBreweries.forEach(b => b.mailtoLink = 'mailto:' + b.EmailAddress + '?subject=biermi&body=' + this.getRandomBody());
			this.searching = false;
			this.viewSearchResults = true;
			this.breweries.forEach((brewery, index) => {
				setTimeout(() => {
					brewery.visible = true;
				}, 100 * index);
			});
		});
	}

	filtersChanged() {
		this.search();
	}

	getOfferings(brewery: Brewery) {
		var offerings = [];
		if(brewery.deliveryEnabled) {
			offerings.push('delivery');
		}
		if(brewery.pickupEnabled) {
			offerings.push('pick-up');
		}
		return offerings.join(', ');
	}

	getDelivers(brewery: Brewery) {
		return brewery.deliveryEnabled && this.deliveryZoneService.getDeliveryZone(brewery, this.place) ? true : false;
	}

	searchAgain() {
		this.viewSearchResults = false;
		this.autocompleteInput = '';
		this.unlistedBreweries = null;
		this.place = null;
		this.breweries = null;
		this.viewSearch = true;
	}

	navigateToBrewery(brewery) {
		this.router.navigate(['/', 'store', brewery.slug]);
	}

	getRandomBody() {
		return encodeURI(this.precannedMessages[Math.floor(Math.random() * this.precannedMessages.length)]);
	}
}
