import { Component, OnInit, Input } from '@angular/core';
import { Order } from 'src/app/classes/biermi';

@Component({
	selector: 'biermi-order-status-delivery',
	templateUrl: './order-status-delivery.component.html',
	styleUrls: ['./order-status-delivery.component.scss']
})
export class OrderStatusDeliveryComponent implements OnInit {

	@Input('order') order: Order;

	constructor() { }

	ngOnInit() {
	}

}
