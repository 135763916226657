import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { Brewery, Order, Barcode } from 'src/app/classes/biermi';

@Component({
	selector: 'app-confirmation',
	templateUrl: './confirmation.component.html',
	styleUrls: ['../public.scss', './confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

	loading: boolean = true;
	error;
	orderUuid: string;
	order: Order;
	brewery: Brewery;
	location;
	redemptionBarcode: string;
	curbsidePickup: boolean;

	constructor(private route: ActivatedRoute, private api: ApiService) {
		this.route.params.subscribe(params => {
			this.orderUuid = params['orderUuid'];
			this.load();
		});
	}

	ngOnInit() {}

	load() {
		this.loading = true;
		setTimeout(() => {
			this.api.getOrder(this.orderUuid).subscribe((order: Order) => {
				this.order = order;
				this.api.getBreweryBasic(this.order.breweryId).subscribe((brewery: Brewery) => {
					this.brewery = brewery;
					this.location = this.brewery.locations.find(l => l.id == this.order.pickupLocationId);
					this.curbsidePickup = this.location && this.location.pickupCurbsideEnabled;
					this.loading = false;
				}, error => {
					this.error = error;
					this.loading = false;
				});
			}, error => {
				this.error = error;
				this.loading = false;
			});
		}, 500);
	}

	getConfirmationNumber() {
		return this.order.uuid.slice(-8).toUpperCase();
	}

	getState() {
		if(!this.order.pickupLocationId) {
			return '';
		}
		var location = this.brewery.locations.find(l => l.id == this.order.pickupLocationId);
		if(!location) {
			return '';
		}
		return location.addressStateCode;
	}

	getLocation() {
		var location = this.brewery.locations.find(l => l.id == this.order.pickupLocationId);
		return location ? location.address : this.brewery.address;
	}

	getTable() {
		var location = this.brewery.locations.find(l => l.id == this.order.pickupLocationId);
		if(!location) {
			return '';
		}
		var table = location.tables.find(t => t.id == this.order.tableId);
		if(!table) {
			return '';
		}
		return table.title;
	}
}
