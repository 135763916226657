import { Component, OnInit, Input } from '@angular/core';
import { DonutSlice } from './donut-slice';
import { SlicePipe } from '@angular/common';

@Component({
	selector: 'biermi-donut',
	templateUrl: './donut.component.html',
	styleUrls: ['./donut.component.scss']
})
export class DonutComponent implements OnInit {

	loading: boolean = true;
	@Input() slices: DonutSlice[];
	@Input() max: number;
	@Input() fill: number;

	MAX_ANGLE: number = 359.99;

	constructor() {}

	ngOnInit() {
		var angle = 0;
		this.slices.forEach((slice) => {
			// slice.startAngle = angle + ;
			// 		// angle = bookedAngle + Math.floor(360 * (this.softBooked / this.capacity));
			// slice.endAndgle = slice.percent * (360 * this.fill / this.max);
			// slice.endAndgle = slice.endAndgle >= this.MAX_ANGLE ? this.MAX_ANGLE : slice.endAndgle;
			// angle = slice.endAndgle;
		});
		this.loading = false;

		// // Close the loop
		// var close = new DonutSlice();
		// close.startAngle = angle;
		// close.endAndgle = this.MAX_ANGLE;
		// close.color = '#eee';
		// this.slices.push(close);
	}

	getSliceArc(slice: DonutSlice) {
		console.log(slice);
		return this.describeArc(25, 25, 14, slice.startAngle, slice.endAndgle);
	}

	polarToCartesian(centerX, centerY, radius, angleInDegrees) {
		var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

		return {
			x: centerX + (radius * Math.cos(angleInRadians)),
			y: centerY + (radius * Math.sin(angleInRadians))
		};
	}

	describeArc(x, y, radius, startAngle, endAngle){
		var start = this.polarToCartesian(x, y, radius, endAngle);
		var end = this.polarToCartesian(x, y, radius, startAngle);

		var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

		var d = [
			"M", start.x, start.y,
			"A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
		].join(" ");

		return d;
	}
}
