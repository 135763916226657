import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Modules
import { SortablejsModule } from 'ngx-sortablejs';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ɵs } from '@ng-select/ng-select';

// Services
import { ApiService } from './services/api/api.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { AuthGuardService } from './services/guards/auth-guard/auth-guard.service';
import { HttpInterceptorService } from './services/http-interceptor/http-interceptor.service';
import { AnalyticsService } from './services/analytics/analytics.service';
import { ErrorService } from './services/error/error.service';
import { ModalComponent } from './services/modal/modal.component';

// Directives
import { DebounceDirective } from './directives/debounce/debounce.directive';


@NgModule({
	declarations: [
		AppComponent,

		// Directives
		DebounceDirective,
		ModalComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		SortablejsModule.forRoot({ animation: 150 }),
		NgSelectModule
	],
	providers: [
		ApiService,
		AnalyticsService,
		AuthenticationService,
		AuthGuardService,
		NgSelectConfig,
		ɵs,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptorService,
			multi: true
		}, {
			provide: ErrorHandler,
			useClass: ErrorService,
		},
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
