import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.scss']
})
export class NoticesComponent implements OnInit {

	loading: boolean = true;
	notices = [];

	constructor(private api: ApiService) { }

	ngOnInit() {
		this.load();
	}

	load() {
		this.api.getPublicLegalNotices().subscribe(notices => {
			for(let l in notices) {
				this.notices.push(notices[l]);
			}
			this.loading = false;
		});
	}
}
