export const SHIPPING_PROVIDERS = {
	UPS: 1,
	FlatRate: 2
};

export class ShippingProvider {
	id: number;
	externalId: string;

	breweryId: number;
	businessContactName: string;
	businessContactEmail: string;
	businessContactPhone: string;
	businessAPContactName: string;
	businessAPContactEmail: string;
	businessAPContactPhone: string;
	upsAccountManagerName: string;
	upsAccountManagerPhone: string;
	upsAccountManagerEmail: string;
	shippingProviderTypeId: number;
	accountNumber: string;
	accessKey: string;
	upsApiUsername: string;
	upsApiPassword: string;

	upsWineAccountNumber: string;
	upsWineAccessKey: string;
	upsWineApiUsername: string;
	upsWineApiPassword: string;

	upsSpiritsAccountNumber: string;
	upsSpiritsAccessKey: string;
	upsSpiritsApiUsername: string;
	upsSpiritsApiPassword: string;

	upsCiderAccountNumber: string;
	upsCiderAccessKey: string;
	upsCiderApiUsername: string;
	upsCiderApiPassword: string;

	accountNumberNonAlcohol: string;
	upsNonAlcoholAccessKey: string;
	upsNonAlcoholApiUsername: string;
	upsNonAlcoholApiPassword: string;
	upsServiceGround: boolean;
	upsServiceNextDayAir: boolean;
	upsServiceNextDayAirSaver: boolean;

	externalTitle: string;

	flatRate: number;
	trackingUrl: string;
	handlingFee: number;

	availableStateAL: boolean;
	availableStateAK: boolean;
	availableStateAR: boolean;
	availableStateAZ: boolean;
	availableStateCA: boolean;
	availableStateCO: boolean;
	availableStateCT: boolean;
	availableStateDC: boolean;
	availableStateDE: boolean;
	availableStateFL: boolean;
	availableStateGA: boolean;
	availableStateHI: boolean;
	availableStateIA: boolean;
	availableStateID: boolean;
	availableStateIL: boolean;
	availableStateIN: boolean;
	availableStateKS: boolean;
	availableStateKY: boolean;
	availableStateLA: boolean;
	availableStateMA: boolean;
	availableStateMD: boolean;
	availableStateME: boolean;
	availableStateMI: boolean;
	availableStateMN: boolean;
	availableStateMO: boolean;
	availableStateMS: boolean;
	availableStateMT: boolean;
	availableStateNC: boolean;
	availableStateND: boolean;
	availableStateNE: boolean;
	availableStateNH: boolean;
	availableStateNJ: boolean;
	availableStateNM: boolean;
	availableStateNV: boolean;
	availableStateNY: boolean;
	availableStateOH: boolean;
	availableStateOK: boolean;
	availableStateOR: boolean;
	availableStatePA: boolean;
	availableStateRI: boolean;
	availableStateSC: boolean;
	availableStateSD: boolean;
	availableStateTN: boolean;
	availableStateTX: boolean;
	availableStateUT: boolean;
	availableStateVA: boolean;
	availableStateVT: boolean;
	availableStateWA: boolean;
	availableStateWI: boolean;
	availableStateWV: boolean;
	availableStateWY: boolean;

	availableWineStateAL: boolean;
	availableWineStateAK: boolean;
	availableWineStateAR: boolean;
	availableWineStateAZ: boolean;
	availableWineStateCA: boolean;
	availableWineStateCO: boolean;
	availableWineStateCT: boolean;
	availableWineStateDC: boolean;
	availableWineStateDE: boolean;
	availableWineStateFL: boolean;
	availableWineStateGA: boolean;
	availableWineStateHI: boolean;
	availableWineStateIA: boolean;
	availableWineStateID: boolean;
	availableWineStateIL: boolean;
	availableWineStateIN: boolean;
	availableWineStateKS: boolean;
	availableWineStateKY: boolean;
	availableWineStateLA: boolean;
	availableWineStateMA: boolean;
	availableWineStateMD: boolean;
	availableWineStateME: boolean;
	availableWineStateMI: boolean;
	availableWineStateMN: boolean;
	availableWineStateMO: boolean;
	availableWineStateMS: boolean;
	availableWineStateMT: boolean;
	availableWineStateNC: boolean;
	availableWineStateND: boolean;
	availableWineStateNE: boolean;
	availableWineStateNH: boolean;
	availableWineStateNJ: boolean;
	availableWineStateNM: boolean;
	availableWineStateNV: boolean;
	availableWineStateNY: boolean;
	availableWineStateOH: boolean;
	availableWineStateOK: boolean;
	availableWineStateOR: boolean;
	availableWineStatePA: boolean;
	availableWineStateRI: boolean;
	availableWineStateSC: boolean;
	availableWineStateSD: boolean;
	availableWineStateTN: boolean;
	availableWineStateTX: boolean;
	availableWineStateUT: boolean;
	availableWineStateVA: boolean;
	availableWineStateVT: boolean;
	availableWineStateWA: boolean;
	availableWineStateWI: boolean;
	availableWineStateWV: boolean;
	availableWineStateWY: boolean;

	availableSpiritsStateAL: boolean;
	availableSpiritsStateAK: boolean;
	availableSpiritsStateAR: boolean;
	availableSpiritsStateAZ: boolean;
	availableSpiritsStateCA: boolean;
	availableSpiritsStateCO: boolean;
	availableSpiritsStateCT: boolean;
	availableSpiritsStateDC: boolean;
	availableSpiritsStateDE: boolean;
	availableSpiritsStateFL: boolean;
	availableSpiritsStateGA: boolean;
	availableSpiritsStateHI: boolean;
	availableSpiritsStateIA: boolean;
	availableSpiritsStateID: boolean;
	availableSpiritsStateIL: boolean;
	availableSpiritsStateIN: boolean;
	availableSpiritsStateKS: boolean;
	availableSpiritsStateKY: boolean;
	availableSpiritsStateLA: boolean;
	availableSpiritsStateMA: boolean;
	availableSpiritsStateMD: boolean;
	availableSpiritsStateME: boolean;
	availableSpiritsStateMI: boolean;
	availableSpiritsStateMN: boolean;
	availableSpiritsStateMO: boolean;
	availableSpiritsStateMS: boolean;
	availableSpiritsStateMT: boolean;
	availableSpiritsStateNC: boolean;
	availableSpiritsStateND: boolean;
	availableSpiritsStateNE: boolean;
	availableSpiritsStateNH: boolean;
	availableSpiritsStateNJ: boolean;
	availableSpiritsStateNM: boolean;
	availableSpiritsStateNV: boolean;
	availableSpiritsStateNY: boolean;
	availableSpiritsStateOH: boolean;
	availableSpiritsStateOK: boolean;
	availableSpiritsStateOR: boolean;
	availableSpiritsStatePA: boolean;
	availableSpiritsStateRI: boolean;
	availableSpiritsStateSC: boolean;
	availableSpiritsStateSD: boolean;
	availableSpiritsStateTN: boolean;
	availableSpiritsStateTX: boolean;
	availableSpiritsStateUT: boolean;
	availableSpiritsStateVA: boolean;
	availableSpiritsStateVT: boolean;
	availableSpiritsStateWA: boolean;
	availableSpiritsStateWI: boolean;
	availableSpiritsStateWV: boolean;
	availableSpiritsStateWY: boolean;

	availableCiderStateAL: boolean;
	availableCiderStateAK: boolean;
	availableCiderStateAR: boolean;
	availableCiderStateAZ: boolean;
	availableCiderStateCA: boolean;
	availableCiderStateCO: boolean;
	availableCiderStateCT: boolean;
	availableCiderStateDC: boolean;
	availableCiderStateDE: boolean;
	availableCiderStateFL: boolean;
	availableCiderStateGA: boolean;
	availableCiderStateHI: boolean;
	availableCiderStateIA: boolean;
	availableCiderStateID: boolean;
	availableCiderStateIL: boolean;
	availableCiderStateIN: boolean;
	availableCiderStateKS: boolean;
	availableCiderStateKY: boolean;
	availableCiderStateLA: boolean;
	availableCiderStateMA: boolean;
	availableCiderStateMD: boolean;
	availableCiderStateME: boolean;
	availableCiderStateMI: boolean;
	availableCiderStateMN: boolean;
	availableCiderStateMO: boolean;
	availableCiderStateMS: boolean;
	availableCiderStateMT: boolean;
	availableCiderStateNC: boolean;
	availableCiderStateND: boolean;
	availableCiderStateNE: boolean;
	availableCiderStateNH: boolean;
	availableCiderStateNJ: boolean;
	availableCiderStateNM: boolean;
	availableCiderStateNV: boolean;
	availableCiderStateNY: boolean;
	availableCiderStateOH: boolean;
	availableCiderStateOK: boolean;
	availableCiderStateOR: boolean;
	availableCiderStatePA: boolean;
	availableCiderStateRI: boolean;
	availableCiderStateSC: boolean;
	availableCiderStateSD: boolean;
	availableCiderStateTN: boolean;
	availableCiderStateTX: boolean;
	availableCiderStateUT: boolean;
	availableCiderStateVA: boolean;
	availableCiderStateVT: boolean;
	availableCiderStateWA: boolean;
	availableCiderStateWI: boolean;
	availableCiderStateWV: boolean;
	availableCiderStateWY: boolean;

	availableNonAlcoholStateAL;
	availableNonAlcoholStateAK;
	availableNonAlcoholStateAR;
	availableNonAlcoholStateAZ;
	availableNonAlcoholStateCA;
	availableNonAlcoholStateCO;
	availableNonAlcoholStateCT;
	availableNonAlcoholStateDC;
	availableNonAlcoholStateDE;
	availableNonAlcoholStateFL;
	availableNonAlcoholStateGA;
	availableNonAlcoholStateHI;
	availableNonAlcoholStateIA;
	availableNonAlcoholStateID;
	availableNonAlcoholStateIL;
	availableNonAlcoholStateIN;
	availableNonAlcoholStateKS;
	availableNonAlcoholStateKY;
	availableNonAlcoholStateLA;
	availableNonAlcoholStateMA;
	availableNonAlcoholStateMD;
	availableNonAlcoholStateME;
	availableNonAlcoholStateMI;
	availableNonAlcoholStateMN;
	availableNonAlcoholStateMO;
	availableNonAlcoholStateMS;
	availableNonAlcoholStateMT;
	availableNonAlcoholStateNC;
	availableNonAlcoholStateND;
	availableNonAlcoholStateNE;
	availableNonAlcoholStateNH;
	availableNonAlcoholStateNJ;
	availableNonAlcoholStateNM;
	availableNonAlcoholStateNV;
	availableNonAlcoholStateNY;
	availableNonAlcoholStateOH;
	availableNonAlcoholStateOK;
	availableNonAlcoholStateOR;
	availableNonAlcoholStatePA;
	availableNonAlcoholStateRI;
	availableNonAlcoholStateSC;
	availableNonAlcoholStateSD;
	availableNonAlcoholStateTN;
	availableNonAlcoholStateTX;
	availableNonAlcoholStateUT;
	availableNonAlcoholStateVA;
	availableNonAlcoholStateVT;
	availableNonAlcoholStateWA;
	availableNonAlcoholStateWI;
	availableNonAlcoholStateWV;
	availableNonAlcoholStateWY;
}
