import { Component, OnInit } from '@angular/core';
import { GeographyService } from 'src/app/services/geography/geography.service';

@Component({
	selector: 'biermi-map',
	templateUrl: './biermi-map.component.html',
	styleUrls: ['./biermi-map.component.scss']
})
export class BiermiMapComponent implements OnInit {


	constructor(private geography: GeographyService) { }

	ngOnInit() {
	}

	active(state) {
		return this.geography.activeStates.indexOf(state) > -1;
	}

}
