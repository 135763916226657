export class DeliveryZone {
	id: number;
	breweryDeliveryZoneId: number;
	country: string;
	state: string;
	locality: string;
	county: string;
	postalCode: string;
	radius: number;
	minLatitude: number;
	maxLatitude: number;
	minLongitude: number;
	maxLongitude: number;
	active: boolean = true;
}

export class BreweryDeliveryZone {
	id: number;
	breweryId: number;
	externalId: string;
	title: string;
	externalTitle: string;
	originAddress: string;
	originLatitude: number;
	originLongitude: number;
	salesTax: number;
	deliveryFee: number;
	deliveryFeeTax: number;
	deliveryFeeDistance: number;
	deliveryFeeWaivedAtAmount: number;
	minimumOrder: number;
	minimumVolume: number;
	maximumOrder: number;
	maximumVolume: number;
	published: boolean = true;

	maximumRadius: number;
	stateLimit: string

	windowMondayEnabled: boolean;
	windowTuesdayEnabled: boolean;
	windowWednesdayEnabled: boolean;
	windowThursdayEnabled: boolean;
	windowFridayEnabled: boolean;
	windowSaturdayEnabled: boolean;
	windowSundayEnabled: boolean;

	windowMondayCutoffOffset: number = 0;
	windowTuesdayCutoffOffset: number = 0;
	windowWednesdayCutoffOffset: number = 0;
	windowThursdayCutoffOffset: number = 0;
	windowFridayCutoffOffset: number = 0;
	windowSaturdayCutoffOffset: number = 0;
	windowSundayCutoffOffset: number = 0;

	windowMondayCutoffTime: number;
	windowTuesdayCutoffTime: number;
	windowWednesdayCutoffTime: number;
	windowThursdayCutoffTime: number;
	windowFridayCutoffTime: number;
	windowSaturdayCutoffTime: number;
	windowSundayCutoffTime: number;

	windowMondayStartTime: number;
	windowMondayEndTime: number;
	windowTuesdayStartTime: number;
	windowTuesdayEndTime: number;
	windowWednesdayStartTime: number;
	windowWednesdayEndTime: number;
	windowThursdayStartTime: number;
	windowThursdayEndTime: number;
	windowFridayStartTime: number;
	windowFridayEndTime: number;
	windowSaturdayStartTime: number;
	windowSaturdayEndTime: number;
	windowSundayStartTime: number;
	windowSundayEndTime: number;

	active: boolean;
	createdOn: Date;
	createdBy: number;
	lastUpdatedOn: Date;
	lastUpdatedBy: number;

	editing: boolean;
	loading: boolean;

	deliveryZones: DeliveryZone[] = [];
}
