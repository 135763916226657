import { Component, OnInit, Input } from '@angular/core';
import { Delivery } from 'src/app/classes/biermi';

@Component({
	selector: 'biermi-process-delivery',
	templateUrl: './delivery.component.html',
	styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {

	@Input('delivery') delivery: Delivery;

	steps: string[] = [
		'Prepare',
		'Load',
		'Ship'
	];
	step;

	constructor() { }

	ngOnInit() {
		console.log(this.delivery)

		if(this.delivery.statusOutForDelivery) {
			this.step = 2;
		} else if(this.delivery.statusLoading) {
			this.step = 1;
		} else {
			this.step = 0;
		}
	}
}
