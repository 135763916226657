export const environment = {
	production: false,
	baseUrl: 'https://development.biermi.com',
	apiUrl: 'https://development-api.biermi.com',
	apiUrlOperations: 'https://development-api-operations.biermi.com',
	apiUrlIntegrations: 'https://development-api-integrations.biermi.com',
	wss: `wss://r382t4zkm8.execute-api.us-east-1.amazonaws.com/dev`,

	media: {
		audio: {
			alert: `/audio/alert.mp3`
		}
	},

	paymentsTypes: {
		stripe: {
			publishableKey: 'pk_test_J4PKrCiJceTyDZfsLaT1JtW500luclFSFR'
		},
		square: {
			url: 'connect.squareupsandbox.com',
			applicationId: 'sandbox-sq0idb-TfxVL8T1rUyj7loh7aZWNQ',
			urlTransactions: 'https://squareupsandbox.com/dashboard/sales/transactions/'
		},
		clover: {
			url: 'sandbox.dev.clover.com',
			appId: 'R48EHC91P19BA'
		},
		chaseFreedomPay: {
			origin: 'https://hpc.uat.freedompay.com'
		}
	},

	analytics: {
		trackingId: ''
	},

	integrations: {
		untappd: {
			url: `https://untappd.com/oauth/authenticate/`,
			clientId: '2687049447312D5F1CCA97FEFEF11A7BC2F95831',
			redirectUrl: 'https://api.biermi.com/integrations/untappd/authentication/callback'
		}
	},

	mapKey: 'AIzaSyDsg-CHGBzRUvraH50Qdhjr0016A4r66P0'
};
