import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MediaService } from 'src/app/services/media/media.service';

@Component({
	selector: 'app-media',
	templateUrl: './media.component.html',
	styleUrls: ['./media.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class MediaComponent implements OnInit {

	constructor() {}

	ngOnInit() {}
}
