import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { switchMap, filter, take, catchError } from 'rxjs/operators';
import { AuthenticationService } from "../authentication/authentication.service";
import { Router } from "@angular/router";


@Injectable({
	providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

	isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(private router: Router, private authService: AuthenticationService) {}

	addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
		if(token && token != '') {
			return req.clone({ setHeaders: {
				Authorization: token
			}});
		} else {
			return req;
		}
    }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(this.addToken(req, this.authService.getAccessToken()))
            .pipe(catchError(error => {
                if (error instanceof HttpErrorResponse && req.url.indexOf('/authentication') == -1) {
                    switch ((<HttpErrorResponse>error).status) {
                        case 400:
                            return this.handle400Error(error);
                        case 401:
							return this.handle401Error(req, next, error);
						case 403:
							return this.handle401Error(req, next, error);
						case 0:
							return this.handle401Error(req, next, error);
					}
					return throwError(error);
                } else {
					return throwError(error);
                }
            }));
	}

	handle400Error(error) {
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            return this.logoutUser();
        }

        return throwError(error);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler, error) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.authService.getRefreshToken()
                .pipe(switchMap((newToken: string) => {
                    if (newToken) {
                        this.tokenSubject.next(newToken);
                        return next.handle(this.addToken(req, newToken));
                    }

                    // If we don't get a new token, we are in trouble so logout.
                    return this.logoutUser();
                }))
                .pipe(catchError(error => {
					console.log('error x', error);
					// If there is an exception calling 'refreshToken', bad news so logout.
					let token = this.authService.getAccessToken();
					if(!token || token == '') {
						// return Observable;
						this.isRefreshingToken = false;
						return throwError("HTTP Interceptor: Not logged in");
					} else {
						this.isRefreshingToken = false;
						return this.logoutUser();
					}
                })).pipe(res => {
					this.isRefreshingToken = false;
					return res;
				})
        } else {
            return this.tokenSubject
                .pipe(filter(token => token != null))
                .pipe(take(1))
                .pipe(switchMap(token => {
                    return next.handle(this.addToken(req, token));
                }));
        }
    }

    logoutUser() {
		this.authService.logout(true);
        return throwError("HTTP Interceptor: invalid session");
    }
}
