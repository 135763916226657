import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api/api.service';

@Injectable({
	providedIn: 'root'
})
export class ErrorService implements ErrorHandler {

	constructor(private injector: Injector) { }

	handleError(error: any) {
		const router = this.injector.get(Router);
		const api = this.injector.get(ApiService);
		// if (Error instanceof HttpErrorResponse) {
		// 	console.log(error.status);
		// } else {
		// 	console.error("an error occurred here broo");
		// }
		api.postApplicationError({
			url: router.url,
			status: error.status,
			stack: error.stack
		}).toPromise();
		console.error(error);
	}
}