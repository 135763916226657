import { Injectable, NgZone } from '@angular/core';
import { Place } from 'src/app/classes/biermi';

@Injectable({
	providedIn: 'root'
})
export class GeographyService {

	activeStates = [
		'CA',
		'CO',
		'DC',
		'FL',
		'GA',
		'IL',
		'IN',
		'KS',
		'LA',
		'MA',
		'MD',
		'ME',
		'MI',
		'MO',
		'MT',
		'NC',
		'NH',
		'NJ',
		'NM',
		'NV',
		'NY',
		'OH',
		'OK',
		'OR',
		'PA',
		'TX',
		'VA',
		'VT',
		'WA',
		'WI',
		'WV'
	]

	states = [{
		title: 'Alabama',
		code: 'AL'
	}, {
		title: 'Alaska',
		code: 'AK'
	}, {
		title: 'Arizona',
		code: 'AZ'
	}, {
		title: 'Arkansas',
		code: 'AR'
	}, {
		title: 'California',
		code: 'CA'
	}, {
		title: 'Colorado',
		code: 'CO'
	}, {
		title: 'Connecticut',
		code: 'CT'
	}, {
		title: 'Delaware',
		code: 'DE'
	}, {
		title: 'Florida',
		code: 'FL'
	}, {
		title: 'Georgia',
		code: 'GA'
	}, {
		title: 'Hawaii',
		code: 'HI'
	}, {
		title: 'Idaho',
		code: 'ID'
	}, {
		title: 'Illinois',
		code: 'IL'
	}, {
		title: 'Indiana',
		code: 'IN'
	}, {
		title: 'Iowa',
		code: 'IA'
	}, {
		title: 'Kansas',
		code: 'KS'
	}, {
		title: 'Kentucky',
		code: 'KY'
	}, {
		title: 'Louisiana',
		code: 'LA'
	}, {
		title: 'Maine',
		code: 'ME'
	}, {
		title: 'Maryland',
		code: 'MD'
	}, {
		title: 'Massachusetts',
		code: 'MA'
	}, {
		title: 'Michigan',
		code: 'MI'
	}, {
		title: 'Minnesota',
		code: 'MN'
	}, {
		title: 'Mississippi',
		code: 'MS'
	}, {
		title: 'Missouri',
		code: 'MO'
	}, {
		title: 'Montana',
		code: 'MT'
	}, {
		title: 'Nebraska',
		code: 'NE'
	}, {
		title: 'Nevada',
		code: 'NV'
	}, {
		title: 'New Hampshire',
		code: 'NH'
	}, {
		title: 'New Jersey',
		code: 'NJ'
	}, {
		title: 'New Mexico',
		code: 'NM'
	}, {
		title: 'New York',
		code: 'NY'
	}, {
		title: 'North Carolina',
		code: 'NC'
	}, {
		title: 'North Dakota',
		code: 'ND'
	}, {
		title: 'Ohio',
		code: 'OH'
	}, {
		title: 'Oklahoma',
		code: 'OK'
	}, {
		title: 'Oregon',
		code: 'OR'
	}, {
		title: 'Pennsylvania',
		code: 'PA'
	}, {
		title: 'Rhode Island',
		code: 'RI'
	}, {
		title: 'South Carolina',
		code: 'SC'
	}, {
		title: 'South Dakota',
		code: 'SD'
	}, {
		title: 'Tennessee',
		code: 'TN'
	}, {
		title: 'Texas',
		code: 'TX'
	}, {
		title: 'Utah',
		code: 'UT'
	}, {
		title: 'Vermont',
		code: 'VT'
	}, {
		title: 'Virginia',
		code: 'VA'
	}, {
		title: 'Washington',
		code: 'WA'
	}, {
		title: 'West Virginia',
		code: 'WV'
	}, {
		title: 'Wisconsin',
		code: 'WI'
	}, {
		title: 'Wyoming',
		code: 'WY'
	}, {
		title: 'Washington DC',
		code: 'DC'
	}];

	constructor(private ngZone: NgZone) { }

	getPlaceFromId(place_id) {
		return new Promise((resolve, reject) => {
			var request = {
				placeId: place_id,
				fields: ['place_id', 'formatted_address', 'address_components', 'geometry']
			};

			let map = new google.maps.Map(document.createElement('div'));
			var service = new google.maps.places.PlacesService(map);
			service.getDetails(request, (results, status) => {
				this.ngZone.run(() => {
					if (status == google.maps.places.PlacesServiceStatus.OK) {
						resolve(new Place(results))
					} else {
						reject();
					}
				});
			});
		});
	}
}
