import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  	name: 'phone'
})
export class PhonePipe implements PipeTransform {

	transform(value: any, ...args: any[]): any {
		var digits = `${value}`.replace(/[^0-9]/g, '');
		var response = value;
		switch(digits.length) {
			case 7:
				response = `${digits.slice(0, 3)}-${digits.slice(3)}`;
				break;
			case 10:
				response = `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;
				break;
			case 11:
				response = `${digits.slice(1, 4)}-${digits.slice(4, 7)}-${digits.slice(7)}`;
				break;
		}
		return response;
	}

}
