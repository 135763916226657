export class Photo {
	id: number;
	uuid: string;
	name: string; // "content/photos/6331e7fc-f8ee-4e42-8ef0-7b0078b9b027"
	url: string; // "https://s3.amazonaws.com/biermi.com/content/photos/6331e7fc-f8ee-4e42-8ef0-7b0078b9b027?AWSAccessKeyId=ASIAWFO6X4RGRRW2ICU7&Content-Type=image%2Fpng&Expires=1584835996&Signature=kDoaiizu5umu8T0zIfSAKYLA8Ds%3D&x-amz-security-token=IQoJb3JpZ2luX2VjECkaCXVzLWVhc3QtMSJGMEQCIAv8lvRJnU%2FrsZ5le6BXfJc00Lrh3lJ6FYedV1qkvLofAiAx8yS3cd8tZghEv1VSQuGYsYcvS6nDXuvr1mlmebUuGirbAQghEAAaDDQyNDA1ODI4MzA4NSIMLmEe4ahpiJOjGANPKrgBIGbGR1tf2gsgSMKCSXuCHXtZcdWnbAtuvxKQY5dTEBbV8bDxhIoyKOOh95OyaD4bdVuIc3yMmPa1GfKKUHmR3SEfazs7KKuH9wHSckVVQxZxXU6MF6wG8EK1ts8Ir0izKvdz69mZrDtmdG6hu%2BMXGek6JCGdXUVptS%2B8R8hlCnzVwgxLjmlowZ%2BcfQz%2BTL1zyx2cwbFYxBYixWcRMNH036J67dwFI99c5TZSsj7zfQ2AD55WXmQdTzC32trzBTrhAcg5XP8CwAjBuudm3g9PxFrG7iqCzuRKtLVqPpKSoUhNliEbZMjyhtCVmB3EAgMCjSFlky5Ov4CPW5X%2FdQKSUGiQye0PFe2SIBKXuqdXDC0ArPKiQRcOzzD00IsxyTH5EPGRJNxXmz6BFGd%2FK%2B8T9w6c3IaLeSMeijLQJNwqf5%2BcuFT%2BhU089AKXqlcb2xl9a5l2kq4uANeHhVdRGksyXOmIRGYgPz%2Fo%2Bb2LjANVxcQUmGVsPZDOTDRifqsn48W5o9GxO1UyqpM5iD8DEoGJ23kjk8f0tuodhf75EVpHV037kg%3D%3D"
	contentType: string; // "image/png"
	destination: string;

	// Types
	breweryId: number;
	beerId: number;
	deliveryOrderId: number;
	userId: number;
	subscriptionId: number;
	guildId: number;
}
