export class MembershipInvite {
	id: number;
	membershipId: number;
	email: string = '';
	firstName: string = '';
	lastName: string = '';

	// Membership import fields
	invalidFirstName: boolean;
	invalidLastName: boolean;
	invalidEmail: boolean;
	bypassEmail: boolean;
	pending: boolean;
	loading: boolean;
	success: boolean;
	error: string;
	warning: string;
}

export class MembershipMember {
	id: number;
}

export class Membership {
	id: number;
	breweryId: number;
	title: string;

	earlyAccess: boolean;
	privateAccess: boolean;
	discountPercent: number;
	taxFree: boolean;

	invites: MembershipInvite[];
	users: MembershipMember[];
}
