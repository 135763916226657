import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { Brewery } from 'src/app/classes/biermi';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['../public.scss', './page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

	loading: boolean;
	breweries: Brewery[];

	constructor(private api: ApiService) { }

	ngOnInit() {
		this.load();
	}

	load() {
		this.loading = true;
		this.api.getBreweriesLive().subscribe(breweries => {
			this.breweries = this.shuffle(breweries);
			this.loading = false;
		});
	}

	shuffle(array) {
		var currentIndex = array.length, temporaryValue, randomIndex;
		while (0 !== currentIndex) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}

		return array;
	}
}
