export class RefundItem {
	id: number;
	orderRefundId: number;
	orderItemId: number;
	quantity: number;
	unitPrice: number;
	subtotal: number;
	salesTax: number;
	deposit: number;
	bottleDeposit: number;
	total: number;
}

export class Refund {
	id: number;
	uuid: string;
	orderId: number;
	amount: number;
	currency: string;
	externalId: string;

	refundType: number = 1;
	refundedItems: RefundItem[] = [];

	restock: boolean = true;
	dismiss: boolean = false;
	tip: boolean;
	tipAmount: number;
	deliveryCharge: boolean;
	deliveryChargeAmount: number;
	deliveryChargeTaxAmount: number;

	createdOn: Date;

	// For Reporting
	salesTax: number;
	subTotal: number;
	deposit: number;
	bottleDeposit: number;
}
