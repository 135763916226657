import { Brewery } from '../brewery/brewery';
import { Photo } from '../photo/photo';

export class UserRole {
	id: number;
	userRoleTypeId: number;
	title: string;
}

export class User {
	id: number;
	uuid: string;
	userTypeId: number;
	photo: string;
	firstName: string = '';
	lastName: string = '';
	dateOfBirth: string = '';
	email: string = '';
	phone: string = '';
	city: string = '';
	state: string = '';
	roles: UserRole[];
	devices: string[];
	brewryUserId: number;

	breweries: Brewery[];

	token: string;
	refreshToken: string;

	password?: string = '';
	passwordConfirmation?: string;
	resetToken: string = '';
	captcha: string;
	captchaHash: string;
	captchaExpiration: number;

	agreeToTerms: boolean;
	emailSubscriptionMarketing: boolean;
	untappdUsername: string;

	verified: boolean;
	verificationCode: string;
}
