import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class VehicleService {

	public vehicles = [
		{make:"Ford",model:"Model T"},
		{make:"Chrysler",model:"Imperial"},
		{make:"Hillman",model:"Minx Magnificent"},
		{make:"Chevrolet",model:"Corvette"},
		{make:"Cadillac",model:"Fleetwood"},
		{make:"Ford",model:"Thunderbird"},
		{make:"BMW",model:"600"},
		{make:"Austin",model:"Mini"},
		{make:"Chevrolet",model:"Corvair"},
		{make:"Fillmore",model:"Fillmore"},
		{make:"Fairthorpe",model:"Rockette"},
		{make:"Austin",model:"Mini Cooper"},
		{make:"Studebaker",model:"Avanti"},
		{make:"Pontiac",model:"Tempest"},
		{make:"Pontiac",model:"Grand Prix"},
		{make:"Buick",model:"Special"},
		{make:"Austin",model:"Mini Cooper S"},
		{make:"Rambler",model:"Classic"},
		{make:"Ford",model:"E-Series"},
		{make:"Chevrolet",model:"Corvair 500"},
		{make:"Ford",model:"Mustang"},
		{make:"Ford",model:"Galaxie"},
		{make:"Pontiac",model:"GTO"},
		{make:"Pontiac",model:"LeMans"},
		{make:"Pontiac",model:"Bonneville"},
		{make:"Plymouth",model:"Fury"},
		{make:"Ford",model:"Fairlane"},
		{make:"Volkswagen",model:"Beetle"},
		{make:"Ford",model:"Falcon"},
		{make:"Jensen",model:"Interceptor"},
		{make:"Oldsmobile",model:"Toronado"},
		{make:"Pontiac",model:"Firebird"},
		{make:"Chevrolet",model:"Camaro"},
		{make:"Chevrolet",model:"Bel Air"},
		{make:"Ford",model:"Country"},
		{make:"Mercury",model:"Cougar"},
		{make:"Shelby",model:"GT500"},
		{make:"Dodge",model:"Charger"},
		{make:"Shelby",model:"GT350"},
		{make:"Plymouth",model:"Roadrunner"},
		{make:"Ford",model:"Torino"},
		{make:"Porsche",model:"914"},
		{make:"Chevrolet",model:"Vega"},
		{make:"Chevrolet",model:"Monte Carlo"},
		{make:"Chevrolet",model:"Monza"},
		{make:"Volkswagen",model:"Golf"},
		{make:"Toyota",model:"Celica"},
		{make:"Plymouth",model:"Volare"},
		{make:"Dodge",model:"Aspen"},
		{make:"Chevrolet",model:"Caprice"},
		{make:"Mercedes-Benz",model:"W123"},
		{make:"MG",model:"MGB"},
		{make:"Plymouth",model:"Horizon"},
		{make:"Dodge",model:"Omni"},
		{make:"Nissan",model:"280ZX"},
		{make:"Chevrolet",model:"LUV"},
		{make:"Buick",model:"Riviera"},
		{make:"Chevrolet",model:"Citation"},
		{make:"Honda",model:"Civic"},
		{make:"Plymouth",model:"Reliant"},
		{make:"Dodge",model:"Aries"},
		{make:"Mercedes-Benz",model:"W126"},
		{make:"Pontiac",model:"6000"},
		{make:"Pontiac",model:"Sunbird"},
		{make:"Honda",model:"Accord"},
		{make:"Renault",model:"Alliance"},
		{make:"Mazda",model:"626"},
		{make:"Mazda",model:"RX-7"},
		{make:"Porsche",model:"944"},
		{make:"Pontiac",model:"1000"},
		{make:"Pontiac",model:"Parisienne"},
		{make:"Pontiac",model:"Fiero"},
		{make:"Pontiac",model:"Firefly"},
		{make:"Ford",model:"Bronco II"},
		{make:"Ford",model:"Laser"},
		{make:"Ford",model:"EXP"},
		{make:"Ford",model:"Ranger"},
		{make:"Ford",model:"Escort"},
		{make:"Ford",model:"Tempo"},
		{make:"Ford",model:"LTD"},
		{make:"Ford",model:"F250"},
		{make:"Ford",model:"F150"},
		{make:"Ford",model:"E250"},
		{make:"Ford",model:"LTD Crown Victoria"},
		{make:"Ford",model:"Bronco"},
		{make:"Ford",model:"E150"},
		{make:"Mitsubishi",model:"Space"},
		{make:"Mitsubishi",model:"Galant"},
		{make:"Mitsubishi",model:"Mirage"},
		{make:"Mitsubishi",model:"Starion"},
		{make:"Mitsubishi",model:"Pajero"},
		{make:"Mitsubishi",model:"Cordia"},
		{make:"Mitsubishi",model:"Tredia"},
		{make:"Subaru",model:"Brat"},
		{make:"Mercedes-Benz",model:"S-Class"},
		{make:"Mercedes-Benz",model:"E-Class"},
		{make:"Mercedes-Benz",model:"W201"},
		{make:"Mercedes-Benz",model:"SL-Class"},
		{make:"Mercury",model:"Topaz"},
		{make:"Mercury",model:"Grand Marquis"},
		{make:"Mercury",model:"Lynx"},
		{make:"Mercury",model:"Capri"},
		{make:"Mercury",model:"Marquis"},
		{make:"Lotus",model:"Esprit Turbo"},
		{make:"Volkswagen",model:"Jetta"},
		{make:"Volkswagen",model:"Vanagon"},
		{make:"Volkswagen",model:"Scirocco"},
		{make:"Volkswagen",model:"Quantum"},
		{make:"Buick",model:"Electra"},
		{make:"Buick",model:"Century"},
		{make:"Buick",model:"Skyhawk"},
		{make:"Lincoln",model:"Town Car"},
		{make:"Lincoln",model:"Continental"},
		{make:"Lincoln",model:"Mark VII"},
		{make:"Maserati",model:"Quattroporte"},
		{make:"Maserati",model:"Biturbo"},
		{make:"Saab",model:"900"},
		{make:"Audi",model:"5000S"},
		{make:"Honda",model:"CR-X"},
		{make:"Honda",model:"Prelude"},
		{make:"Dodge",model:"Daytona"},
		{make:"Suzuki",model:"SJ 410"},
		{make:"Mazda",model:"GLC"},
		{make:"Buick",model:"Somerset"},
		{make:"Buick",model:"LeSabre"},
		{make:"Buick",model:"Regal"},
		{make:"Buick",model:"Skylark"},
		{make:"Ford",model:"F-Series"},
		{make:"Mitsubishi",model:"Truck"},
		{make:"Mitsubishi",model:"Chariot"},
		{make:"Volkswagen",model:"GTI"},
		{make:"Volkswagen",model:"Cabriolet"},
		{make:"Volkswagen",model:"Passat"},
		{make:"Volkswagen",model:"Type 2"},
		{make:"Audi",model:"4000s"},
		{make:"Audi",model:"Quattro"},
		{make:"Audi",model:"Coupe GT"},
		{make:"Lamborghini",model:"Countach"},
		{make:"Mazda",model:"Familia"},
		{make:"Mazda",model:"B2000"},
		{make:"Pontiac",model:"Grand Am"},
		{make:"Subaru",model:"XT"},
		{make:"Subaru",model:"Leone"},
		{make:"Merkur",model:"XR4Ti"},
		{make:"Lincoln",model:"Continental Mark VII"},
		{make:"Plymouth",model:"Voyager"},
		{make:"Porsche",model:"911"},
		{make:"Porsche",model:"928"},
		{make:"Suzuki",model:"Cultus"},
		{make:"Suzuki",model:"SJ"},
		{make:"Toyota",model:"MR2"},
		{make:"Lotus",model:"Esprit"},
		{make:"Dodge",model:"Caravan"},
		{make:"Ford",model:"Taurus"},
		{make:"Ford",model:"Aerostar"},
		{make:"Ford",model:"Courier"},
		{make:"Mitsubishi",model:"Mighty Max"},
		{make:"Mitsubishi",model:"Precis"},
		{make:"Pontiac",model:"Safari"},
		{make:"Pontiac",model:"Gemini"},
		{make:"Pontiac",model:"Firebird Trans Am"},
		{make:"Audi",model:"5000CS Quattro"},
		{make:"Audi",model:"4000s Quattro"},
		{make:"Audi",model:"4000CS Quattro"},
		{make:"Mercury",model:"Sable"},
		{make:"Mazda",model:"B-Series"},
		{make:"Saab",model:"9000"},
		{make:"Land Rover",model:"Range Rover"},
		{make:"Audi",model:"4000"},
		{make:"Audi",model:"5000CS"},
		{make:"Mazda",model:"929"},
		{make:"Mazda",model:"B2600"},
		{make:"Volkswagen",model:"Fox"},
		{make:"Suzuki",model:"Swift"},
		{make:"Subaru",model:"Justy"},
		{make:"Porsche",model:"924 S"},
		{make:"Pontiac",model:"Chevette"},
		{make:"Mitsubishi",model:"Excel"},
		{make:"Mitsubishi",model:"L300"},
		{make:"Acura",model:"Legend"},
		{make:"Acura",model:"Integra"},
		{make:"Porsche",model:"924"},
		{make:"Pontiac",model:"Turbo Firefly"},
		{make:"Mercury",model:"Tracer"},
		{make:"Buick",model:"Reatta"},
		{make:"Ford",model:"Festiva"},
		{make:"Mazda",model:"MX-6"},
		{make:"Audi",model:"90"},
		{make:"Audi",model:"80/90"},
		{make:"Buick",model:"Estate"},
		{make:"Ford",model:"Probe"},
		{make:"Mazda",model:"MPV"},
		{make:"Mitsubishi",model:"Sigma"},
		{make:"Mitsubishi",model:"Eclipse"},
		{make:"Subaru",model:"Legacy"},
		{make:"Suzuki",model:"Sidekick"},
		{make:"Lexus",model:"LS"},
		{make:"Lexus",model:"ES"},
		{make:"Maserati",model:"228"},
		{make:"Maserati",model:"430"},
		{make:"Maserati",model:"Spyder"},
		{make:"Maserati",model:"Karif"},
		{make:"Audi",model:"80"},
		{make:"Audi",model:"100"},
		{make:"Audi",model:"200"},
		{make:"Toyota",model:"Truck Xtracab SR5"},
		{make:"BMW",model:"6 Series"},
		{make:"Dodge",model:"Colt"},
		{make:"Plymouth",model:"Laser"},
		{make:"Buick",model:"Coachbuilder"},
		{make:"Subaru",model:"Loyale"},
		{make:"Mazda",model:"MX-5"},
		{make:"Pontiac",model:"Trans Sport"},
		{make:"Pontiac",model:"Grand Prix Turbo"},
		{make:"Audi",model:"V8"},
		{make:"Audi",model:"Coupe Quattro"},
		{make:"Lotus",model:"Elan"},
		{make:"Volkswagen",model:"Corrado"},
		{make:"Mitsubishi",model:"GTO"},
		{make:"Nissan",model:"Maxima"},
		{make:"Nissan",model:"Datsun/Nissan Z-car"},
		{make:"Lamborghini",model:"Diablo"},
		{make:"Eagle",model:"Talon"},
		{make:"Ford",model:"Explorer"},
		{make:"Buick",model:"Park Avenue"},
		{make:"Buick",model:"Roadmaster"},
		{make:"Mazda",model:"Navajo"},
		{make:"Nissan",model:"300ZX"},
		{make:"Nissan",model:"Sentra"},
		{make:"Land Rover",model:"Sterling"},
		{make:"Toyota",model:"Previa"},
		{make:"Volkswagen",model:"Eurovan"},
		{make:"Volkswagen",model:"riolet"},
		{make:"Ford",model:"Crown Victoria"},
		{make:"Ford",model:"Club Wagon"},
		{make:"Ford",model:"Econoline E150"},
		{make:"Ford",model:"Econoline E250"},
		{make:"Ford",model:"Econoline E350"},
		{make:"Ford",model:"F350"},
		{make:"Suzuki",model:"Samurai"},
		{make:"Mercedes-Benz",model:"190E"},
		{make:"Mercedes-Benz",model:"300CE"},
		{make:"Mercedes-Benz",model:"300D"},
		{make:"Mercedes-Benz",model:"300E"},
		{make:"Mercedes-Benz",model:"300SD"},
		{make:"Mercedes-Benz",model:"300SE"},
		{make:"Mercedes-Benz",model:"300SL"},
		{make:"Mercedes-Benz",model:"300TE"},
		{make:"Mercedes-Benz",model:"400E"},
		{make:"Mercedes-Benz",model:"400SE"},
		{make:"Mercedes-Benz",model:"500E"},
		{make:"Mercedes-Benz",model:"500SEL"},
		{make:"Mercedes-Benz",model:"500SL"},
		{make:"Mercedes-Benz",model:"600SEL"},
		{make:"Subaru",model:"Alcyone SVX"},
		{make:"Subaru",model:"SVX"},
		{make:"Mitsubishi",model:"Diamante"},
		{make:"Mitsubishi",model:"RVR"},
		{make:"Mitsubishi",model:"3000GT"},
		{make:"Mitsubishi",model:"Expo"},
		{make:"Mitsubishi",model:"Mighty Max Macro"},
		{make:"Mitsubishi",model:"Montero"},
		{make:"Mazda",model:"323"},
		{make:"Mazda",model:"Protege"},
		{make:"Mazda",model:"MX-3"},
		{make:"Mazda",model:"B-Series Plus"},
		{make:"Mazda",model:"Miata MX-5"},
		{make:"Audi",model:"S4"},
		{make:"BMW",model:"3 Series"},
		{make:"BMW",model:"5 Series"},
		{make:"BMW",model:"7 Series"},
		{make:"BMW",model:"8 Series"},
		{make:"BMW",model:"M5"},
		{make:"Pontiac",model:"Firebird Formula"},
		{make:"Land Rover",model:"Defender"},
		{make:"Lexus",model:"SC"},
		{make:"Dodge",model:"Viper"},
		{make:"Dodge",model:"D150 Club"},
		{make:"Dodge",model:"D150"},
		{make:"Dodge",model:"D250 Club"},
		{make:"Dodge",model:"D250"},
		{make:"Dodge",model:"D350 Club"},
		{make:"Dodge",model:"D350"},
		{make:"Dodge",model:"Dakota Club"},
		{make:"Dodge",model:"Dakota"},
		{make:"Dodge",model:"Dynasty"},
		{make:"Dodge",model:"Grand Caravan"},
		{make:"Dodge",model:"Monaco"},
		{make:"Dodge",model:"Ram 50"},
		{make:"Dodge",model:"Ram Van B150"},
		{make:"Dodge",model:"Ram Van B250"},
		{make:"Dodge",model:"Ram Van B350"},
		{make:"Dodge",model:"Ram Wagon B150"},
		{make:"Dodge",model:"Ram Wagon B250"},
		{make:"Dodge",model:"Ram Wagon B350"},
		{make:"Dodge",model:"Ramcharger"},
		{make:"Dodge",model:"Shadow"},
		{make:"Dodge",model:"Spirit"},
		{make:"Dodge",model:"Stealth"},
		{make:"Porsche",model:"968"},
		{make:"Cadillac",model:"Seville"},
		{make:"Cadillac",model:"Allante"},
		{make:"Cadillac",model:"Brougham"},
		{make:"Cadillac",model:"DeVille"},
		{make:"Cadillac",model:"Eldorado"},
		{make:"Toyota",model:"Camry"},
		{make:"Toyota",model:"4Runner"},
		{make:"Toyota",model:"Corolla"},
		{make:"Toyota",model:"Cressida"},
		{make:"Toyota",model:"Land Cruiser"},
		{make:"Toyota",model:"Paseo"},
		{make:"Toyota",model:"Supra"},
		{make:"Toyota",model:"Tercel"},
		{make:"Toyota",model:"Xtra"},
		{make:"Nissan",model:"240SX"},
		{make:"Nissan",model:"NX"},
		{make:"Nissan",model:"Pathfinder"},
		{make:"Nissan",model:"Stanza"},
		{make:"Plymouth",model:"Acclaim"},
		{make:"Plymouth",model:"Colt"},
		{make:"Plymouth",model:"Colt Vista"},
		{make:"Plymouth",model:"Grand Voyager"},
		{make:"Plymouth",model:"Sundance"},
		{make:"Eagle",model:"Premier"},
		{make:"Eagle",model:"Summit"},
		{make:"Acura",model:"NSX"},
		{make:"Acura",model:"Vigor"},
		{make:"Alfa Romeo",model:"164"},
		{make:"Alfa Romeo",model:"Spider"},
		{make:"Chevrolet",model:"1500"},
		{make:"Chevrolet",model:"2500"},
		{make:"Chevrolet",model:"3500"},
		{make:"Chevrolet",model:"APV"},
		{make:"Chevrolet",model:"Astro"},
		{make:"Chevrolet",model:"Beretta"},
		{make:"Chevrolet",model:"Blazer"},
		{make:"Chevrolet",model:"Cavalier"},
		{make:"Chevrolet",model:"Corsica"},
		{make:"Chevrolet",model:"G-Series G10"},
		{make:"Chevrolet",model:"G-Series G20"},
		{make:"Chevrolet",model:"G-Series G30"},
		{make:"Chevrolet",model:"Lumina"},
		{make:"Chevrolet",model:"Lumina APV"},
		{make:"Chevrolet",model:"S10 Blazer"},
		{make:"Chevrolet",model:"S10"},
		{make:"Chevrolet",model:"Sportvan G10"},
		{make:"Chevrolet",model:"Sportvan G20"},
		{make:"Chevrolet",model:"Sportvan G30"},
		{make:"Chevrolet",model:"Suburban 1500"},
		{make:"Chevrolet",model:"Suburban 2500"},
		{make:"Chrysler",model:"Fifth Ave"},
		{make:"Chrysler",model:"LeBaron"},
		{make:"Chrysler",model:"New Yorker"},
		{make:"Chrysler",model:"Town & Country"},
		{make:"Daihatsu",model:"Charade"},
		{make:"Daihatsu",model:"Rocky"},
		{make:"Geo",model:"Metro"},
		{make:"Geo",model:"Prizm"},
		{make:"Geo",model:"Storm"},
		{make:"Geo",model:"Tracker"},
		{make:"GMC",model:"1500"},
		{make:"GMC",model:"2500"},
		{make:"GMC",model:"3500"},
		{make:"GMC",model:"1500 Club Coupe"},
		{make:"GMC",model:"2500 Club Coupe"},
		{make:"GMC",model:"3500 Club Coupe"},
		{make:"GMC",model:"Jimmy"},
		{make:"GMC",model:"Rally Wagon 1500"},
		{make:"GMC",model:"Rally Wagon 2500"},
		{make:"GMC",model:"Rally Wagon 3500"},
		{make:"GMC",model:"Safari"},
		{make:"GMC",model:"Sonoma Club"},
		{make:"GMC",model:"Sonoma"},
		{make:"GMC",model:"Suburban 1500"},
		{make:"GMC",model:"Suburban 2500"},
		{make:"GMC",model:"Vandura 1500"},
		{make:"GMC",model:"Vandura 2500"},
		{make:"GMC",model:"Vandura 3500"},
		{make:"GMC",model:"Yukon"},
		{make:"Hyundai",model:"Elantra"},
		{make:"Hyundai",model:"Excel"},
		{make:"Hyundai",model:"Scoupe"},
		{make:"Hyundai",model:"Sonata"},
		{make:"Infiniti",model:"G"},
		{make:"Infiniti",model:"M"},
		{make:"Infiniti",model:"Q"},
		{make:"Isuzu",model:"Amigo"},
		{make:"Isuzu",model:"Impulse"},
		{make:"Isuzu",model:"Rodeo"},
		{make:"Isuzu",model:"Space"},
		{make:"Isuzu",model:"Stylus"},
		{make:"Isuzu",model:"Trooper"},
		{make:"Jaguar",model:"XJ Series"},
		{make:"Jeep",model:"Cherokee"},
		{make:"Jeep",model:"Comanche"},
		{make:"Jeep",model:"Wrangler"},
		{make:"Oldsmobile",model:"88"},
		{make:"Oldsmobile",model:"98"},
		{make:"Oldsmobile",model:"Achieva"},
		{make:"Oldsmobile",model:"Bravada"},
		{make:"Oldsmobile",model:"Ciera"},
		{make:"Oldsmobile",model:"Custom Cruiser"},
		{make:"Oldsmobile",model:"Cutlass Supreme"},
		{make:"Oldsmobile",model:"Silhouette"},
		{make:"Saturn",model:"S-Series"},
		{make:"Volvo",model:"240"},
		{make:"Volvo",model:"740"},
		{make:"Volvo",model:"940"},
		{make:"Volvo",model:"960"},
		{make:"Subaru",model:"Impreza"},
		{make:"Mercury",model:"Villager"},
		{make:"Mercedes-Benz",model:"C-Class"},
		{make:"Mercedes-Benz",model:"400SEL"},
		{make:"Mercedes-Benz",model:"500SEC"},
		{make:"Mercedes-Benz",model:"600SEC"},
		{make:"Mercedes-Benz",model:"600SL"},
		{make:"Dodge",model:"Viper RT/10"},
		{make:"Dodge",model:"Intrepid"},
		{make:"Volkswagen",model:"Golf III"},
		{make:"Volkswagen",model:"Jetta III"},
		{make:"Land Rover",model:"Range Rover Classic"},
		{make:"Land Rover",model:"Defender 110"},
		{make:"Nissan",model:"Altima"},
		{make:"Nissan",model:"Quest"},
		{make:"Lincoln",model:"Mark VIII"},
		{make:"Lexus",model:"GS"},
		{make:"Chrysler",model:"Concorde"},
		{make:"Honda",model:"del Sol"},
		{make:"Toyota",model:"T100"},
		{make:"Jeep",model:"Grand Cherokee"},
		{make:"Cadillac",model:"Sixty Special"},
		{make:"Chevrolet",model:"Caprice Classic"},
		{make:"Eagle",model:"Vision"},
		{make:"GMC",model:"Sonoma Club Coupe"},
		{make:"HUMMER",model:"H1"},
		{make:"Infiniti",model:"J"},
		{make:"Oldsmobile",model:"Cutlass Cruiser"},
		{make:"Volvo",model:"850"},
		{make:"Ford",model:"Lightning"},
		{make:"Ford",model:"Aspire"},
		{make:"Kia",model:"Sephia"},
		{make:"Dodge",model:"Ram"},
		{make:"Dodge",model:"Ram 1500"},
		{make:"Dodge",model:"Ram 2500"},
		{make:"Dodge",model:"Ram 3500"},
		{make:"Chevrolet",model:"Impala SS"},
		{make:"Chevrolet",model:"Impala"},
		{make:"Audi",model:"Cabriolet"},
		{make:"Audi",model:"riolet"},
		{make:"Land Rover",model:"Discovery"},
		{make:"Land Rover",model:"Defender 90"},
		{make:"Honda",model:"Passport"},
		{make:"Chrysler",model:"LHS"},
		{make:"Kia",model:"Sportage"},
		{make:"Pontiac",model:"Sunfire"},
		{make:"Ford",model:"Windstar"},
		{make:"Ford",model:"Contour"},
		{make:"Volkswagen",model:"rio"},
		{make:"Mercury",model:"Mystique"},
		{make:"Suzuki",model:"Esteem"},
		{make:"Mazda",model:"Millenia"},
		{make:"Audi",model:"S6"},
		{make:"Audi",model:"A6"},
		{make:"Acura",model:"TL"},
		{make:"BMW",model:"M3"},
		{make:"Dodge",model:"Avenger"},
		{make:"Dodge",model:"Neon"},
		{make:"Dodge",model:"Ram 1500 Club"},
		{make:"Dodge",model:"Ram 2500 Club"},
		{make:"Dodge",model:"Ram 3500 Club"},
		{make:"Dodge",model:"Ram Van 1500"},
		{make:"Dodge",model:"Ram Van 2500"},
		{make:"Dodge",model:"Ram Van 3500"},
		{make:"Dodge",model:"Stratus"},
		{make:"Nissan",model:"200SX"},
		{make:"Chevrolet",model:"K5 Blazer"},
		{make:"Chevrolet",model:"Tahoe"},
		{make:"Honda",model:"Odyssey"},
		{make:"Chrysler",model:"Cirrus"},
		{make:"Chrysler",model:"Sebring"},
		{make:"Holden",model:"VS Commodore"},
		{make:"GMC",model:"Rally Wagon G2500"},
		{make:"GMC",model:"Rally Wagon G3500"},
		{make:"GMC",model:"Vandura G1500"},
		{make:"GMC",model:"Vandura G2500"},
		{make:"GMC",model:"Vandura G3500"},
		{make:"Hyundai",model:"Accent"},
		{make:"Oldsmobile",model:"Aurora"},
		{make:"Plymouth",model:"Neon"},
		{make:"Toyota",model:"Avalon"},
		{make:"Toyota",model:"T100 Xtra"},
		{make:"Toyota",model:"Tacoma"},
		{make:"Toyota",model:"Tacoma Xtra"},
		{make:"Buick",model:"Hearse"},
		{make:"Audi",model:"A4"},
		{make:"Mitsubishi",model:"Expo LRV"},
		{make:"Suzuki",model:"X-90"},
		{make:"Plymouth",model:"Breeze"},
		{make:"Chevrolet",model:"Express 1500"},
		{make:"Chevrolet",model:"Express 2500"},
		{make:"Chevrolet",model:"Express 3500"},
		{make:"Chevrolet",model:"G-Series 1500"},
		{make:"Chevrolet",model:"G-Series 2500"},
		{make:"Lexus",model:"LX"},
		{make:"BMW",model:"Z3"},
		{make:"Acura",model:"RL"},
		{make:"Acura",model:"SLX"},
		{make:"GMC",model:"Savana 1500"},
		{make:"GMC",model:"Savana 2500"},
		{make:"GMC",model:"Savana 3500"},
		{make:"Infiniti",model:"I"},
		{make:"Isuzu",model:"Hombre"},
		{make:"Isuzu",model:"Oasis"},
		{make:"Toyota",model:"RAV4"},
		{make:"Kia",model:"Mentor"},
		{make:"Mercury",model:"Mountaineer"},
		{make:"Honda",model:"CR-V"},
		{make:"Audi",model:"A8"},
		{make:"Mitsubishi",model:"Challenger"},
		{make:"Mitsubishi",model:"Montero Sport"},
		{make:"Porsche",model:"Boxster"},
		{make:"Mercedes-Benz",model:"SLK-Class"},
		{make:"Ford",model:"Expedition"},
		{make:"Chevrolet",model:"Malibu"},
		{make:"Chevrolet",model:"G-Series 3500"},
		{make:"Chevrolet",model:"Venture"},
		{make:"Plymouth",model:"Prowler"},
		{make:"Acura",model:"CL"},
		{make:"Cadillac",model:"Catera"},
		{make:"Hyundai",model:"Tiburon"},
		{make:"Infiniti",model:"QX"},
		{make:"Isuzu",model:"Hombre Space"},
		{make:"Jaguar",model:"XK Series"},
		{make:"Oldsmobile",model:"Cutlass"},
		{make:"Oldsmobile",model:"LSS"},
		{make:"Oldsmobile",model:"Regency"},
		{make:"Volvo",model:"S90"},
		{make:"Volvo",model:"V90"},
		{make:"Chevrolet",model:"Metro"},
		{make:"Chevrolet",model:"Prizm"},
		{make:"Chevrolet",model:"Tracker"},
		{make:"Subaru",model:"Forester"},
		{make:"Volkswagen",model:"New Beetle"},
		{make:"Lincoln",model:"Navigator"},
		{make:"Mercedes-Benz",model:"M-Class"},
		{make:"Mercedes-Benz",model:"CL-Class"},
		{make:"Mercedes-Benz",model:"CLK-Class"},
		{make:"Dodge",model:"Durango"},
		{make:"GMC",model:"Envoy"},
		{make:"Nissan",model:"Frontier"},
		{make:"Oldsmobile",model:"Intrigue"},
		{make:"Toyota",model:"Sienna"},
		{make:"Volvo",model:"C70"},
		{make:"Volvo",model:"S70"},
		{make:"Volvo",model:"V70"},
		{make:"Chevrolet",model:"Silverado"},
		{make:"Chevrolet",model:"Silverado 1500"},
		{make:"Chevrolet",model:"Silverado 2500"},
		{make:"Pontiac",model:"Montana"},
		{make:"Suzuki",model:"Vitara"},
		{make:"Suzuki",model:"Grand Vitara"},
		{make:"Land Rover",model:"Discovery Series II"},
		{make:"Saab",model:"44077"},
		{make:"Saab",model:"44079"},
		{make:"Lexus",model:"RX"},
		{make:"GMC",model:"EV1"},
		{make:"GMC",model:"Sierra 1500"},
		{make:"GMC",model:"Sierra 2500"},
		{make:"Chrysler",model:"300M"},
		{make:"Corbin",model:"Sparrow"},
		{make:"Cadillac",model:"Escalade"},
		{make:"Chrysler",model:"300"},
		{make:"Daewoo",model:"Lanos"},
		{make:"Daewoo",model:"Leganza"},
		{make:"Daewoo",model:"Nubira"},
		{make:"Isuzu",model:"VehiCROSS"},
		{make:"Oldsmobile",model:"Alero"},
		{make:"Toyota",model:"Solara"},
		{make:"Volvo",model:"S80"},
		{make:"Subaru",model:"Outback"},
		{make:"Honda",model:"Insight"},
		{make:"Honda",model:"S2000"},
		{make:"BMW",model:"X5"},
		{make:"BMW",model:"M"},
		{make:"BMW",model:"Z8"},
		{make:"Kia",model:"Spectra"},
		{make:"Lincoln",model:"LS"},
		{make:"Ford",model:"Th!nk"},
		{make:"Ford",model:"Explorer Sport Trac"},
		{make:"Ford",model:"Escape"},
		{make:"Ford",model:"Excursion"},
		{make:"Ford",model:"Explorer Sport"},
		{make:"Ford",model:"Focus"},
		{make:"Audi",model:"TT"},
		{make:"Toyota",model:"Tundra"},
		{make:"Toyota",model:"Ipsum"},
		{make:"Toyota",model:"Echo"},
		{make:"Nissan",model:"Xterra"},
		{make:"Chrysler",model:"Grand Voyager"},
		{make:"Chrysler",model:"Voyager"},
		{make:"GMC",model:"Sierra 3500"},
		{make:"GMC",model:"Yukon Denali"},
		{make:"GMC",model:"Yukon XL 1500"},
		{make:"GMC",model:"Yukon XL 2500"},
		{make:"Jaguar",model:"S-Type"},
		{make:"Saturn",model:"L-Series"},
		{make:"Volvo",model:"S40"},
		{make:"Volvo",model:"V40"},
		{make:"Ford",model:"Fiesta"},
		{make:"Ford",model:"ZX2"},
		{make:"Suzuki",model:"XL-7"},
		{make:"Mazda",model:"B2500"},
		{make:"Mazda",model:"Tribute"},
		{make:"Audi",model:"S8"},
		{make:"Audi",model:"Allroad"},
		{make:"Pontiac",model:"Aztek"},
		{make:"Mitsubishi",model:"Lancer"},
		{make:"Lexus",model:"IS"},
		{make:"Land Rover",model:"Freelander"},
		{make:"BMW",model:"525"},
		{make:"BMW",model:"530"},
		{make:"Toyota",model:"Highlander"},
		{make:"Toyota",model:"Prius"},
		{make:"Toyota",model:"Sequoia"},
		{make:"Kia",model:"Rio"},
		{make:"Kia",model:"Optima"},
		{make:"Chevrolet",model:"Silverado 3500"},
		{make:"Acura",model:"MDX"},
		{make:"Chrysler",model:"PT Cruiser"},
		{make:"Chrysler",model:"Prowler"},
		{make:"Hyundai",model:"Santa Fe"},
		{make:"Hyundai",model:"XG300"},
		{make:"Isuzu",model:"Rodeo Sport"},
		{make:"Volvo",model:"S60"},
		{make:"Suzuki",model:"Aerio"},
		{make:"Mercedes-Benz",model:"G-Class"},
		{make:"Subaru",model:"Outback Sport"},
		{make:"Honda",model:"Pilot"},
		{make:"Mazda",model:"Protege5"},
		{make:"Mitsubishi",model:"Lancer Evolution"},
		{make:"Lamborghini",model:"Murci√©lago"},
		{make:"Buick",model:"Rendezvous"},
		{make:"BMW",model:"745"},
		{make:"Lincoln",model:"Blackwood"},
		{make:"Kia",model:"Sedona"},
		{make:"GMC",model:"Envoy XL"},
		{make:"Chevrolet",model:"Avalanche"},
		{make:"Chevrolet",model:"Trailblazer"},
		{make:"Chevrolet",model:"Avalanche 1500"},
		{make:"Chevrolet",model:"Avalanche 2500"},
		{make:"MINI",model:"MINI"},
		{make:"MINI",model:"Cooper"},
		{make:"Acura",model:"RSX"},
		{make:"Cadillac",model:"Escalade EXT"},
		{make:"Hyundai",model:"XG350"},
		{make:"Isuzu",model:"Axiom"},
		{make:"Jaguar",model:"X-Type"},
		{make:"Jeep",model:"Liberty"},
		{make:"Saturn",model:"VUE"},
		{make:"Mercury",model:"Marauder"},
		{make:"BMW",model:"760"},
		{make:"BMW",model:"Z4"},
		{make:"Ford",model:"Escort ZX2"},
		{make:"Ford",model:"Freestar"},
		{make:"Ford",model:"E350"},
		{make:"Lexus",model:"GX"},
		{make:"Honda",model:"Element"},
		{make:"Honda",model:"Civic Si"},
		{make:"Honda",model:"Civic GX"},
		{make:"Porsche",model:"Cayenne"},
		{make:"Mitsubishi",model:"Outlander"},
		{make:"Subaru",model:"Baja"},
		{make:"Pontiac",model:"Vibe"},
		{make:"Audi",model:"RS6"},
		{make:"Audi",model:"RS 6"},
		{make:"Mazda",model:"Mazda6"},
		{make:"Volkswagen",model:"Touareg"},
		{make:"Kia",model:"Sorento"},
		{make:"Lamborghini",model:"Gallardo"},
		{make:"Lincoln",model:"Aviator"},
		{make:"Maybach",model:"57"},
		{make:"Maybach",model:"62"},
		{make:"Volvo",model:"XC90"},
		{make:"Volvo",model:"XC70"},
		{make:"Chevrolet",model:"SSR"},
		{make:"Infiniti",model:"G35"},
		{make:"Infiniti",model:"FX"},
		{make:"Nissan",model:"350Z"},
		{make:"Nissan",model:"Murano"},
		{make:"Cadillac",model:"CTS"},
		{make:"Cadillac",model:"Escalade ESV"},
		{make:"HUMMER",model:"H2"},
		{make:"Isuzu",model:"Ascender"},
		{make:"Saturn",model:"Ion"},
		{make:"Toyota",model:"Matrix"},
		{make:"Mitsubishi",model:"Endeavor"},
		{make:"Lotus",model:"Exige"},
		{make:"Lotus",model:"Elise"},
		{make:"Porsche",model:"Carrera GT"},
		{make:"Mazda",model:"Mazda3"},
		{make:"Mazda",model:"RX-8"},
		{make:"Kia",model:"Amanti"},
		{make:"BMW",model:"325"},
		{make:"BMW",model:"545"},
		{make:"BMW",model:"645"},
		{make:"BMW",model:"X3"},
		{make:"Buick",model:"Rainier"},
		{make:"Holden",model:"Monaro"},
		{make:"Mercury",model:"Monterey"},
		{make:"Spyker",model:"C8 Spyder"},
		{make:"Spyker",model:"C8 Spyder Wide Body"},
		{make:"Spyker",model:"C8 Laviolette"},
		{make:"Suzuki",model:"Daewoo Lacetti"},
		{make:"Suzuki",model:"Daewoo Magnus"},
		{make:"Suzuki",model:"Forenza"},
		{make:"Suzuki",model:"Verona"},
		{make:"Volkswagen",model:"R32"},
		{make:"Volkswagen",model:"Phaeton"},
		{make:"Chevrolet",model:"Aveo"},
		{make:"Chevrolet",model:"Classic"},
		{make:"Chevrolet",model:"Colorado"},
		{make:"Scion",model:"xA"},
		{make:"Scion",model:"xB"},
		{make:"Cadillac",model:"SRX"},
		{make:"Cadillac",model:"XLR"},
		{make:"Nissan",model:"Titan"},
		{make:"Nissan",model:"Pathfinder Armada"},
		{make:"Acura",model:"TSX"},
		{make:"Chrysler",model:"Crossfire"},
		{make:"Chrysler",model:"Pacifica"},
		{make:"GMC",model:"Canyon"},
		{make:"GMC",model:"Envoy XUV"},
		{make:"Scion",model:"tC"},
		{make:"Pontiac",model:"G6"},
		{make:"Pontiac",model:"Daewoo Kalos"},
		{make:"Pontiac",model:"Monterey"},
		{make:"Pontiac",model:"Montana SV6"},
		{make:"Ford",model:"GT"},
		{make:"Ford",model:"Freestyle"},
		{make:"Ford",model:"Five Hundred"},
		{make:"Buick",model:"LaCrosse"},
		{make:"Buick",model:"Terraza"},
		{make:"Suzuki",model:"Reno"},
		{make:"Mercedes-Benz",model:"SLR McLaren"},
		{make:"BMW",model:"330"},
		{make:"Cadillac",model:"STS"},
		{make:"Maserati",model:"Gran Sport"},
		{make:"Maserati",model:"Coupe"},
		{make:"Maserati",model:"GranSport"},
		{make:"Chrysler",model:"300C"},
		{make:"Mercury",model:"Montego"},
		{make:"Mercury",model:"Mariner"},
		{make:"Chevrolet",model:"Cobalt"},
		{make:"Chevrolet",model:"Equinox"},
		{make:"Chevrolet",model:"Uplander"},
		{make:"Land Rover",model:"LR3"},
		{make:"Spyker Cars",model:"C8"},
		{make:"Maybach",model:"57S"},
		{make:"Saab",model:"9-7X"},
		{make:"Saab",model:"9-2X"},
		{make:"Dodge",model:"Magnum"},
		{make:"Aston Martin",model:"DB9"},
		{make:"Aston Martin",model:"Vanquish S"},
		{make:"Bentley",model:"Arnage"},
		{make:"Bentley",model:"Continental"},
		{make:"Hyundai",model:"Tucson"},
		{make:"Nissan",model:"Armada"},
		{make:"Panoz",model:"Esperante"},
		{make:"Rolls-Royce",model:"Phantom"},
		{make:"Saturn",model:"Relay"},
		{make:"Volvo",model:"V50"},
		{make:"GMC",model:"Sierra Denali"},
		{make:"GMC",model:"Sierra 3500HD"},
		{make:"GMC",model:"Sierra 2500HD"},
		{make:"GMC",model:"Yukon XL"},
		{make:"GMC",model:"Savana Cargo Van"},
		{make:"GMC",model:"Sierra Hybrid"},
		{make:"GMC",model:"Savana"},
		{make:"Mazda",model:"Mazda6 5-Door"},
		{make:"Mazda",model:"Mazda5"},
		{make:"Mazda",model:"Mazda6 Sport"},
		{make:"Mazda",model:"Mazdaspeed6"},
		{make:"Bentley",model:"Azure"},
		{make:"Bentley",model:"Continental GT"},
		{make:"Bentley",model:"Continental Flying Spur"},
		{make:"BMW",model:"550"},
		{make:"BMW",model:"650"},
		{make:"BMW",model:"750"},
		{make:"BMW",model:"M6"},
		{make:"BMW",model:"M Roadster"},
		{make:"BMW",model:"Z4 M"},
		{make:"Mercedes-Benz",model:"CLS-Class"},
		{make:"Mercedes-Benz",model:"G55 AMG"},
		{make:"Mercedes-Benz",model:"SL65 AMG"},
		{make:"Mercedes-Benz",model:"R-Class"},
		{make:"Hyundai",model:"Azera"},
		{make:"Cadillac",model:"DTS"},
		{make:"Cadillac",model:"XLR-V"},
		{make:"Cadillac",model:"CTS-V"},
		{make:"Cadillac",model:"STS-V"},
		{make:"Jeep",model:"Commander"},
		{make:"Honda",model:"Ridgeline"},
		{make:"Suzuki",model:"XL7"},
		{make:"Buick",model:"Lucerne"},
		{make:"Volkswagen",model:"Rabbit"},
		{make:"Kia",model:"Spectra5"},
		{make:"Pontiac",model:"Torrent"},
		{make:"Pontiac",model:"Solstice"},
		{make:"Mitsubishi",model:"Raider"},
		{make:"Jaguar",model:"XK"},
		{make:"Jaguar",model:"XJ"},
		{make:"Toyota",model:"Yaris"},
		{make:"Toyota",model:"Camry Solara"},
		{make:"Subaru",model:"Tribeca"},
		{make:"Subaru",model:"B9 Tribeca"},
		{make:"Chevrolet",model:"Suburban"},
		{make:"Chevrolet",model:"Silverado 3500HD"},
		{make:"Chevrolet",model:"HHR Panel"},
		{make:"Chevrolet",model:"Malibu Maxx"},
		{make:"Chevrolet",model:"HHR"},
		{make:"Chevrolet",model:"Silverado Hybrid"},
		{make:"Chevrolet",model:"Express"},
		{make:"Ford",model:"F-250 Super Duty"},
		{make:"Ford",model:"F-350 Super Duty"},
		{make:"Ford",model:"E-350 Super Duty"},
		{make:"Ford",model:"E-350 Super Duty Van"},
		{make:"Ford",model:"Fusion"},
		{make:"Audi",model:"A3"},
		{make:"Land Rover",model:"Range Rover Sport"},
		{make:"Lexus",model:"RX Hybrid"},
		{make:"Porsche",model:"Cayman"},
		{make:"Mercury",model:"Milan"},
		{make:"Hummer",model:"H2 SUT"},
		{make:"Hummer",model:"H2 SUV"},
		{make:"Hummer",model:"H3"},
		{make:"Nissan",model:"350Z Roadster"},
		{make:"Chrysler",model:"Crossfire Roadster"},
		{make:"Lincoln",model:"Zephyr"},
		{make:"Lincoln",model:"Mark LT"},
		{make:"Dodge",model:"Sprinter"},
		{make:"Aston Martin",model:"DB9 Volante"},
		{make:"Aston Martin",model:"V8 Vantage"},
		{make:"Aston Martin",model:"Vantage"},
		{make:"Spyker",model:"C8 Double 12 S"},
		{make:"Ferrari",model:"F430"},
		{make:"Ferrari",model:"612 Scaglietti"},
		{make:"Ferrari",model:"F430 Spider"},
		{make:"Morgan",model:"Aero 8"},
		{make:"Peugeot",model:"207"},
		{make:"Isuzu",model:"i-Series"},
		{make:"Isuzu",model:"i-280"},
		{make:"Isuzu",model:"i-350"},
		{make:"Chevrolet",model:"Cobalt SS"},
		{make:"Volkswagen",model:"Eos"},
		{make:"Ford",model:"F-Series Super Duty"},
		{make:"Ford",model:"GT500"},
		{make:"Ford",model:"Edge"},
		{make:"Ford",model:"Expedition EL"},
		{make:"Mercedes-Benz",model:"GL-Class"},
		{make:"BMW",model:"Alpina B7"},
		{make:"Dodge",model:"Nitro"},
		{make:"Dodge",model:"Caliber"},
		{make:"Kia",model:"Carens"},
		{make:"Kia",model:"Rondo"},
		{make:"Toyota",model:"Highlander Hybrid"},
		{make:"Toyota",model:"Camry Hybrid"},
		{make:"Toyota",model:"FJ Cruiser"},
		{make:"Toyota",model:"TundraMax"},
		{make:"Mazda",model:"CX-7"},
		{make:"Mazda",model:"Mazdaspeed 3"},
		{make:"Mazda",model:"CX-9"},
		{make:"Audi",model:"Q7"},
		{make:"Audi",model:"RS4"},
		{make:"Audi",model:"RS 4"},
		{make:"Jeep",model:"Compass"},
		{make:"Jeep",model:"Patriot"},
		{make:"Isuzu",model:"i-290"},
		{make:"Isuzu",model:"i-370"},
		{make:"Hyundai",model:"Veracruz"},
		{make:"Hyundai",model:"Entourage"},
		{make:"Foose",model:"Hemisfear"},
		{make:"Infiniti",model:"QX56"},
		{make:"Honda",model:"Fit"},
		{make:"Nissan",model:"Versa"},
		{make:"GMC",model:"Acadia"},
		{make:"GMC",model:"Sierra"},
		{make:"Suzuki",model:"SX4"},
		{make:"Ferrari",model:"599 GTB Fiorano"},
		{make:"Lincoln",model:"MKX"},
		{make:"Lincoln",model:"MKZ"},
		{make:"Lincoln",model:"Navigator L"},
		{make:"Saturn",model:"Outlook"},
		{make:"Saturn",model:"Aura"},
		{make:"Saturn",model:"Sky"},
		{make:"Pontiac",model:"G5"},
		{make:"Bentley",model:"Continental GTC"},
		{make:"Acura",model:"RDX"},
		{make:"Chrysler",model:"Aspen"},
		{make:"Volvo",model:"C30"},
		{make:"BMW",model:"1 Series"},
		{make:"BMW",model:"X6"},
		{make:"Audi",model:"S5"},
		{make:"Audi",model:"R8"},
		{make:"Audi",model:"A5"},
		{make:"Ford",model:"Taurus X"},
		{make:"Ford",model:"F450"},
		{make:"Volkswagen",model:"GLI"},
		{make:"Volkswagen",model:"Touareg 2"},
		{make:"Land Rover",model:"LR2"},
		{make:"Lexus",model:"IS-F"},
		{make:"Lexus",model:"IS F"},
		{make:"Lamborghini",model:"Revent√≥n"},
		{make:"Lamborghini",model:"Murci√©lago LP640"},
		{make:"Nissan",model:"Rogue"},
		{make:"Infiniti",model:"G37"},
		{make:"Infiniti",model:"EX"},
		{make:"Dodge",model:"Challenger"},
		{make:"Aston Martin",model:"DBS"},
		{make:"Kia",model:"Rio5"},
		{make:"Saturn",model:"Astra"},
		{make:"Pontiac",model:"G8"},
		{make:"Ferrari",model:"430 Scuderia"},
		{make:"MINI",model:"Clubman"},
		{make:"MINI",model:"Cooper Clubman"},
		{make:"Buick",model:"Enclave"},
		{make:"Aptera",model:"Typ-1"},
		{make:"Maserati",model:"GranTurismo"},
		{make:"Smart",model:"Fortwo"},
		{make:"Scion",model:"xD"},
		{make:"Mercedes-Benz",model:"SLK55 AMG"},
		{make:"Mercedes-Benz",model:"CL65 AMG"},
		{make:"Audi",model:"Q5"},
		{make:"Hyundai",model:"Genesis"},
		{make:"HUMMER",model:"H3T"},
		{make:"BMW",model:"Z4 M Roadster"},
		{make:"Chevrolet",model:"Traverse"},
		{make:"Volkswagen",model:"CC"},
		{make:"Volkswagen",model:"Tiguan"},
		{make:"Volkswagen",model:"Routan"},
		{make:"Kia",model:"Mohave/Borrego"},
		{make:"Kia",model:"Borrego"},
		{make:"Volvo",model:"XC60"},
		{make:"Toyota",model:"Venza"},
		{make:"Pontiac",model:"G3"},
		{make:"Ferrari",model:"California"},
		{make:"Maybach",model:"Landaulet"},
		{make:"Dodge",model:"Journey"},
		{make:"Nissan",model:"Cube"},
		{make:"Nissan",model:"GT-R"},
		{make:"Nissan",model:"370Z"},
		{make:"Ford",model:"Flex"},
		{make:"Bentley",model:"Brooklands"},
		{make:"Suzuki",model:"Equator"},
		{make:"Mitsubishi",model:"Tundra"},
		{make:"Lincoln",model:"MKS"},
		{make:"Jaguar",model:"XF"},
		{make:"Aptera",model:"2e"},
		{make:"Bugatti",model:"Veyron"},
		{make:"BMW",model:"X5 M"},
		{make:"BMW",model:"X6 M"},
		{make:"Honda",model:"Accord Crosstour"},
		{make:"Mercedes-Benz",model:"Sprinter"},
		{make:"Mercedes-Benz",model:"GLK-Class"},
		{make:"Aston Martin",model:"Rapide"},
		{make:"Hyundai",model:"Genesis Coupe"},
		{make:"Porsche",model:"Panamera"},
		{make:"Land Rover",model:"LR4"},
		{make:"Kia",model:"Soul"},
		{make:"Kia",model:"Forte"},
		{make:"Subaru",model:"Impreza WRX"},
		{make:"Lincoln",model:"MKT"},
		{make:"Rolls-Royce",model:"Ghost"},
		{make:"Ford",model:"Transit Connect"},
		{make:"Lexus",model:"LS Hybrid"},
		{make:"Lexus",model:"HS"},
		{make:"Ferrari",model:"458 Italia"},
		{make:"Lotus",model:"Evora"},
		{make:"GMC",model:"Terrain"},
		{make:"Bentley",model:"Continental Super"},
		{make:"Bentley",model:"Azure T"},
		{make:"Suzuki",model:"Kizashi"},
		{make:"Acura",model:"ZDX"},
		{make:"Tesla",model:"Roadster"},
		{make:"Aptera",model:"Type-1h"},
		{make:"Land Rover",model:"Defender Ice Edition"},
		{make:"Mazda",model:"Mazda2"},
		{make:"Mercedes-Benz",model:"SLS AMG"},
		{make:"Mercedes-Benz",model:"SLS-Class"},
		{make:"Mercedes-Benz",model:"Sprinter 2500"},
		{make:"Mercedes-Benz",model:"Sprinter 3500"},
		{make:"Honda",model:"CR-Z"},
		{make:"Lexus",model:"CT"},
		{make:"Nissan",model:"Leaf"},
		{make:"Nissan",model:"JUKE"},
		{make:"MINI",model:"Countryman"},
		{make:"MINI",model:"Cooper Countryman"},
		{make:"Chevrolet",model:"Volt"},
		{make:"Chevrolet",model:"Cruze"},
		{make:"Aston Martin",model:"V8 Vantage S"},
		{make:"Aston Martin",model:"V12 Vantage"},
		{make:"Aston Martin",model:"Virage"},
		{make:"Bentley",model:"Mulsanne"},
		{make:"Infiniti",model:"G25"},
		{make:"Infiniti",model:"IPL G"},
		{make:"Saab",model:"9-4X"},
		{make:"Hyundai",model:"Equus"},
		{make:"Mitsubishi",model:"Outlander Sport"},
		{make:"Chrysler",model:"200"},
		{make:"Ram",model:"1500"},
		{make:"Ram",model:"2500"},
		{make:"Ram",model:"3500"},
		{make:"Ram",model:"Dakota"},
		{make:"Nissan",model:"NV1500"},
		{make:"Nissan",model:"NV2500"},
		{make:"Nissan",model:"NV3500"},
		{make:"Audi",model:"A7"},
		{make:"Land Rover",model:"Range Rover Evoque"},
		{make:"Honda",model:"FCX Clarity"},
		{make:"Honda",model:"Crosstour"},
		{make:"Hyundai",model:"HED-5"},
		{make:"Hyundai",model:"Veloster"},
		{make:"Chevrolet",model:"Sonic"},
		{make:"Toyota",model:"Prius v"},
		{make:"Toyota",model:"Prius Plug-in Hybrid"},
		{make:"Toyota",model:"Prius c"},
		{make:"Toyota",model:"Prius Plug-in"},
		{make:"Lexus",model:"LFA"},
		{make:"FIAT",model:"500"},
		{make:"Fiat",model:"Nuova 500"},
		{make:"Ferrari",model:"FF"},
		{make:"Lamborghini",model:"Aventador"},
		{make:"Tesla",model:"Model S"},
		{make:"Buick",model:"Verano"},
		{make:"Mitsubishi",model:"i-MiEV"},
		{make:"Scion",model:"iQ"},
		{make:"McLaren",model:"MP4-12C"},
		{make:"Ram",model:"C/V"},
		{make:"Ford",model:"C-MAX Hybrid"},
		{make:"Ford",model:"Focus ST"},
		{make:"Infiniti",model:"JX"},
		{make:"Mazda",model:"CX-5"},
		{make:"Scion",model:"FR-S"},
		{make:"Subaru",model:"BRZ"},
	]

	public colors = [
		'White',
		'Silver',
		'Black',
		'Blue',
		'Gray',
		'Red',
		'Green',
		'Brown',
		'Gold',
		'Yellow',
		'Purple',
		'Orange',
		'Pink'
	]

	constructor() { }
}
