import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { NgSelectModule } from '@ng-select/ng-select';

import { ApiService } from 'src/app/services/api/api.service';
import { SharedModule } from '../shared/shared.module';
import { PublicContainerComponent } from './containers/public-container/public-container.component';

import { FeaturesComponent } from './pages/features/features.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BiermiMapComponent } from './components/biermi-map/biermi-map.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { JoinComponent } from './pages/join/join.component';
import { MediaComponent as x, MediaComponent } from './pages/media/media.component';
import { ContactComponent } from './pages/contact/contact.component';
import { BrowseComponent } from './pages/browse/browse.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { TrackComponent } from './pages/track/track.component';
import { PrivacyComponent } from './pages/legal/privacy/privacy.component';
import { TermsComponent } from './pages/legal/terms/terms.component';
import { CookiesComponent } from './pages/legal/cookies/cookies.component';
import { NoticesComponent } from './pages/legal/notices/notices.component';
import { FooterSocialComponent } from './components/footer-social/footer-social.component';
import { OrderLocationComponent } from './components/order-location/order-location.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AboutComponent } from './pages/about/about.component';
import { SearchComponent } from './pages/search/search.component';
import { SquareComponent } from './pages/features/integrations/square/square.component';
import { MediaComponent as ComponentMediaComponent } from './components/media/media.component';
import { PickupComponent } from './pages/pickup/pickup.component';
import { ShippingComponent } from './pages/shipping/shipping.component';

@NgModule({
	declarations: [
		// Containers
		PublicContainerComponent,

		// Pages
		HomeComponent,
		FeaturesComponent,
		PricingComponent,
		JoinComponent,
		ContactComponent,
		BrowseComponent,
		MediaComponent,
		ConfirmationComponent,
		TrackComponent,
		PrivacyComponent,
		TermsComponent,
		CookiesComponent,
		NoticesComponent,
		PageNotFoundComponent,

		// Components
		FooterComponent,
		BiermiMapComponent,
		FooterSocialComponent,
		OrderLocationComponent,
		AboutComponent,
		SearchComponent,
		SquareComponent,
		ComponentMediaComponent,
		PickupComponent,
		ShippingComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		HttpClientModule,
		SharedModule,
		NgSelectModule,
		RouterModule.forChild([
			{ path: '', component: HomeComponent },
			{
				path: '',
				component: PublicContainerComponent,
				children: [
					{ path: 'search', component: SearchComponent },
					{
						path: 'features',
						children: [{
							path: '',
							component: FeaturesComponent
						}, {
							path: 'integrations/square',
							component: SquareComponent
						}]
					},
					{ path: 'pricing', component: PricingComponent },
					{ path: 'join', component: JoinComponent },
					{ path: 'about', component: AboutComponent },
					{ path: 'media', component: MediaComponent },
					{ path: 'contact', component: ContactComponent },
					{ path: 'browse', component: BrowseComponent },
					{ path: 'confirmation/:orderUuid', component: ConfirmationComponent },
					{ path: 'pickup/:orderUuid', component: PickupComponent },
					{ path: 'track/:orderUuid', component: TrackComponent },
					{
						path: 'shipping',
						children: [{
							path: '',
							component: ShippingComponent
						}, {
							path: ':beverageType',
							component: ShippingComponent
						}, {
							path: ':beverageType/:state',
							component: ShippingComponent
						}]
					},
					{ path: 'legal/privacy', component: PrivacyComponent },
					{ path: 'legal/terms', component: TermsComponent },
					{ path: 'legal/cookies', component: CookiesComponent },
					{ path: 'legal/notices', component: NoticesComponent },
				]
			},
		]),
		AgmCoreModule.forRoot({
			apiKey: environment.mapKey
		}),
	],
	providers: [
	]
})
export class PublicModule {}
