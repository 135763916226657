import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Brewery, Order } from 'src/app/classes/biermi';
import { ApiService } from 'src/app/services/api/api.service';
import * as moment from 'moment';

@Component({
	selector: 'app-track',
	templateUrl: './track.component.html',
	styleUrls: ['../public.scss', './track.component.scss']
})
export class TrackComponent implements OnInit, OnDestroy {

	loading: boolean = true;
	error;
	orderUuid: string;
	order: Order;
	brewery: Brewery;
	refreshInterval;
	etaToday: boolean;;

	constructor(private route: ActivatedRoute, private api: ApiService) {
		this.route.params.subscribe(params => {
			this.orderUuid = params['orderUuid'];
			this.load();
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		clearInterval(this.refreshInterval);
	}

	load() {
		this.loading = true;
		this.api.getOrder(this.orderUuid).subscribe((order: Order) => {
			this.order = order;
			if(this.order.estimatedArrivalTime) {
				this.etaToday = moment(this.order.estimatedArrivalTime).diff(moment(), 'hours') < 12;
			}

			this.api.getBrewery(this.order.breweryId).subscribe(brewery => {
				this.brewery = brewery;
				this.loading = false;
				this.refresh();
			});
		}, error => {
			this.error = error;
			this.loading = false;
		});
	}

	refresh() {
		this.refreshInterval = setInterval(() => {
			this.api.getOrder(this.orderUuid).subscribe((order: Order) => {
				this.order = order;
			}, error => {});
		}, 2 * 60 * 1000);
	}
}
