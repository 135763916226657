import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../authentication/authentication.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardService {

	constructor(private router: Router, private authService: AuthenticationService) { }

	canActivate() {
		if(this.authService.isLoggedIn()) {
			return true;
		}

		// not logged in so redirect to login page
        this.router.navigate(['/']);
        return false;
    }
}
