export class Package {
	id: number;
	breweryId: number;
	standardPackageId: number;
	configurationId: number;
	packageTypeId: number = 1;
	title: string;

	material: string;
	volume: number;
	volumeUnit: string = 'oz';
	unitCount: number;
	unitCost: number;
	excludeFromRating: boolean;
	bottleDeposit: boolean;

	weight: number;
	length: number;
	width: number;
	height: number;
	efficiencyMultiplier: number = 1;
	showCustomerOptimizationMessage: boolean = true;
	fillPackageRequired: boolean = false;

	productPackages: Package[] = [];

	// View Helpers
	count: number;
	selected: boolean;
}
