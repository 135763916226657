import { Category } from '../category/category';

export class Variation {
	id: number;
	beerId: number;
	published: boolean = true;
	title: string;
	variationPrice: number;
	variationPriceHappyHour: number;
	deposit: number;
	inventory: number;
	packageId: number;
	packageType: string;
	packageMaterial: string = 'cans';
	packageUnitVolume: number;
	packageUnitVolumeType: string = 'oz';
	packageUnitCount: number = 0;
	deliveryEnabled: boolean = true;
	pickupEnabled: boolean = true;
	shippingEnabled: boolean = true;
	dineInEnabled: boolean = true;
	soldInventory: number;
	remainingInventory: number;
	rebaseInventory: boolean;
	rank: number = 0;
}

export class Beer {
	id: number;
	breweryId: number;
	breweryLocationId: number;
	externalId: string;
	published: boolean = true;
	publishedSecretMenu: boolean = false;
	privateAccess: boolean = false;
	releaseOn;
	releaseOnTicks;
	releaseDate;
	closeOn;
	fulfillBy;
	title: string;
	photo: string;
	description: string;
	style: string;
	abv: number;
	ibu: number;
	itemCategoryId: number;
	inventory: number;
	packageId: number;
	packageType: string;
	packageMaterial: string = 'cans';
	packageUnitVolume: number;
	packageUnitVolumeType: string = 'oz';
	packageUnitCount: number;
	soldInventory: number;
	remainingInventory: number;
	rebaseInventory: boolean;
	unitPrice: number;
	unitPriceHappyHour: number;
	discount: number;
	deposit: number;
	depositTotal: number;
	bottleDeposit: number;
	bottleDepositTotal: number;
	subTotal: number;
	taxRateId: number;
	salesTaxPercent: number;
	salesTax: number;
	total: number;

	digitalEnabled: boolean;
	deliveryEnabled: boolean;
	pickupEnabled: boolean;
	shippingEnabled: boolean;
	dineInEnabled: boolean;
	pickupMaximumVolume: number;
	deliveryMaximumVolume: number;
	shippingMaximumVolume: number;
	dineInMaximumVolume: number;

	rank: number;
	active: boolean;
	availabilityNotificationsEnabled: boolean;

	variations: Variation[] = [];
	category: Category;

	untappdId: number;
	untappdSlug: string;

	// Needed for orders
	selectedQuantity: number;
	selectedVariation: Variation;

	// needed for UI
	loading: boolean;
}
