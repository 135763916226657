import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class MediaService {

	constructor() { }

	public static posts = [{
		outletTitle: `Craft Beer &amp; Brewing`,
		url: `https://brewingindustryguide.com/sales-and-digital-e-commerce-for-all/`,
		image: `assets/media/craft-beer-and-brewing-logo.jpg`,
		imageGray: `assets/media/gray/craft-beer-and-brewing.png`,
		imageAlt: `Craft Beer & Brewing`,
		contentSummary: `The Craft Beer &amp; Brewing Industry Guide gave us the opportunity to talk e-commerce and how <a href="https://brewingindustryguide.com/sales-and-digital-e-commerce-for-all/">biermi has propped up an industry</a> during desparate times. The pandemic has accelerated change in an industry and we've been fortunate to be able to help with the rapid transition.`
	}, {
		outletTitle: `Head Retention`,
		url: `https://www.iheart.com/podcast/457-head-retention-30366289/episode/true-respite-disrupts-the-beer-delivery-68645217/`,
		image: `assets/media/head-retention.png`,
		imageGray: `assets/media/gray/head-retention.png`,
		imageAlt: `Head Retention`,
		contentSummary: `Co-Founder Brendan O'Leary joined DC101 DJ, Roche, to talk about biermi and how breweries have successfully pivoted their business model to Direct-To-Consumer since the start of the pandemic on his <a href="https://www.iheart.com/podcast/457-head-retention-30366289/episode/true-respite-disrupts-the-beer-delivery-68645217/">Head Retention</a> podcast.`
	}, {
		outletTitle: `The Beer League`,
		url: `https://anchor.fm/beerleaguephilly/episodes/Biermi-and-Bonus-Beer-ek545n`,
		image: `assets/media/the-beer-league.jpg`,
		imageGray: `assets/media/gray/the-beer-league.png`,
		imageAlt: `The Beer League`,
		contentSummary: `Co-Founder Brian O'Connor spent some time hanging out with Philadelphia crew, <a href="https://anchor.fm/beerleaguephilly/episodes/Biermi-and-Bonus-Beer-ek545n">The  Beer League</a>, to talk about beer, biermi, and sports.`
	}, {
		outletTitle: `Baltimore Sun`,
		url: `https://www.baltimoresun.com/coronavirus/bs-md-breweries-innovation-20200506-20200529-azdffsjnyvbzlmak6ba36qfdsu-story.html`,
		image: `assets/media/baltimore-sun.jpg`,
		imageGray: `assets/media/gray/baltimore-sun.png`,
		imageAlt: `Baltimore Sun`,
		contentSummary: `The <a href="https://www.baltimoresun.com/coronavirus/bs-md-breweries-innovation-20200506-20200529-azdffsjnyvbzlmak6ba36qfdsu-story.html">Baltimore Sun</a> paints a picture on how craft beverage producers have pivoted their businesses to survive. We're grateful that biermi has played such a pivotal role in this transition and all of this comes on the day that Maryland announces alcohol producers can direct ship to their customers.`
	}, {
		outletTitle: `dcist`,
		url: `https://dcist.com/story/20/05/18/biermi-alcohol-direct-sales-pandemic-dc-md-va/`,
		image: `assets/media/dcist.jpg`,
		imageGray: `assets/media/gray/dcist.png`,
		imageAlt: `dcist`,
		contentSummary: `A recent survey from the National Brewers Association found that 61% of the more than 900 brewers surveyed anticipated layoffs due to the pandemic. bimeri aims to defy the odds and save as many of these businesses as possible and the co-founders had a chance to tell the <a href="https://dcist.com/story/20/05/18/biermi-alcohol-direct-sales-pandemic-dc-md-va/"><b>dc</b>ist</a> how they're working to do so.`
	}, {
		outletTitle: `The Washington Post`,
		url: `https://www.washingtonpost.com/goingoutguide/breweries-once-viewed-home-delivery-as-a-hassle-now-its-a-lifeline/2020/05/18/72dc4144-96c6-11ea-91d7-cf4423d47683_story.html`,
		image: `assets/media/the-washington-post.jpg`,
		imageGray: `assets/media/gray/the-washington-post.png`,
		imageAlt: `The Washington Post`,
		contentSummary: `biermi Co-Founder, Brendan, had a chance to talk with <a href="https://www.washingtonpost.com/goingoutguide/breweries-once-viewed-home-delivery-as-a-hassle-now-its-a-lifeline/2020/05/18/72dc4144-96c6-11ea-91d7-cf4423d47683_story.html">The Washington Post</a> and tell them a bit about how we've been helping businesses pivot their model to home delivery. It's humbling to see how biermi has been able to help so many businesses survive.`
	}, {
		outletTitle: `Fast Company`,
		url: `https://www.fastcompany.com/90499692/why-we-opened-our-pandemic-born-delivery-platform-to-competitors`,
		image: `assets/media/fastcompany.png`,
		imageGray: `assets/media/gray/fastcompany.png`,
		imageAlt: `Fast Company`,
		contentSummary: `We had an opportunity to share our story with <a href="https://www.fastcompany.com/90499692/why-we-opened-our-pandemic-born-delivery-platform-to-competitors">Fast Company</a>. We shared a bit about the formation of biermi and how wild the first month of the platform was.`
	}, {
		outletTitle: `The Uncapped Podcast`,
		url: `http://www.fnppodcasts.com/uncapped/2020/3/17/episode-174-breweries-responding-to-covid-19-closures`,
		image: `assets/media/uncapped.jpg`,
		imageGray: `assets/media/gray/uncapped.png`,
		imageAlt: `The Upcapped Podcast`,
		contentSummary: `The <a href="http://www.fnppodcasts.com/uncapped/2020/3/17/episode-174-breweries-responding-to-covid-19-closures">Uncapped</a> podcast showed us some love during their episode covering the impact of COVID-19 on breweries. Jim Bauckman, director of communications for the Brewers Association of Maryland, and Brenden O'Leary, co-founder of True Respite Brewing co., talk about what beer fans can do to help support the breweries we love.`
	}, {
		outletTitle: `The DC Beer Show`,
		url: `https://dcbeer.com/podcast/biermi-online-beer-marketplace/`,
		image: `assets/media/dc-beer-show.png`,
		imageGray: `assets/media/gray/dc-beer-show.png`,
		imageAlt: `The DC Beer Show`,
		contentSummary: `<a href="https://dcbeer.com/podcast/biermi-online-beer-marketplace/">The DC Beer Show</a> invited two of the co-founders of biermi onto the show to talk about the biermi story. We had the opportunity to share a bit of the origin, backstory, the launch, and our mission.`
	}, {
		outletTitle: `Georgia Tech`,
		url: `https://www.cc.gatech.edu/news/634253/alumni-launch-company-help-breweries-go-consumer-direct`,
		image: `assets/media/georgia-tech.png`,
		imageGray: `assets/media/gray/georgia-tech.png`,
		imageAlt: `Georgia Tech`,
		contentSummary: `The three founders of biermi are all <a href="https://www.cc.gatech.edu/news/634253/alumni-launch-company-help-breweries-go-consumer-direct">Georgia Tech</a> grads and were featured in The Compiler, the College of Computing newsletter.`
	}, {
		outletTitle: `Baltimore Business Journal`,
		url: `https://www.bizjournals.com/baltimore/news/2020/04/13/this-maryland-brewery-is-behind-a-new-alcohol.html`,
		image: `assets/media/baltimore-business-journal.jpeg`,
		imageGray: `assets/media/gray/baltimore-business-journal.png`,
		imageAlt: `Baltimore Business Journal`,
		contentSummary: `biermi has had a huge impact to businesses in Baltimore and the surrounding area. The <a href="https://www.bizjournals.com/baltimore/news/2020/04/13/this-maryland-brewery-is-behind-a-new-alcohol.html">Baltimore Business Journal</a> took note and featured biermi and our story.`
	}];
}
