import { Component, OnInit, ViewChild, NgZone, ElementRef } from '@angular/core';
import { Brewery, Place } from 'src/app/classes/biermi';
import { ApiService } from 'src/app/services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from 'src/app/services/search/search.service';
import { DeliveryZoneService } from 'src/app/services/delivery-zone/delivery-zone.service';
import { MediaService } from 'src/app/services/media/media.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['../public.scss', './home.component.scss']
})
export class HomeComponent implements OnInit {

	place: Place;

	viewSearchResults: boolean;
	autocompleteInput: string;
	media;

	@ViewChild('addresstext', { static: false }) addresstext: any;

	constructor(private route: ActivatedRoute, private router: Router, private ngZone: NgZone, private api: ApiService, private searchService: SearchService, private deliveryZoneService: DeliveryZoneService) {
		this.media = MediaService.posts;
	}

	ngOnInit() {}

	deliveryAddressEntered(place: Place) {
		this.router.navigate(['/', 'search'], {
			relativeTo: this.route,
			queryParams: {
				pid: place.place_id
			},
			queryParamsHandling: 'merge'
		});
	}
}
