export class Card {
	id: number;
	uuid: string;
	brand: string;
	lastFour: string;
	expirationMonth: string;
	expirationYear: string;
	createdOn: Date;

	breweryId: number;
	breweryName: string;
	brewreySlug: string;
	breweryLogo: string;
	breweryPhone: string;
	breweryEmai: string;
}
