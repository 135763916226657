import { Beer } from '../beer/beer';
import { User } from '../user/user';
import { BreweryLocation } from '../brewery-location/brewery-location';
import { Category } from '../category/category';
import { ShippingProvider } from '../shipping-provider/shipping-provider';
import { TaxRate } from '../tax-rate/tax-rate';
import { Subscription } from '../subscription/subscription';

export class BreweryPaymentType {
	id: number;
	breweryId: number;
	paymentTypeId: number;
	publicKey: string;
	privateKey: string;
	securityKey: string;
	title: string;
}

export class BreweryBreweryDeliveryZone {
	id: number;
	breweryId: number;
	title: string;
	originAddress: string;
	originLatitude: number;
	originLongitude: number;
	salesTax: number;
	deliveryFee: number;
	deliveryFeeTax: number;
	deliveryFeeDistance: boolean;
	deliveryFeeWaivedAtAmount: number;
	minimumOrder: number;
	minimumVolume: number;
	maximumOrder: number;
	maximumVolume: number;
	published: boolean;

	maximumRadius: number;
	stateLimit: string

	active: boolean;
	deliveryZoneId: number;
	country: string;
	state: string;
	county: string;
	postalCode: string;
	radius: number;
	minLatitude: number;
	maxLatitude: number;
	minLongitude: number;
	maxLongitude: number;
}

export class Brewery {
	id: number;
	uuid: string;
	slug: string;
	live: boolean;
	test: boolean;
	name: string;
	legalName: string;
	logo: string;
	retailLicenseNumber: string;
	alcoholAgeMinimumYears: number;
	description: string;
	address: string;
	address2: string;
	city: string;
	state: string;
	zip: string;
	latitude: number;
	longitude: number;
	phone: string;
	email: string;
	website: string;
	ageGate: boolean;

	locations: BreweryLocation[];

	deliveryEnabled: boolean;
	deliverySameDayCutoff: boolean;
	deliveryWindow: boolean;
	deliveryInstructions: string;
	deliveryTipEnabled: boolean;

	deliveryEmailContentConfirmation: string;
	deliveryEmailContentOutForDelivery: string;
	deliveryEmailContentUpNext: string

	salesTax: number;
	salesTaxIncluded: boolean;
	digitalEnabled: boolean;

	pickupEnabled: boolean;
	pickupHours: string;
	pickupInstructions: string;
	pickupInstructionsEnabled: boolean;
	pickupDesignationEnabled: boolean;
	pickupSalesTax: number;

	pickupMinimumOrder: number;
	pickupMinimumVolume: number;
	pickupMaximumOrder: number;
	pickupMaximumVolume: number;
	pickupTipEnabled: boolean;
	pickupDelayedCapture: boolean;
	pickupPreparedEmailNotification: boolean;
	pickupPreparedSMSNotification: boolean;

	shippingEnabled: boolean;
	shippingInstructions: string;
	shippingMinimumOrder: number;
	shippingMinimumVolume: number;
	shippingMaximumOrder: number;
	shippingMaximumVolume: number;
	shippingFreeShippingEnabled: boolean;
	shippingFreeShippingMinimumOrder: number;
	shippingDeliveryOfferOnAddressEntered: boolean;
	shippingShowCostDetails: boolean;

	dineInMinimumOrder: number;
	dineInMinimumVolume: number;
	dineInMaximumOrder: number;
	dineInMaximumVolume: number;

	tipRateDineIn1: number;
	tipRateDineIn2: number;
	tipRateDineIn3: number;
	tipRatePickup1: number;
	tipRatePickup2: number;
	tipRatePickup3: number;
	tipRateDelivery1: number;
	tipRateDelivery2: number;
	tipRateDelivery3: number;

	settingCollectDateOfBirth: boolean;
	promoCodesEnabled: boolean;
	active: boolean;
	snooze: boolean;
	snoozedOn: Date;

	photoUrl: string;

	beers: Beer[];
	subscriptions: Subscription[];
	categories: Category[];
	taxes: TaxRate[];

	deliveryZones: BreweryBreweryDeliveryZone[];
	shippingProviders: ShippingProvider[];
	paymentTypes: BreweryPaymentType[];
	users: User[];
	roleId;

	untappdUsername: string;
	untappdBreweryId: number;
	untappdBreweryName: string;
	untappdBreweryPageUrl: string;
	untappdBrewerySlug: string;

	timezone: string;
	confirmationText: string;
	settingsVerbiageDeposit: string;
	unavailableTitle: string;
	unavailableMessage: string;
	secretMenuEnabled: boolean;
	billingCardStored: boolean;
	billingCardFailed: boolean;
	billingCustomerId: string;

	visible: boolean;
	liveOn: Date;

	googleAnalyticsMeasurementId: string;
}
