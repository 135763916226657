import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-square',
	templateUrl: './square.component.html',
	styleUrls: ['../../../public.scss', './square.component.scss']
})
export class SquareComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
