import { Component, OnInit, ViewChild, ElementRef, NgZone, Output, EventEmitter, Input } from '@angular/core';
import { Place } from 'src/app/classes/biermi';

@Component({
	selector: 'biermi-address-input',
	templateUrl: './address-input.component.html',
	styleUrls: ['./address-input.component.scss']
})
export class AddressInputComponent implements OnInit {

	@Input('autofocus') autofocus: boolean = true;
	@Input('placeholder') placeholder: string = 'Enter Your Address';
	@Input('searchType') searchType: string = 'address';
	@Input('geolocation') geolocation: boolean = true;
	@Input('branded') branded: boolean = true;
	@ViewChild('addresstext', { static: false }) addresstext: ElementRef;
	@Output('onSelected') onSelected = new EventEmitter();

	autocompleteInput: string;

	constructor(private ngZone: NgZone) { }

	ngOnInit() {
	}

	ngAfterViewInit() {
		this.getPlaceAutocomplete();
	}

	setupAddressField() {
		this.getPlaceAutocomplete();

		setTimeout(() => {
			this.addresstext.nativeElement.focus();
		}, 100);
	}

	private getPlaceAutocomplete() {
		var types = ['address'];
		if(this.searchType == 'region') {
			types = ['geocode'];
		}

		var fields = ['place_id', 'formatted_address', 'address_components'];
		if(this.geolocation) {
			fields.push('geometry');
		}

        const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
			componentRestrictions: { country: ['US', 'CA'] },
			types: types,
			fields: fields
		});
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
			const place = autocomplete.getPlace();
            this.invokeEvent(place);
		});

		// Handle focus
		if(this.autofocus) {
			setTimeout(() => {
				this.addresstext.nativeElement.focus();
			}, 100);
		}
    }

    invokeEvent(place: any) {
		this.ngZone.run(() => {
			// var result = new Place(place);
			// result.name = place.name;
			// result.formatted_address = place.formatted_address;
			// result.place_id = place.place_id;

			// if(place.geometry) {
			// 	result.latitude = place.geometry.location.lat();
			// 	result.longitude = place.geometry.location.lng();
			// }

			// var pieces = place['address_components'];
			// if(!pieces) {
			// 	return;
			// }
			// pieces.forEach(piece => {
			// 	if(piece.types.find(t => t == 'street_number')) {
			// 		result.details.street_number = piece.long_name;
			// 	} else if(piece.types.find(t => t == 'route')) {
			// 		result.details.route = piece.long_name;
			// 	} else if(piece.types.find(t => t == 'neighborhood')) {
			// 		result.details.neighborhood = piece.long_name;
			// 	} else if(piece.types.find(t => t == 'locality')) {
			// 		result.details.locality = piece.long_name;
			// 	} else if(piece.types.find(t => t == 'administrative_area_level_2')) {
			// 		result.details.administrative_area_level_2 = piece.long_name;
			// 	} else if(piece.types.find(t => t == 'administrative_area_level_1')) {
			// 		result.details.administrative_area_level_1 = piece.long_name;
			// 		result.details.stateCode = piece.short_name;
			// 	} else if(piece.types.find(t => t == 'country')) {
			// 		result.details.country = piece.long_name;
			// 	} else if(piece.types.find(t => t == 'postal_code')) {
			// 		result.details.postal_code = piece.long_name;
			// 	} else if(piece.types.find(t => t == 'postal_code_suffix')) {
			// 		result.details.postal_code_suffix = piece.long_name;
			// 	}
			// });
			// this.onSelected.emit(result);
			this.onSelected.emit(new Place(place));
			this.autocompleteInput = '';
		});
	}
}
