import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

	constructor(private router: Router, private api: ApiService) { }

	ngOnInit() {
	}

	logSponsorClick(location) {
		console.log(location);
		this.api.postAnalyticsEvent(3, {
			location: location,
			url: this.router.url
		}).toPromise();
	}
}
