import { Order } from '../order/order';

export const SUBSCRIPTION_FREQUENCY_TYPES = {
	weekly: 1,
	monthly: 2,
	yearly: 3
}

export class UserSubscription {
	id: number;

	subscription: Subscription;

	userId: number;
	userFirstName: string;
	userLastName: string;
	userPhone: string;
	userEmail: string;

	subscriptionId: number;
	userSubscriptionId: number;
	sourceOrderId: number;
	userCardId: number;
	frequency: number;

	pickup: boolean = true;
	delivery: boolean = true;
	shipping: boolean = true;

	breweryDeliveryZoneId: number;

	address: string;
	addressCountry: string;
	addressCounty: string;
	addressLocality: string;
	addressNeighborhood: string;
	addressPostalCode: string;
	addressPostalCodeSuffix: string;
	addressRoute: string;
	addressState: string;
	addressStateCode: string;
	addressStreetNumber: string;
	latitude: number;
	longitude: number;

	orders: Order[];

	lastBillDate: string;
	nextBillDate: string;
	createdOn: Date;
}

export class Subscription {
	id: number;
	breweryId: number;
	title: string;
	description: string;

	photo: string;

	pickupEnabled: boolean = true;
	deliveryEnabled: boolean = true;
	shippingEnabled: boolean = true;

	packageId: number;

	unitPrice: number;

	frequencyTypeId: number = 2;
	frequencyWeeks1: boolean = true;
	frequencyWeeks2: boolean = true;
	frequencyWeeks3: boolean = true;
	frequencyWeeks4: boolean = true;
	frequencyWeeks5: boolean = true;
	frequencyAlignCycles: boolean = true;
	frequencyMonths: number;
	frequencyMonthsMonth: number;
	frequencyMonthsDay: number;
	frequencyYearlyMonth: number;
	frequencyYearlyDay: number;

	published: boolean = false;

	users: [];

	// Order Specific
	frequency: number;
	taxRateId: number;
	salesTaxPercent: number;
	salesTax: number;
}
