import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SearchService {

	place;

	constructor() { }

	setPlace(place) {
		this.place = place;
	}

	getPlace() {
		return this.place;
	}
}
