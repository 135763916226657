import { Table } from '../table/table';

export class BreweryLocation {
	id: number;
	breweryId: number;
	externalId: string;
	title: string = '';
	externalTitle: string;

	address: string = '';
	addressStreetNumber: string = '';
	addressRoute: string = '';
	addressNeighborhood: string = '';
	addressLocality: string = '';
	addressCounty: string = '';
	addressState: string = '';
	addressStateCode: string = '';
	addressCountry: string = '';
	addressPostalCode: string = '';
	addressPostalCodeSuffix: string = '';

	latitude: number;
	longitude: number;

	pickupServiceFee: number = 0;
	pickupServiceFeePercentage: number = 0;
	pickupWindowSelectionEnabled: boolean;
	pickupWindowSelectionRequired: boolean;
	pickupWindowTimeSelectionEnabled: boolean;
	pickupWindowTimeIncrements: number;
	pickupWindowPreparationTime: number;
	pickupWindowLimit: number;
	pickupSlotsSelected: {};

	enabled: boolean = true;
	marketplaceEnabled: boolean = true;
	dineInEnabled: boolean;
	dineInPreparedSMSNotification: boolean;

	pickupWindowDays: number;
	pickupWindowMondayStart: number;
	pickupWindowMondayEnd: number;
	pickupWindowTuesdayStart: number;
	pickupWindowTuesdayEnd: number;
	pickupWindowWednesdayStart: number;
	pickupWindowWednesdayEnd: number;
	pickupWindowThursdayStart: number;
	pickupWindowThursdayEnd: number;
	pickupWindowFridayStart: number;
	pickupWindowFridayEnd: number;
	pickupWindowSaturdayStart: number;
	pickupWindowSaturdayEnd: number;
	pickupWindowSundayStart: number;
	pickupWindowSundayEnd: number;

	pickupCurbsideEnabled: boolean;
	pickupSpotNumberEnabled: boolean;

	happyHourMondayStart: number;
	happyHourMondayEnd: number;
	happyHourTuesdayStart: number;
	happyHourTuesdayEnd: number;
	happyHourWednesdayStart: number;
	happyHourWednesdayEnd: number;
	happyHourThursdayStart: number;
	happyHourThursdayEnd: number;
	happyHourFridayStart: number;
	happyHourFridayEnd: number;
	happyHourSaturdayStart: number;
	happyHourSaturdayEnd: number;
	happyHourSundayStart: number;
	happyHourSundayEnd: number;

	tables: Table[] = [];

	error: string;
	editing: boolean;
	loading: boolean;
}
