import { Component, OnInit } from '@angular/core';
import { ModalService } from './modal.service';
import { ApiService } from '../api/api.service';

declare var $: any;

@Component({
	selector: 'biermi-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

	title: string;
	body: string;
	type: string;
	callback: Function;
	cancelCallback: Function;
	error: string;
	loading: boolean;

	multiple: boolean;
	exclude: any[];
	untappdBeers: any;
	filteredUntappdBeers: any;
	selected = [];
	filterText = '';

	toasting: boolean;
	toast;

	constructor(private modalSerivce: ModalService, private api: ApiService) { }

	ngOnInit() {
		this.modalSerivce.confirmation.subscribe(confirmation => {
			this.type = confirmation.type;
			this.title = confirmation.title;
			this.body = confirmation.body;
			this.callback = confirmation.callback;
			this.cancelCallback = confirmation.cancelCallback;

			this.multiple = confirmation.multiple;
			this.exclude = confirmation.exclude;

			this.selected = [];
			this.error = null;
			this.loading = false;
			this.untappdBeers = null;

			if(this.type == 'untappd-import') {
				this.searchBeers();
			}

			$('.modal-confirm').modal('show');
		});

		this.modalSerivce.toast.subscribe(toast => {
			this.toasting = true;
			this.toast = toast;

			setTimeout(() => {
				$('.modal-toast-container').fadeIn();
			}, 50);

			setTimeout(() => {
				$('.modal-toast-container').fadeOut();
				setTimeout(() => {
					this.toasting = false;
				}, 200);

			}, 1000);
		});
	}

	affirm() {
		$('.modal-confirm').modal('hide');
		if(this.callback) {
			if(this.type == 'untappd-import') {
				this.callback(this.selected);
				this.filterText = '';
			} else {
				this.callback();
			}
		}
	}

	cancel() {
		if(this.cancelCallback) {
			this.cancelCallback();
		}
		this.callback = null;
		this.cancelCallback = null;
		$('.modal-confirm').modal('hide');
	}

	// Untappd

	searchBeers() {
		this.error = null;
		this.loading = true;
		this.untappdBeers = null;


		this.api.getUntappdSearchBeers(this.filterText).subscribe((response: any) => {
			this.untappdBeers = response.beers.items;
			this.loading = false;
		}, error => {
			this.error = `We were unable to complete your search`;
			this.loading = false;
		});
	}

	selectBeer(beer) {
		this.selected.push(beer);
	}

	isSelected(beer) {
		return this.selected.indexOf(beer) > -1;
	}

	isExcluded(beer) {
		return this.exclude && this.exclude.indexOf(beer.bid) > -1;
	}

	removeSelection(beer) {
		var index = this.selected.indexOf(beer);
		if(index > -1) {
			this.selected.splice(index, 1);
		}
	}
}
