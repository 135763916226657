import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
	selector: 'biermi-footer-social',
	templateUrl: './footer-social.component.html',
	styleUrls: ['./footer-social.component.scss']
})
export class FooterSocialComponent implements OnInit {

	showUntappdLink: boolean;

	constructor(private authService: AuthenticationService) { }

	ngOnInit() {
		this.showUntappdLink = this.authService.isLoggedIn();
	}

	getUntappdURL() {
		return `${environment.integrations.untappd.url}?client_id=${environment.integrations.untappd.clientId}&response_type=code&redirect_url=${environment.integrations.untappd.redirectUrl}`;
	}

}
