import { Beer } from '../beer/beer';
import { Brewery, BreweryBreweryDeliveryZone } from '../brewery/brewery';
import * as moment from 'moment';
import { Code } from '../code/code';
import { Subscription } from '../subscription/subscription';
import { TaxRate } from '../tax-rate/tax-rate';
import { Refund } from '../refund/refund';
import { Membership } from '../membership/membership';
import { Package } from '../package/package';
import { BreweryLocation } from '../brewery-location/brewery-location';
import { Photo } from '../photo/photo';

export class Order {
	id: number;
	uuid: string;
	breweryId: number;
	userId: number;
	digital: boolean = false;
	delivery: boolean = false;
	pickup: boolean = false;
	shipping: boolean = false;
	dineIn: boolean = false;
	name: string = '';
	lastName: string = '';
	dateOfBirth: string;
	phone: string = '';
	email: string = '';
	pickupLocation: BreweryLocation;
	pickupLocationId: number = 0;
	pickupLocationTitle: string;
	breweryLocationTitle: string; // used in orders lists
	tableId: number = 0;
	tableTitle: string; // used in orders lists
	pickupWindow: string = '';
	pickupWindowDate: string;
	pickupWindowTime: number;
	deliveryZone: BreweryBreweryDeliveryZone;
	deliveryZoneId: number;
	breweryShippingProviderId: number;
	shippingProviderTypeId: number;
	address: string = '';
	unitNumber: string = '';
	country: string;

	addressStreetNumber: string;					// 1621
	addressRoute: string;							// Catharine Street
	addressNeighborhood: string;					// Graduate Hospital
	addressLocality: string;						// Philadelphia
	addressCounty: string;							// Philadelphia County
	addressState: string;							// Pennsylvania
	addressStateCode: string;						// Pennsylvania
	addressCountry: string;							// United States
	addressPostalCode: string;						// 19146
	addressPostalCodeSuffix: string;				// 2020

	state: string;
	county: string;
	zipCode: string;
	latitude: number;
	longitude: number;
	deliveryInstructions: string;
	pickupDesignatedAlternative: string;
	beers: Beer[] = [];
	subscriptions: Subscription[] = [];
	items: []; // for the confirmation page
	breweryPaymentTypeId: number;
	stripeToken: string;
	chargeToken: string;
	savedToken: string;
	chargeSession: string;
	orderChargeStateId: number;
	orderChargeState: string;

	codes: Code[] = [];
	memberships: Membership[] = [];

	membershipId: number;
	membershipDiscountPercent: number;
	membershipTaxFree: boolean;
	userSubscriptionId: number;

	happyHour: boolean;
	discount: number;
	subTotal: number;
	salesTaxPercent: number;
	salesTax: number;
	deposit: number;
	bottleDeposit: number;
	deliveryCharge: number;
	deliveryChargeDiscount: number;
	deliveryChargeTax: number;
	tipPercentage: number;
	tipAmount: number = null;
	tipSet: boolean = false;
	total: number;

	privateNote: string;
	statusPrepared: boolean;
	statusLoading: boolean;
	statusOutForDelivery: boolean;
	statusEnRoute: boolean;
	statusRescheduled: boolean;
	statusFulfilled: boolean;
	deliveredOn: Date;
	pickedUpOn: Date;
	preparedByFirstName: string;
	preparedByLastName: string;
	fulfilledByFirstName: string;
	fulfilledByLastName: string;

	photoID: string;
	photos: Photo[] = [];

	refunds: Refund[];
	packages: Package[];

	createdOn: Date;

	// Temp
	deliveryId: number;

	// In-page pieces
	selectedForDelivery: boolean;
	details: any;
	checkoutAsGuest: boolean = false;
	creatingShipment: boolean;
	creatingShipmentError: string;
	shipmentAddressCandidates: string;

	// Pick-up
	pickupCheckedIn: boolean;
	pickupCheckedInOn: Date;
	pickupVehicleMake: string;
	pickupVehicleModel: string;
	pickupVehicleColor: string;
	pickupSpot: string;
	pickupCheckinNotes: string;

	// Delivery Orders + Tracking page
	deliveryOrderId: number;
	rank: number;
	startAddress: string;
	startLatitude: number;
	startLongitude: number;
	endAddress: string;
	endLatitude: number;
	endLongitude: number;
	distance: number;
	duration: number;
	estimatedArrivalTime: Date;
	driverFirstName: string;
	driverLastNameInitial: string;

	shippingProvider: {
		id: string;
		shippingProviderTypeId: number;
		trackingUrl: string;
	};

	deliveryStop: number;
	deliveryStopCompleted: number;

	// Shipping
	shippingRating: any;
	shippingProviderServiceId: number;
	shippingTrackingIdentifier: string;
	shippingTransactionIdentifier: string;

	// Reports
	breweryDeliveryZoneId: number;

	// Safety
	safetyLevel: number;

	// Methods

	static getConfirmationNumber(order) {
		return order.uuid.slice(-8).toUpperCase();
	}

	static round(num) {
		return Math.round((num + Number.EPSILON) * 100) / 100;
	};

	static roundBankers(num) {
		var result = Math.round(num * 100);
		if(((((num > 0) ? num : (-num)) * 100) % 1) === 0.5) {
			if(0 != (result % 2)) {
				result -= 1;
			}
		}

		return Order.round(result / 100);
	};

	getDateOfBirth() {
		if(!this.dateOfBirth || this.dateOfBirth.length < 1) {
			return;
		}
		return moment(this.dateOfBirth);
	}

	setDateOfBirth(momentDate) {
		if(!momentDate.isValid() || moment().diff(momentDate, 'years', false) > 150) {
			this.dateOfBirth = null;
			return;
		}
		this.dateOfBirth = momentDate.format('YYYY-MM-DD');
	}

	applyCode(code) {
		// Filter to only early access
		this.codes.unshift(code);

		var earlyAccess = this.codes.find(c => c.codeTypeId == 1);
		var deliveryDiscount = this.codes.find(c => c.codeTypeId == 2);
		var percentDiscount = this.codes.find(c => c.codeTypeId == 3);
		var amountDiscount = this.codes.find(c => c.codeTypeId == 4);
		var referral = this.codes.find(c => c.codeTypeId == 5);
		var privateAccess = this.codes.find(c => c.codeTypeId == 6);

		var newCodes = [];
		if(earlyAccess) {
			newCodes.push(earlyAccess)
		}
		if(deliveryDiscount) {
			newCodes.push(deliveryDiscount);
		}
		if(percentDiscount) {
			newCodes.push(percentDiscount);
		}
		if(amountDiscount) {
			newCodes.push(amountDiscount);
		}
		if(referral) {
			newCodes.push(referral);
		}
		if(privateAccess) {
			newCodes.push(privateAccess)
		}
		this.codes = newCodes;
	}

	strikePromoCodes() {
		this.codes = this.codes.filter(c => c.codeTypeId == 1 || c.codeTypeId == 5);
	}

	getItemVolumeOunces(beer) {
		var volume;
		if(beer.selectedVariation && beer.selectedVariation.packageUnitVolume && beer.selectedVariation.packageUnitCount) {
			volume =  beer.selectedVariation.packageUnitVolume * beer.selectedVariation.packageUnitCount;
			if(beer.selectedVariation.packageUnitVolumeType == 'mL') {
				volume = volume / 29.574;
			} else if(beer.selectedVariation.packageUnitVolumeType == 'l') {
				volume = volume * 33.814
			} else if(beer.selectedVariation.packageUnitVolumeType == 'gal') {
				volume = volume * 128;
			}
		} else {
			if(beer.packageUnitVolume && beer.packageUnitCount) {
				volume =  beer.packageUnitVolume * beer.packageUnitCount;
				if(beer.packageUnitVolumeType == 'mL') {
					volume = volume / 29.574;
				} else if(beer.packageUnitVolumeType == 'l') {
					volume = volume * 33.814
				} else if(beer.packageUnitVolumeType == 'gal') {
					volume = volume * 128;
				}
			}
		}
		return volume;
	}

	getOrderVolume() {
		var volume = 0.0;
		this.beers.forEach(beer => {
			volume += this.getItemVolumeOunces(beer) * beer.selectedQuantity;
		});
		return volume;
	}

	getValidCodes() {
		return this.codes.filter(c =>
			(this.delivery && c.deliveryEnabled) ||
			(this.pickup && c.pickupEnabled) ||
			(this.shipping && c.shippingEnabled) ||
			(this.dineIn && c.dineInEnabled) ||
			(this.digital && c.digitalEnabled)
		);
	}

	// Financials

	getItemSubtotal(item) {
		var total = 0;
		var price = 0;
		if(item.selectedVariation) {
			if(this.happyHour && item.selectedVariation.variationPriceHappyHour) {
				price = item.selectedVariation.variationPriceHappyHour;
			} else {
				price = item.selectedVariation.variationPrice
			}
		} else {
			if(this.happyHour && item.unitPriceHappyHour) {
				price = item.unitPriceHappyHour;
			} else {
				price = item.unitPrice;
			}
		}

		total += price * item.selectedQuantity;

		return Order.round(total);
	}

	updateOrderSubtotals() {
		var preDiscountedSubtotal = 0;
		this.beers.forEach(beer => {
			beer.subTotal = this.getItemSubtotal(beer);
			beer.discount = 0;
			preDiscountedSubtotal += beer.subTotal;
		});

		// Discount Codes
		var codes = this.getValidCodes();
		var discountPercentage = codes.find(c => c.codeTypeId == 3);
		var discountAmount = codes.find(c => c.codeTypeId == 4);
		var applies = true;
		if(discountPercentage) {
			// Check applied amount
			if(discountPercentage.appliedLevel) {
				if(preDiscountedSubtotal < discountPercentage.appliedLevel) {
					applies = false;
				}
			}

			// Check applied volume
			if(discountPercentage.appliedVolume) {
				if(this.getOrderVolume() < discountPercentage.appliedVolume) {
					applies = false;
				}
			}

			// Check day of week
			if(discountPercentage.dayOfWeek && discountPercentage.dayOfWeek != moment().weekday()) {
				applies = false;
			}

			// Apply discount
			this.beers.forEach(beer => {
				if(applies && discountPercentage) {
					if(
						(!discountPercentage.beerId || beer.id == discountPercentage.beerId) &&
						(!discountPercentage.itemCategoryId || beer.itemCategoryId == discountPercentage.itemCategoryId)
					) {
						beer.discount = beer.subTotal * discountPercentage.amount;
						beer.discount = Order.round(beer.discount);
					}
				} else {
					beer.discount = 0;
				}
			});
		}

		if(discountAmount) {
			var remainingAmount = discountAmount.amount;
			// Check applied amount
			if(discountAmount.appliedLevel) {
				if(preDiscountedSubtotal < discountAmount.appliedLevel) {
					applies = false;
				}
			}

			// Check applied volume
			if(discountAmount.appliedVolume) {
				if(this.getOrderVolume() < discountAmount.appliedVolume) {
					applies = false;
				}
			}

			// Check day of week
			if(discountAmount.dayOfWeek && discountAmount.dayOfWeek != moment().weekday()) {
				applies = false;
			}

			// Apply discount
			this.beers.forEach(beer => {
				if(applies && discountAmount) {
					if(
						(!discountAmount.beerId || beer.id == discountAmount.beerId) &&
						(!discountAmount.itemCategoryId || beer.itemCategoryId == discountAmount.itemCategoryId)
					) {
						if(remainingAmount < beer.subTotal) {
							beer.discount = remainingAmount;
							remainingAmount = 0;
						} else {
							beer.discount = beer.subTotal;
							remainingAmount -= beer.subTotal;
						}
					}
				} else {
					beer.discount = 0;
				}
			});
		}

		// Override with memberships (maybe)
		if(this.memberships) {
			var membership;
			this.memberships.filter(m => m.breweryId == this.breweryId).forEach(m => {
				if(m.discountPercent && m.discountPercent > 0 && m.discountPercent <= 1) {
					if(!membership || m.discountPercent > membership.discountPercent) {
						membership = m;
					}
				}
			});

			// Found one!
			if(membership) {
				this.membershipId = membership.id;
				this.membershipDiscountPercent = membership.discountPercent;
				this.beers.forEach(item => {
					// Only update this if the membership discount is more (this is different than the API because we don't cache the discount percent on the beer)
					if(!item.discount || item.discount < (item.subTotal * membership.discountPercent)) {
						item.discount = item.subTotal * membership.discountPercent;
						item.discount = Math.round((item.discount + Number.EPSILON) * 100) / 100;
					}
				});
			}

			// Tax free memberships
			if(this.memberships.find(m => m.taxFree)) {
				this.membershipTaxFree = true;
				this.beers.forEach(item => {
					item.salesTaxPercent = 0;
				});
			}
		}

		// Run it
		this.discount = 0;
		this.deposit = 0;
		this.bottleDeposit = 0;
		this.salesTax = 0;
		this.subTotal = 0;

		this.beers.forEach(item => {
			item.depositTotal = this.getItemDeposit(item);
			item.depositTotal = Order.round(item.depositTotal);

			item.bottleDepositTotal = this.getItemBottleDeposit(item);
			item.bottleDepositTotal = Order.round(item.bottleDepositTotal);

			item.subTotal = this.getItemSubtotal(item); // call it anyway
			item.subTotal -= item.discount;
			item.subTotal = Order.round(item.subTotal);

			item.salesTax = item.salesTaxPercent * item.subTotal;
			item.salesTax = Order.roundBankers(item.salesTax);

			item.total = item.subTotal;
			item.total += item.depositTotal;
			item.total += item.bottleDepositTotal;
			item.total += item.salesTax;
			item.total = Order.round(item.total);

			// Order Totals
			this.discount += item.discount;
			this.deposit += item.depositTotal;
			this.bottleDeposit += item.bottleDepositTotal;
			this.salesTax += item.salesTax;
			this.subTotal += item.subTotal;
		});

		// Add Subscriptions
		this.subscriptions.forEach(subscription => {
			this.salesTax += subscription.salesTax;
			this.subTotal += subscription.unitPrice;
		});

		// Tip
		this.tipAmount = this.tipAmount ? +this.tipAmount : 0;
	}

	setTipPercentage(percentage) {
		this.tipPercentage = percentage;
		this.tipAmount = +(this.subTotal * percentage).toFixed(2);
		this.tipSet = true;
	}

	getTaxRate(brewery: Brewery, item) {
		var taxRate: TaxRate = new TaxRate();

		// Default
		taxRate.rate = brewery.salesTax; // default sales tax on the business (aka brewery)

		// Legacy sales tax on delivery zone
		if(this.delivery && this.deliveryZone.salesTax != null) {
			taxRate.rate = this.deliveryZone.salesTax;
		}

		// Legacy categories + sales tax
		var category = brewery.categories.find(c => c.id == item.itemCategoryId);
		if(category) {
			if(this.pickup && category.salesTax) {
				taxRate.rate = category.salesTax;
			}
		}

		// filter based on fulfillment method and associated location
		var taxRates = brewery.taxes.filter(tr => (
			(this.pickup || this.dineIn) && tr.pickup && this.pickupLocationId == tr.breweryLocationId
		) || (
			this.dineIn && tr.dineIn && this.pickupLocationId == tr.breweryLocationId
		) || (
			this.delivery && tr.delivery && this.deliveryZone.id == tr.breweryDeliveryZoneId // note that we're pulling the delivery zone id off off the zone here and it's flattened on the server
		) || (
			this.shipping && tr.shipping && this.addressStateCode == tr.shippingStateCode
		) || (
			this.digital && tr.digital
		));

		// See if dine-in is more specific than pick-up
		if(this.dineIn && taxRates.filter(tr => tr.dineIn).length > 0) {
			taxRates = taxRates.filter(tr => tr.dineIn);
		}

		// if delivery or shipping, sort by zip code
		if((this.delivery || this.shipping) && this.addressPostalCode && taxRates.find(tr => tr.postalCode == this.addressPostalCode)) {
			taxRates = taxRates.filter(tr => tr.postalCode == this.addressPostalCode);
		}

		// filter based on category
		if(item.itemCategoryId) {
			var categoryFilteredTaxRates = taxRates.filter(tr => item.itemCategoryId == tr.itemCategoryId);
			if(categoryFilteredTaxRates.length < 1) {
				taxRates = taxRates.filter(tr => !tr.itemCategoryId);
			} else {
				taxRates = categoryFilteredTaxRates;
			}
		} else {
			taxRates = taxRates.filter(tr => !tr.itemCategoryId);
		}
		if(taxRates.length > 0) {
			taxRate = taxRates[0];
		}
		return taxRate;
	}

	updateOrderTaxes(brewery: Brewery) {
		// Products
		this.beers.forEach(item => {
			var taxRate = this.getTaxRate(brewery, item);
			item.taxRateId = taxRate.id;
			item.salesTaxPercent = taxRate.rate;
		});

		// Subscriptions
		this.subscriptions.forEach(item => {
			var taxRate = this.getTaxRate(brewery, item);
			item.taxRateId = taxRate.id;
			item.salesTaxPercent = taxRate.rate;

			item.salesTax = item.salesTaxPercent * item.unitPrice; // different for subscriptions
			item.salesTax = Math.round((item.salesTax + Number.EPSILON) * 100) / 100;
		});
	}

	getItemDeposit(beer) {
		if(beer.selectedVariation) {
			return (beer.selectedVariation.deposit ? beer.selectedVariation.deposit : 0) * beer.selectedQuantity;
		} else {
			return (beer.deposit ? beer.deposit : 0) * beer.selectedQuantity;
		}
	}

	getItemBottleDeposit(beer) {
		var deposit = 0;
		var bottleDeposit = beer.packageBottleDeposit;
		var packageUnitVolume = beer.packageUnitVolume;
		var packageUnitVolumeType = beer.packageUnitVolumeType;
		var unitCount = beer.packageUnitCount;

		if(beer.selectedVariation) {
			bottleDeposit = beer.selectedVariation.packageBottleDeposit;
			packageUnitVolume = beer.selectedVariation.packageUnitVolume;
			packageUnitVolumeType = beer.selectedVariation.packageUnitVolumeType;
			unitCount = beer.selectedVariation.packageUnitCount;
		}

		if(bottleDeposit) {
			var unitVolumeOunces = packageUnitVolume;
			if(packageUnitVolumeType == 'mL') {
				unitVolumeOunces = packageUnitVolume / 29.574;
			} else if(packageUnitVolumeType == 'l') {
				unitVolumeOunces = packageUnitVolume  * 33.814
			} else if(packageUnitVolumeType == 'gal') {
				unitVolumeOunces = packageUnitVolume * 128;
			}

			switch(this.addressStateCode) {
				case 'CA':
					if(unitVolumeOunces < 24) {
						deposit = .05;
					} else {
						deposit = .1;
					}
					break;
				case 'CT':
					deposit = .05;
					break;
				case 'HI':
					deposit = .05;
					break;
				case 'IA':
					deposit = .05;
					break;
				case 'ME':
					if(beer.categorySpirits || beer.categoryWine) {
						deposit = .15;
					} else {
						deposit = .05;
					}
					break;
				case 'MA':
					deposit = .05;
					break;
				case 'MI':
					deposit = .1;
					break;
				case 'NY':
					deposit = .05;
					break;
				case 'OR':
					deposit = .1;
					break;
				case 'VT':
					if(beer.categorySpirits) {
						deposit = .15;
					} else {
						deposit = .05;
					}
					break;
				case 'GU':
					deposit = .05;
					break;
			}
		}
		return deposit * unitCount * beer.selectedQuantity;
	}

	updateDeliveryFee() {
		if(this.shipping) {
			this.deliveryCharge = this.deliveryCharge ? this.deliveryCharge : 0;
		} else if(this.pickup && this.pickupLocation) {
			this.deliveryCharge = 0;
			if(this.pickupLocation.pickupServiceFee > 0) {
				this.deliveryCharge = this.pickupLocation.pickupServiceFee;
			} else if(this.pickupLocation.pickupServiceFeePercentage > 0) {
				this.deliveryCharge = this.pickupLocation.pickupServiceFeePercentage * this.subTotal;
			}
		} else if(this.deliveryZone) {
			this.deliveryCharge = this.deliveryZone.deliveryFee;
			this.deliveryChargeTax = this.deliveryZone.deliveryFeeTax;
		} else {
			this.deliveryCharge = 0;
		}

		// Discount code for delivery / shipping
		var codes = this.getValidCodes();

		var discountDelivery = codes.find(c => c.codeTypeId == 2);
		if(discountDelivery) {
			var preDiscountedSubtotal = 0;
			this.beers.forEach(beer => {
				beer.subTotal = this.getItemSubtotal(beer);
				beer.discount = 0;
				preDiscountedSubtotal += beer.subTotal;
			});

			var applies = true;

			// Check applied amount
			if(discountDelivery.appliedLevel) {
				if(preDiscountedSubtotal < discountDelivery.appliedLevel) {
					applies = false;
				}
			}

			// Check applied volume
			if(discountDelivery.appliedVolume) {
				if(this.getOrderVolume() < discountDelivery.appliedVolume) {
					applies = false;
				}
			}

			// Check day of week
			if(discountDelivery.dayOfWeek && discountDelivery.dayOfWeek != moment().weekday()) {
				applies = false;
			}

			if(applies) {
				this.deliveryChargeDiscount = this.deliveryCharge * discountDelivery.amount
				this.deliveryChargeDiscount = Math.round((this.deliveryChargeDiscount + Number.EPSILON) * 100) / 100;

				this.deliveryCharge = this.deliveryCharge - this.deliveryChargeDiscount;
				this.deliveryCharge = this.deliveryCharge < 0 ? 0 : this.deliveryCharge;
			}
		}

		this.deliveryCharge = Math.round((this.deliveryCharge + Number.EPSILON) * 100) / 100;
	}

	updateTotal(brewery: Brewery) {
		this.updateOrderTaxes(brewery); // must come before updating order totals
		this.updateOrderSubtotals();
		this.updateDeliveryFee();

		// Waive delivery fee at amount
		if(this.deliveryZone && this.deliveryZone.deliveryFeeWaivedAtAmount && this.deliveryZone.deliveryFeeWaivedAtAmount > 0 && this.deliveryZone.deliveryFeeWaivedAtAmount < this.subTotal) {
			this.deliveryCharge = 0;
			this.deliveryChargeTax = 0;
		}

		// Additional Taxes
		if(this.deliveryChargeTax && this.deliveryChargeTax > 0) {
			this.salesTax += this.deliveryChargeTax;
		}

		this.total = this.subTotal + this.tipAmount + this.salesTax + this.deposit + this.bottleDeposit + this.deliveryCharge;
		this.total = Math.round((this.total + Number.EPSILON) * 100) / 100;
	}

	// Validation

	canCheckout() {
		return false;
	}

	validateEmail(email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	validatePhone(phone) {
		var digits = '0123456789';
		var phoneStripped = '';
		for(var i=0; i<phone.length; i++) {
			var char = phone[i];
			if(digits.indexOf(char) > -1) {
				phoneStripped += char;
			}
		}
		return phoneStripped.length >= 10;
	}

	maxDateOfBirth(brewery: Brewery) {
		return moment().subtract(brewery.alcoholAgeMinimumYears, 'years');
	}

	isInvalid(brewery: Brewery) {
		this.name = this.name ? this.name : '';
		this.lastName = this.lastName ? this.lastName : '';
		this.email = this.email ? this.email : '';
		this.phone = this.phone ? this.phone : '';

		this.name = this.name.trim();
		this.lastName = this.lastName.trim();
		this.email = this.email.trim();
		this.phone = this.phone.trim();

		if(!this.name || this.name.length < 1) {
			return `Please provide your first name for the order`;
		}
		if(!this.lastName || this.lastName.length < 1) {
			return `Please provide your last name for the order`;
		}
		if(brewery.settingCollectDateOfBirth) {
			var dob = moment(this.dateOfBirth);
			// if(!this.dateOfBirth || dob > this.maxDateOfBirth(brewery)) {
			// 	return `You must be ${brewery.alcoholAgeMinimumYears} years old to place an order. Make sure you enter a valid date of birth.`;
			// }
		}
		if(!this.email || this.email.length < 1) {
			return `Please provide an email address for the order`;
		}
		if(!this.validateEmail(this.email)) {
			return `Please provide a valid email address for the order`;
		}
		if(!this.dineIn) {
			if(!this.phone || this.phone.length < 1) {
				return `Please provide a phone number for the order`;
			}
			if(!this.validatePhone(this.phone)) {
				return `Please provide a valid phone number for the order with the area code`;
			}
		}
	}

	// Order validation before submitting
	isPaymentInvalid(brewery: Brewery) {
		if(
			(this.pickup && brewery.pickupTipEnabled) ||
			(this.delivery && brewery.deliveryTipEnabled) ||
			(this.dineIn)
		) {
			if(!this.tipSet) {
				return `Please select a tip amount`;
			}
		}
		if(this.tipAmount < 0) {
			return `Nice try! Please enter a valid tip amount.`;
		}
		if(this.tipAmount < 0) {
			return `Nice try! Please enter a valid tip amount.`;
		}
		// if(this.tipAmount > this.subTotal) {
		// 	return `We know you're generous but we're not going to let you tip more than your purchase.`;
		// }
		return this.isInvalid(brewery);
	}
}
