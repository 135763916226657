import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './components/loading/loading.component';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { FormsModule } from '@angular/forms';
import { PhonePipe } from './pipes/phone/phone.pipe';
import { TimePipe } from './pipes/time/time.pipe';
import { ProcessComponent } from './components/process/process.component';
import { OrderStatusComponent } from './components/order-status/order-status.component';
import { BreweryCardComponent } from './components/brewery-card/brewery-card.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { OrderStatusDeliveryComponent } from './components/order-status-delivery/order-status-delivery.component';
import { DeliveryComponent } from './components/process/delivery/delivery.component';
import { DonutComponent } from './components/donut/donut.component';
import { InputPercentageComponent } from './components/input-percentage/input-percentage.component';
import { SafePipe } from './pipes/safe/safe.pipe';

@NgModule({
	declarations: [
		LoadingComponent,
		AddressInputComponent,
		BreweryCardComponent,
		ProcessComponent,
		OrderStatusComponent,
		OrderStatusDeliveryComponent,
		DeliveryComponent,
		DonutComponent,
		InputPercentageComponent,

		PhonePipe,
		TimePipe,
		SafePipe,
	],
	imports: [
		CommonModule,
		FormsModule,
		HttpClientModule,
		RouterModule.forChild([]),
	],
	providers: [],
	exports: [
		LoadingComponent,
		AddressInputComponent,
		BreweryCardComponent,
		ProcessComponent,
		OrderStatusComponent,
		OrderStatusDeliveryComponent,
		DeliveryComponent,
		DonutComponent,
		InputPercentageComponent,

		// Pipes
		PhonePipe,
		TimePipe,
		SafePipe
	]
})
export class SharedModule {}
