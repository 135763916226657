import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User, Session, Brewery, BreweryPaymentType, Order, Delivery, Photo, Refund, Code, Category, Package, ShippingProvider, TaxRate, Subscription, UserSubscription, Membership, MembershipInvite, MembershipMember, BreweryLocation } from 'src/app/classes/biermi';
import { Observable } from 'rxjs';
import { Table } from 'src/app/classes/table/table';
import { Guild } from 'src/app/classes/guild/guild';
import { File as biermiFile } from 'src/app/classes/file/file';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(private ngZone: NgZone, public http: HttpClient) {}

	// Metadata

	breweryId;

	setBrewery(breweryId) {
		this.breweryId = breweryId;
	}

	// Analytics

	analyticsAddressSelect(address) {
		return this.http.post(`${environment.apiUrl}/analytics/address/select`, { address: address });
	}

	postAnalyticsEvent(eventTypeId, event) {
		return this.http.post(`${environment.apiUrl}/analytics/event/${eventTypeId}`, { event: JSON.stringify(event) });
	}

	postApplicationError(error) {
		return this.http.post(`${environment.apiUrl}/analytics/error`, { error: error });
	}

	// Contact

	postContactApplicationsDrivers(user: User) {
		return this.http.post(`${environment.apiUrl}/contact/applications/drivers`, user);
	}

	// Notices

	getPublicLegalNotices() {
		return this.http.get(`${environment.baseUrl}/licenses.json`);
	}

	// Administration

	getAdministrationOnboardingPipeline() {
		return this.http.get(`${environment.apiUrl}/administration/onboarding/pipeline`);
	}

	putAdministrationBrewery(brewery) {
		return this.http.put(`${environment.apiUrl}/administration/brewery`, brewery);
	}

	getAdministrationOrdersSearch(query, breweryId?) {
		var url = `${environment.apiUrl}/administration/orders/search?query=${encodeURI(query)}`;
		if(breweryId != null) {
			url += `&breweryId=${breweryId}`;
		}
		return this.http.get(url);
	}

	getAdministrationReportsSalesByBreweries() {
		return this.http.get(`${environment.apiUrl}/administration/reports/sales/brewery`);
	}

	getAdministrationInvoices() {
		return this.http.get(`${environment.apiUrl}/administration/invoices/list`);
	}

	billInvoice(invoice) {
		return this.http.put(`${environment.apiUrl}/administration/invoices/${invoice.id}`, {});
	}

	markInvoiceFailed(invoice) {
		return this.http.put(`${environment.apiUrl}/administration/invoices/${invoice.id}/fail`, {});
	}

	deleteInvoice(invoice) {
		return this.http.delete(`${environment.apiUrl}/administration/invoices/${invoice.id}`);
	}

	// Auth

	getMe(): Observable<User> {
		return this.http.get<User>(`${environment.apiUrl}/authentication/me`);  //, { withCredentials: true });
	}

	putMe(user) {
		return this.http.put(`${environment.apiUrl}/authentication/me`, user);
	}

	authRegister(user): Observable<Session> {
		return this.http.post<Session>(`${environment.apiUrl}/authentication/register`, user);
	}

	authLogin(user: User): Observable<Session> {
		return this.http.post<Session>(`${environment.apiUrl}/authentication/login`, user);
	}

	authCaptcha() {
		return this.http.get(`${environment.apiUrl}/authentication/captcha`);
	}

	authRefreshToken(refreshToken): Observable<Session> {
		return this.http.post<Session>(`${environment.apiUrl}/authentication/refresh`, {
			refreshToken: refreshToken
		});
	}

	authVerifyAccount(user: User) {
		return this.http.post(`${environment.apiUrl}/authentication/verification/verify`, user);
	}

	authVerifyAccountResend(user: User) {
		return this.http.post(`${environment.apiUrl}/authentication/verification/resend`, user);
	}

	authResetPassword(user: User): Observable<Session> {
		return this.http.post<Session>(`${environment.apiUrl}/authentication/reset`, user);
	}

	authResetPasswordTokenValidation(user: User) {
		return this.http.post(`${environment.apiUrl}/authentication/reset/token`, user);
	}

	authSetPassword(user: User): Observable<Session> {
		return this.http.post<Session>(`${environment.apiUrl}/authentication/set`, user);
	}

	// Users / Accounts

	getUser(id: number): Observable<User> {
		return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
	}

	getAccount() {
		return this.http.get<User>(`${environment.apiUrl}/accounts`);
	}

	putAccount(user) {
		return this.http.put(`${environment.apiUrl}/accounts`, user);
	}

	getAccountCards() {
		return this.http.get(`${environment.apiUrl}/accounts/cards/list`);
	}

	getAccountCardsByBrewery(breweryId) {
		return this.http.get(`${environment.apiUrl}/accounts/cards/brewery/${breweryId}`);
	}

	postAccountCard(card) {
		return this.http.post(`${environment.apiUrl}/accounts/cards`, card);
	}

	deleteAccountCard(card) {
		return this.http.delete(`${environment.apiUrl}/accounts/cards/${card.uuid}`);
	}

	getAccountOrders() {
		return this.http.get(`${environment.apiUrl}/accounts/orders`);
	}

	// Delivery Zones

	getBreweryDeliveryZones() {
		return this.http.get(`${environment.apiUrl}/delivery-zones/list/${this.breweryId}`);
	}

	getBreweryDeliveryZone(id) {
		return this.http.get(`${environment.apiUrl}/delivery-zones/${id}`);
	}

	postBreweryDeliveryZone(breweryDeliveryZone) {
		breweryDeliveryZone.breweryId = this.breweryId;
		return this.http.post(`${environment.apiUrl}/delivery-zones`, breweryDeliveryZone);
	}

	putBreweryDeliveryZone(breweryDeliveryZone) {
		return this.http.put(`${environment.apiUrl}/delivery-zones`, breweryDeliveryZone);
	}

	deleteBreweryDeliveryZone(breweryDeliveryZone) {
		return this.http.delete(`${environment.apiUrl}/delivery-zones/${breweryDeliveryZone.id}`);
	}

	// Gateway

	getGateway() {
		return this.http.get(`${environment.apiUrl}/gateway`);
	}

	// Search

	searchPostalCodes(postalCode) {
		return this.http.get(`${environment.apiUrl}/search/postal-code?postalCode=${postalCode}`);
	}

	search(latitude, longitude, stateCode) {
		return this.http.get(`${environment.apiUrl}/search?latitude=${latitude}&longitude=${longitude}&state=${stateCode}`);
	}

	// Breweries

	getBreweries(): Observable<Brewery[]> {
		return this.http.get<Brewery[]>(`${environment.apiUrl}/breweries`);
	}

	getBreweriesLive(): Observable<Brewery[]> {
		return this.http.get<Brewery[]>(`${environment.apiUrl}/breweries/filter/live`);
	}

	getNotLiveBreweriesByState(stateCode: string) {
		return this.http.get<Brewery[]>(`${environment.apiUrl}/breweries/filter/not-live?stateCode=${stateCode}`);
	}

	getBrewery(id?): Observable<Brewery> {
		return this.http.get<Brewery>(`${environment.apiUrl}/breweries/${id ? id : this.breweryId}`);
	}

	getBreweryBasic(id?): Observable<Brewery> {
		return this.http.get<Brewery>(`${environment.apiUrl}/breweries/${id ? id : this.breweryId}/basic`);
	}

	getBreweryBySlug(slug): Observable<Brewery> {
		return this.http.get<Brewery>(`${environment.apiUrl}/breweries/slug/${slug}`);
	}

	getBreweryBySlugBasic(slug): Observable<Brewery> {
		return this.http.get<Brewery>(`${environment.apiUrl}/breweries/slug/${slug}/basic`);
	}

	postBrewery(brewery: Brewery) {
		return this.http.post(`${environment.apiUrl}/breweries`, brewery);
	}

	putBrewery(brewery: Brewery) {
		return this.http.put(`${environment.apiUrl}/breweries`, brewery);
	}

	putBrewerySlug(brewery: Brewery) {
		return this.http.put(`${environment.apiUrl}/administration/breweries/slug`, brewery);
	}

	putBreweryLive(brewery: Brewery) {
		return this.http.put(`${environment.apiUrl}/administration/breweries/live`, brewery);
	}

	putBrewerySnooze(brewery: Brewery) {
		return this.http.put(`${environment.apiUrl}/administration/breweries/snooze`, brewery);
	}

	getAllBreweries(page) {
		return this.http.get(`${environment.apiUrl}/administration/all-breweries?page=${page}`);
	}

	putAllBreweries(brewery) {
		return this.http.put(`${environment.apiUrl}/administration/all-breweries`, brewery);
	}

	getBreweriesNeedingCustomerSync() {
		return this.http.get(`${environment.apiUrl}/accounts/customers/sync-needed`);
	}

	// Brewery Users

	getBreweryUsers() {
		return this.http.get(`${environment.apiUrl}/users/brewery/${this.breweryId}`);
	}

	putBreweryUser(breweryUser) {
		return this.http.put(`${environment.apiUrl}/users`, breweryUser);
	}

	deleteBreweryUser(breweryUser) {
		return this.http.delete(`${environment.apiUrl}/users/${breweryUser.breweryUserId}`);
	}

	postBreweryUser(user: User) {
		return this.http.post(`${environment.apiUrl}/users/invite`, {
			email: user.email,
			breweryId: this.breweryId
		});
	}

	putBreweryUserInvite(breweryUser) {
		return this.http.put(`${environment.apiUrl}/users/invite`, breweryUser);
	}

	deleteBreweryUserInvite(breweryUser) {
		return this.http.delete(`${environment.apiUrl}/users/invite/${breweryUser.id}`);
	}

	// Brewery Tax Rates

	postBreweryTaxRate(taxRate: TaxRate) {
		return this.http.post(`${environment.apiUrl}/breweries/${this.breweryId}/tax-rates`, taxRate);
	}

	deleteBreweryTaxRate(taxRate: TaxRate) {
		return this.http.delete(`${environment.apiUrl}/breweries/${this.breweryId}/tax-rates/${taxRate.id}`);
	}

	// Payment Methods

	postPaymentMethod(breweryPaymentType: BreweryPaymentType) {
		breweryPaymentType.breweryId = this.breweryId;
		return this.http.post(`${environment.apiUrl}/payment-methods`, breweryPaymentType);
	}

	deletePaymentMethod(breweryPaymentType: BreweryPaymentType) {
		return this.http.delete(`${environment.apiUrl}/payment-methods/${breweryPaymentType.id}`);
	}

	getPaymentFrame(breweryPaymentType: BreweryPaymentType) {
		if(breweryPaymentType.paymentTypeId == 4) {
			return this.http.get(`${environment.apiUrl}/payment-methods/frame/freedom-pay/${breweryPaymentType.id}`);
		}
	}

	putPaymentMethodDefaultExternalLocation(defaultLocation) {
		return this.http.put(`${environment.apiUrl}/payment-methods/locations/default`, defaultLocation);
	}

	// Locations

	getBreweryLocations() {
		return this.http.get(`${environment.apiUrl}/brewery-locations/list/${this.breweryId}`);
	}

	postBreweryLocation(location) {
		location.breweryId = this.breweryId;
		return this.http.post(`${environment.apiUrl}/brewery-locations`, location);
	}

	putBreweryLocation(location) {
		return this.http.put(`${environment.apiUrl}/brewery-locations`, location);
	}

	deleteBreweryLocation(location) {
		return this.http.delete(`${environment.apiUrl}/brewery-locations/${location.id}`);
	}

	// Beer

	getBeer(beerId) {
		return this.http.get(`${environment.apiUrl}/beers/${beerId}`);
	}

	getBeerArchived() {
		return this.http.get(`${environment.apiUrl}/beers/breweries/${this.breweryId}?archive=true`);
	}

	postBeer(beer) {
		beer.breweryId = this.breweryId;
		return this.http.post(`${environment.apiUrl}/beers`, beer);
	}

	putBeers(beers) {
		return this.http.put(`${environment.apiUrl}/beers/list`, { beers: beers });
	}

	putBeer(beer) {
		return this.http.put(`${environment.apiUrl}/beers/${beer.id}`, beer);
	}

	putBeerPublished(beer) {
		return this.http.put(`${environment.apiUrl}/beers/published`, beer);
	}

	putBeersInventory(beers) {
		return this.http.put(`${environment.apiUrl}/beers/inventory/list`, { beers: beers });
	}

	archiveBeer(beer) {
		return this.http.delete(`${environment.apiUrl}/beers/${beer.id}?archive=true`);
	}

	putBeerRessurect(beer) {
		return this.http.put(`${environment.apiUrl}/beers/${beer.id}/ressurect`, {});
	}

	deleteBeer(beer) {
		return this.http.delete(`${environment.apiUrl}/beers/${beer.id}`);
	}

	getInventoryUnfulfilled() {
		return this.http.get(`${environment.apiUrl}/breweries/${this.breweryId}/inventory/unfulfilled`);
	}

	getBeerPerformance(beer) {
		return this.http.get(`${environment.apiUrl}/beers/${beer.id}/performance`);
	}

	// Categories

	getCategories() {
		return this.http.get(`${environment.apiUrl}/categories/list/${this.breweryId}`);
	}

	postCategory(category: Category) {
		category.breweryId = this.breweryId;
		return this.http.post(`${environment.apiUrl}/categories`, category);
	}

	putCategory(category: Category) {
		return this.http.put(`${environment.apiUrl}/categories`, category);
	}

	putCategories(categories) {
		return this.http.put(`${environment.apiUrl}/categories/list`, { categories: categories });
	}

	deleteCategory(category: Category) {
		return this.http.delete(`${environment.apiUrl}/categories/${category.id}`);
	}

	// Packages

	getPackages() {
		return this.http.get(`${environment.apiUrl}/packages/list/${this.breweryId}`);
	}

	getPackage(packageId: number) {
		return this.http.get(`${environment.apiUrl}/packages/${packageId}`);
	}

	postPackage(pkg: Package) {
		pkg.breweryId = this.breweryId;
		return this.http.post(`${environment.apiUrl}/packages`, pkg);
	}

	putPackage(pkg: Package) {
		return this.http.put(`${environment.apiUrl}/packages`, pkg);
	}

	deletePackage(pkg: Package) {
		return this.http.delete(`${environment.apiUrl}/packages/${pkg.id}`);
	}

	postPackageConfiguration(pkg: Package, packages: Package[]) {
		return this.http.post(`${environment.apiUrl}/packages/${pkg.id}/configurations`, packages);
	}

	deletePackageConfiguration(pkg: Package, configuration: Package) {
		return this.http.delete(`${environment.apiUrl}/packages/${pkg.id}/configurations/${configuration.configurationId}`);
	}

	// Orders

	postOrder(order: Order) {
		return this.http.post(`${environment.apiUrl}/orders`, order);
	}

	getOrder(uuid: string) {
		return this.http.get(`${environment.apiUrl}/orders/${uuid}`);
	}

	getOrders(query:any = {}) {
		return this.http.get(`${environment.apiUrl}/breweries/${this.breweryId}/orders?page=${query ? query.page : ''}`);
	}

	getOrdersDelivery() {
		return this.http.get(`${environment.apiUrl}/breweries/${this.breweryId}/orders?delivery=true`);
	}

	getOrdersShipping(query) {
		return this.http.get(`${environment.apiUrl}/breweries/${this.breweryId}/orders?shipping=true&page=${query ? query.page : ''}`);
	}

	getOrdersDineIn() {
		return this.http.get(`${environment.apiUrl}/breweries/${this.breweryId}/orders?dineIn=true`);
	}

	updateOrder(order) {
		return this.http.put(`${environment.apiUrl}/orders/${order.id}`, order);
	}

	putOrderGeolocation(order: Order) {
		return this.http.put(`${environment.apiUrl}/orders/${order.id}/geolocation`, order);
	}

	putOrderCheckin(order: Order) {
		return this.http.put(`${environment.apiUrl}/orders/${order.id}/checkin`, order);
	}

	getRedemptionBarcode(uuid: string) {
		return this.http.get(`${environment.apiUrl}/barcodes/qr/${uuid}`);
	}

	getBreweryStoreBarcode(brewery: Brewery) {
		return this.http.get(`${environment.apiUrl}/barcodes/brewery/${brewery.id}`);
	}

	getBreweryLocationBarcode(breweryLocation: BreweryLocation) {
		return this.http.get(`${environment.apiUrl}/barcodes/brewery-location/${breweryLocation.id}`);
	}

	getTableBarcode(table: Table) {
		return this.http.get(`${environment.apiUrl}/barcodes/table-qr/${table.id}`);
	}

	getOrdersSearch(query: string) {
		return this.http.get(`${environment.apiUrl}/orders/search/${this.breweryId}?query=${query}`);
	}

	// Refunds

	postRefund(refund: Refund) {
		return this.http.post(`${environment.apiUrl}/refunds`, refund);
	}

	// Deliveries

	getDeliveries() {
		return this.http.get(`${environment.apiUrl}/deliveries/list/${this.breweryId}`);
	}

	getDelivery(id: number) {
		return this.http.get(`${environment.apiUrl}/deliveries/${id}`);
	}

	postDelivery(delivery: Delivery) {
		if(!delivery.breweryId) {
			delivery.breweryId = this.breweryId;
		}
		return this.http.post(`${environment.apiUrl}/deliveries`, delivery);
	}

	putDelivery(delivery: Delivery) {
		return this.http.put(`${environment.apiUrl}/deliveries`, delivery);
	}

	putDeliveryStatus(delivery: Delivery) {
		return this.http.put(`${environment.apiUrl}/deliveries/status`, delivery);
	}

	updateDeliveryLocation(locations) {
		return this.http.put(`${environment.apiUrl}/deliveries/location`, locations);
	}

	putDeliveryOrders(deliveryId, orders) {
		return this.http.put(`${environment.apiUrl}/deliveries/${deliveryId}/orders/list`, { deliveryOrders: orders });
	}

	deleteDeliveryOrder(deliveryId, order) {
		return this.http.delete(`${environment.apiUrl}/deliveries/${deliveryId}/orders/${order.id}`);
	}

	// Reports

	getReportsDaily(date: string) {
		return this.http.get(`${environment.apiUrl}/reports/${this.breweryId}/daily?date=${date}`);
	}

	getReport(filters) {
		return this.http.get(`${environment.apiUrl}/reports/${this.breweryId}/?start=${filters.start}&end=${filters.end}&page=${filters.page}`);
	}

	getReportDeliveries(filters) {
		return this.http.get(`${environment.apiUrl}/reports/${this.breweryId}/deliveries?start=${filters.start}&end=${filters.end}`);
	}

	getReportCustomers() {
		return this.http.get(`${environment.apiUrl}/reports/${this.breweryId}/customers`);
	}

	// Codes

	getCodes() {
		return this.http.get(`${environment.apiUrl}/codes/list/${this.breweryId}`);
	}

	getCode(id) {
		return this.http.get(`${environment.apiUrl}/codes/${id}`);
	}

	postCode(code: Code) {
		code.breweryId = this.breweryId;
		return this.http.post(`${environment.apiUrl}/codes`, code);
	}

	deleteCode(code: Code) {
		return this.http.delete(`${environment.apiUrl}/codes/${code.id}`);
	}

	getVerifyCode(breweryId, code) {
		return this.http.get(`${environment.apiUrl}/codes/verify/${breweryId}/${code}`);
	}

	// Subscriptions

	getSubscriptionsByBrewery() {
		return this.http.get(`${environment.apiUrl}/subscriptions/list/breweries/${this.breweryId}`);
	}

	getSubscriptionsByUser() {
		return this.http.get(`${environment.apiUrl}/subscriptions/list/users`);
	}

	postSubscription(subscription: Subscription) {
		subscription.breweryId = this.breweryId;
		return this.http.post(`${environment.apiUrl}/subscriptions`, subscription);
	}

	putSubscription(subscription: Subscription) {
		return this.http.put(`${environment.apiUrl}/subscriptions`, subscription);
	}

	deleteSubscription(subscription: Subscription) {
		return this.http.delete(`${environment.apiUrl}/subscriptions/${subscription.id}`);
	}

	getSubscriptionsBillable() {
		return this.http.get(`${environment.apiUrl}/subscriptions/list/billable`);
	}

	// User Subscriptions/billing

	getUserSubscription(userSubscriptionId: number) {
		return this.http.get(`${environment.apiUrl}/subscriptions/user-subscriptions/${userSubscriptionId}`);
	}

	putUserSubscription(userSubscription: UserSubscription) {
		return this.http.put(`${environment.apiUrl}/subscriptions/user-subscriptions/${userSubscription.id}`, userSubscription);
	}

	cancelUserSubscription(userSubscription: UserSubscription) {
		return this.http.delete(`${environment.apiUrl}/subscriptions/user-subscriptions/${userSubscription.userSubscriptionId}`);
	}

	postBillUserSubscription(userSubscription) {
		return this.http.post(`${environment.apiUrl}/subscriptions/user-subscriptions/${userSubscription.id}/bill`, {});
	}

	putUserSubscriptionBilledSuccess(userSubscription) {
		return this.http.put(`${environment.apiUrl}/subscriptions/user-subscriptions/${userSubscription.id}/success`, {});
	}

	// User Beer Subscriptions

	getUserBeerSubscriptions(beerId: number) {
		return this.http.get(`${environment.apiUrl}/beers/${beerId}/user-subscriptions`);
	}

	postUserBeerSubscription(beerId: number) {
		return this.http.post(`${environment.apiUrl}/beers/${beerId}/user-subscriptions`, {});
	}

	// Memberships

	getMemberships() {
		return this.http.get(`${environment.apiUrl}/memberships`);
	}

	getMembershipsByBreweryId() {
		return this.http.get(`${environment.apiUrl}/memberships/list/breweries/${this.breweryId}`);
	}

	getMembershipsByUser() {
		return this.http.get(`${environment.apiUrl}/accounts/memberships`);
	}

	getMembership(id: number) {
		return this.http.get(`${environment.apiUrl}/memberships/${id}`);
	}

	postMembership(membership: Membership) {
		membership.breweryId = this.breweryId;
		return this.http.post(`${environment.apiUrl}/memberships`, membership);
	}

	putMembership(membership: Membership) {
		return this.http.put(`${environment.apiUrl}/memberships`, membership);
	}

	deleteMembership(membership: Membership) {
		return this.http.delete(`${environment.apiUrl}/memberships/${membership.id}`);
	}

	postMembershipInvite(membership: Membership, invite: MembershipInvite) {
		return this.http.post(`${environment.apiUrl}/memberships/${membership.id}/invites`, invite);
	}

	deleteMembershipInvite(membership: Membership, invite: MembershipInvite) {
		return this.http.delete(`${environment.apiUrl}/memberships/${membership.id}/invites/${invite.id}`);
	}

	deleteMembershipUser(membership: Membership, member: MembershipMember) {
		return this.http.delete(`${environment.apiUrl}/memberships/${membership.id}/users/${member.id}`);
	}

	// Guilds

	getGuilds() {
		return this.http.get(`${environment.apiUrl}/accounts/guilds`);
	}

	getGuild(guildId) {
		return this.http.get(`${environment.apiUrl}/accounts/guilds/${guildId}`);
	}

	postGuild(guild: Guild) {
		return this.http.post(`${environment.apiUrl}/accounts/guilds`, guild);
	}

	// Integrations
	putIntegrationsUPSConnect(brewery, code) {
		return this.http.put(`${environment.apiUrl}/integrations/UPS/authentication/connect`, {
			breweryId: brewery.id,
			code: code
		});
	}

	putIntegrationsSquareConnect(brewery, code) {
		return this.http.put(`${environment.apiUrl}/integrations/square/authentication/connect`, {
			breweryId: brewery.id,
			code: code
		});
	}

	postIntegrationsCloverConnect(brewery, code, merchantId, employeeId) {
		return this.http.post(`${environment.apiUrlIntegrations}/clover/authentication/connect`, {
			breweryId: brewery.id,
			code: code,
			merchantId: merchantId,
			employeeId: employeeId
		});
	}

	putIntegrationsUntappdConnect(code) {
		return this.http.put(`${environment.apiUrl}/integrations/untappd/authentication/connect`, {
			code: code
		});
	}

	putIntegrationsUntappdConnectBusiness(code, brewery) {
		return this.http.put(`${environment.apiUrl}/integrations/untappd/authentication/business/connect`, {
			breweryId: brewery.id,
			code: code
		});
	}

	getUntappdBrewerySearch(brewery, search) {
		return this.http.get(`${environment.apiUrl}/untappd/breweries/${brewery.id}/search?search=${search}`);
	}

	getUntappdBeers() {
		return this.http.get(`${environment.apiUrl}/untappd/breweries/${this.breweryId}/beers`);
	}

	getUntappdBeer(bid: number) {
		return this.http.get(`${environment.apiUrl}/untappd/breweries/${this.breweryId}/beers/${bid}`);
	}

	getUntappdSearchBeers(search) {
		return this.http.get(`${environment.apiUrl}/untappd/breweries/${this.breweryId}/beers/search?search=${search}`);
	}

	postIntegrationsQuickbooksSync(breweryId: number) {
		return this.http.post(`${environment.apiUrlIntegrations}/quickbooks/sync`, {
			breweryId: breweryId
		});
	}

	// Shipping

	getShippingProviders() {
		return this.http.get(`${environment.apiUrl}/shipping/list/${this.breweryId}`);
	}

	postShippingProvider(shippingProvider: ShippingProvider) {
		shippingProvider.breweryId = this.breweryId;
		return this.http.post(`${environment.apiUrl}/shipping`, shippingProvider);
	}

	postShippingGetRate(order: Order) {
		return this.http.post(`${environment.apiUrl}/shipping/rate`, order);
	}

	postShippingAddressValidation(order: Order) {
		return this.http.post(`${environment.apiUrl}/shipping/address/${order.uuid}`, order);
	}

	postShippingCreateShipment(order: Order) {
		return this.http.post(`${environment.apiUrl}/shipping/shipment/${order.uuid}`, order);
	}

	// Shipping States + Parameters

	getShippingStates() {
		return this.http.get(`${environment.apiUrl}/shipping/states`);
	}

	getShippingParameters() {
		return this.http.get(`${environment.apiUrl}/shipping/parameters`);
	}

	getShippingParametersByState(stateCode) {
		return this.http.get(`${environment.apiUrl}/shipping/parameters/${stateCode}`);
	}

	getShippingStateLegislations() {
		return this.http.get(`${environment.apiUrl}/shipping/state-legislations`);
	}

	getShippingStateLegislationsByState(stateCode: string) {
		return this.http.get(`${environment.apiUrl}/shipping/state-legislations/${stateCode}`);
	}

	putShippingState(state) {
		return this.http.put(`${environment.apiUrl}/shipping/states`, state);
	}

	postShippingParameters(parameter) {
		return this.http.post(`${environment.apiUrl}/shipping/parameters`, parameter);
	}

	putShippingParameters(parameter) {
		return this.http.put(`${environment.apiUrl}/shipping/parameters`, parameter);
	}

	postShippingStateLegislations(legislation) {
		return this.http.post(`${environment.apiUrl}/shipping/state-legislations`, legislation);
	}

	putShippingStateLegislations(legislation) {
		return this.http.put(`${environment.apiUrl}/shipping/state-legislations`, legislation);
	}

	// Billing

	getBillingInvoices() {
		return this.http.get(`${environment.apiUrl}/breweries/${this.breweryId}/invoices/list`);
	}

	postBillingPaymentMethod(cardToken) {
		return this.http.post(`${environment.apiUrl}/breweries/${this.breweryId}/billing/cards`, {
			token: cardToken
		});
	}

	getBillingPaymentMethod() {
		return this.http.get(`${environment.apiUrl}/breweries/${this.breweryId}/billing/cards`);
	}

	// Square

	getSquareLocations(brewery?: number) {
		return this.http.get(`${environment.apiUrl}/square/breweries/${brewery ? brewery : this.breweryId}/locations`);
	}

	// Files

	getFilesByObjectType(objectType: number) {
		return this.http.get(`${environment.apiUrl}/files/breweries/${this.breweryId}/types/${objectType}`);
	}

	uploadFile(file: File, metadata: biermiFile) {
		return new Promise((resolve, reject) => {
			metadata.contentType = file.type;
			metadata.breweryId = this.breweryId;

			return this.http.post(`${environment.apiUrl}/files/actions/upload`, metadata).subscribe((fileDestination: biermiFile) => {
				var xhr = new XMLHttpRequest();
				xhr.open('PUT', fileDestination.url, true);
				xhr.onload = (event) => {
					this.ngZone.run(() => {
						return this.http.put(`${environment.apiUrl}/files/actions/upload`, { id: fileDestination.id }).subscribe(() => {
							resolve(fileDestination);
						}, error => {
							reject();
						});
					});
				};

				xhr.onerror = (event) => {
					this.ngZone.run(() => {
						reject();
					});
				};

				// Listen to the upload progress.
				xhr.upload.onprogress = (e) => {
					if (e.lengthComputable) {
						// progressBar.value = (e.loaded / e.total) * 100;
						// progressBar.textContent = progressBar.value; // Fallback for unsupported browsers.
					}
				};

				xhr.send(file);
			}, error => {
				reject(error);
			});
		});
	}

	// Photos

	uploadPhoto(photoFile: File, photo: Photo) {
		return new Promise((resolve, reject) => {
			photo.contentType = photoFile.type;
			return this.http.post(`${environment.apiUrl}/photos/actions/upload`, photo).subscribe((photoDestination: Photo) => {
				var xhr = new XMLHttpRequest();
				xhr.open('PUT', photoDestination.url, true);
				xhr.onload = (event) => {
					this.ngZone.run(() => {
						return this.http.put(`${environment.apiUrl}/photos/actions/upload`, { id: photoDestination.id }).subscribe(() => {
							resolve(photoDestination);
						}, error => {
							reject();
						});
					});
				};

				xhr.onerror = (event) => {
					this.ngZone.run(() => {
						reject();
					});
				};

				// Listen to the upload progress.
				xhr.upload.onprogress = (e) => {
					if (e.lengthComputable) {
						// progressBar.value = (e.loaded / e.total) * 100;
						// progressBar.textContent = progressBar.value; // Fallback for unsupported browsers.
					}
				};

				xhr.send(photoFile);
			}, error => {
				reject(error);
			});
		});
	}

	uploadPublicPhoto(file: File) {
		return new Promise((resolve, reject) => {
			return this.http.post(`${environment.apiUrl}/photos/actions/upload/public`, {
				contentType: file.type
			}).subscribe((photoDestination: Photo) => {
				var xhr = new XMLHttpRequest();
				xhr.open('PUT', photoDestination.url, true);
				xhr.onload = (event) => {
					this.ngZone.run(() => {
						resolve(photoDestination);
					});
				};

				xhr.onerror = (event) => {
					this.ngZone.run(() => {
						reject();
					});
				};

				// Listen to the upload progress.
				xhr.upload.onprogress = (e) => {
					if (e.lengthComputable) {
						// progressBar.value = (e.loaded / e.total) * 100;
						// progressBar.textContent = progressBar.value; // Fallback for unsupported browsers.
					}
				};

				xhr.send(file);
			}, error => {
				reject(error);
			});
		});
	}
}
