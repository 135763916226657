import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AnalyticsEvent } from 'src/app/classes/biermi';

declare var gtag: Function;
declare var ga: any;

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {

	constructor() { }

	sendEvent(eventName: string, event?: AnalyticsEvent) {
		gtag('event', eventName, event.getGTag());
	}

	sendPage(url: string) {
		gtag('config', environment.analytics.trackingId, {'page_path': url});
	}

	sendException(error: string, fatal: boolean = true) {
		gtag('event', 'exception', {
			'description': error,
			'fatal': fatal
		});
	}

	setUser(userid) {
		gtag('config', environment.analytics.trackingId, {
			'user_id': '' + userid
		});
	}

	getClientId() {
		try {
			var clientId;
			ga.getAll().forEach((tracker) => {
				clientId = tracker.get('clientId');
			});
			return clientId;
		} catch(e) {
			return '';
		}
	}
}
