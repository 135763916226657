import { Order } from '../order/order';

export class Delivery {
	id: number;
	title: string;
	breweryId: number;
	orders: Order[] = [];
	vehicleId: number;
	driverId: number;
	startAddress: string;
	startLatitude: number;
	startLongitude: number;
	endAddress: string;
	endLatitude: number;
	endLongitude: number;
	departureTime: Date;
	calculateArrivalTimesAutomatically: boolean = true;
	distance: number;
	duration: number;

	driverFirstName: string;
	driverLastNameInitial: string;

	statusLoading: boolean;
	statusOutForDelivery: boolean;

	picksheet;
	orderIds: number[];
	createdOn: Date;
}
