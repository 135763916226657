import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { GeographyService } from 'src/app/services/geography/geography.service';

@Component({
	selector: 'app-shipping',
	templateUrl: './shipping.component.html',
	styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {

	states;
	beverageType: string;
	stateCode: string;
	loading: boolean;
	legislations;
	parameters;

	constructor(private route: ActivatedRoute, private router: Router, private geography: GeographyService, private api: ApiService) { }

	ngOnInit() {
		this.states = this.geography.states;

		this.route.params.subscribe(params => {
			var beverageType = params['beverageType'];
			if(beverageType && ['beer', 'wine', 'spirits', 'cider'].find(t => t == beverageType)) {
				this.beverageType = beverageType;
			}
			if(params['state']) {
				var state = this.states.find(s => s.title.toLowerCase() == params['state'].split('-').join(' '));
				if(state) {
					this.stateCode = state.code;
				}
			}
			this.fetchContent();
		});
	}

	selectBeverage(beverage) {
		if(this.loading) {
			return;
		}
		this.beverageType = beverage;
		this.fetchContent();
	}

	selectState(state) {
		if(this.loading) {
			return;
		}
		this.stateCode = state.code;
		this.fetchContent();
		$('html, body').animate({
			scrollTop: 0
		}, 800);
	}

	fetchContent() {
		var state = this.states.find(s => s.code == this.stateCode);
		if(!this.beverageType || !state) {
			return;
		}
		this.router.navigate(['shipping', this.beverageType, state.title.toLowerCase().split(' ').join('-')]);
		this.loading = true;

		Promise.all([
			this.api.getShippingStateLegislationsByState(this.stateCode).toPromise(),
			this.api.getShippingParametersByState(this.stateCode).toPromise()
		]).then((results: any) => {
			this.legislations = results[0];
			this.parameters = results[1].filter(p => this.beverageType == 'beer' ? p.beer : this.beverageType == 'wine' ? p.wine : this.beverageType == 'spirits' ? p.spirits : this.beverageType == 'cider' ? p.cider : false);
			this.loading = false;
		}, error => {
			this.legislations = [];
			this.parameters = [];
			this.loading = false;
		});
	}
}
