import { Injectable } from '@angular/core';
import { Brewery, Place } from 'src/app/classes/biermi';

@Injectable({
	providedIn: 'root'
})
export class DeliveryZoneService {

	private states = [{
		title: 'Alabama',
		abbreviation: 'AL'
	}, {
		title: 'Alaska',
		abbreviation: 'AK'
	}, {
		title: 'Arizona',
		abbreviation: 'AZ'
	}, {
		title: 'Arkansas',
		abbreviation: 'AR'
	}, {
		title: 'California',
		abbreviation: 'CA'
	}, {
		title: 'Colorado',
		abbreviation: 'CO'
	}, {
		title: 'Connecticut',
		abbreviation: 'CT'
	}, {
		title: 'Delaware',
		abbreviation: 'DE'
	}, {
		title: 'Florida',
		abbreviation: 'FL'
	}, {
		title: 'Georgia',
		abbreviation: 'GA'
	}, {
		title: 'Hawaii',
		abbreviation: 'HI'
	}, {
		title: 'Idaho',
		abbreviation: 'ID'
	}, {
		title: 'Illinois',
		abbreviation: 'IL'
	}, {
		title: 'Indiana',
		abbreviation: 'IN'
	}, {
		title: 'Iowa',
		abbreviation: 'IA'
	}, {
		title: 'Kansas',
		abbreviation: 'KS'
	}, {
		title: 'Kentucky',
		abbreviation: 'KY'
	}, {
		title: 'Louisiana',
		abbreviation: 'LA'
	}, {
		title: 'Maine',
		abbreviation: 'ME'
	}, {
		title: 'Maryland',
		abbreviation: 'MD'
	}, {
		title: 'Massachusetts',
		abbreviation: 'MA'
	}, {
		title: 'Michigan',
		abbreviation: 'MI'
	}, {
		title: 'Minnesota',
		abbreviation: 'MN'
	}, {
		title: 'Mississippi',
		abbreviation: 'MS'
	}, {
		title: 'Missouri',
		abbreviation: 'MO'
	}, {
		title: 'Montana',
		abbreviation: 'MT'
	}, {
		title: 'Nebraska',
		abbreviation: 'NE'
	}, {
		title: 'Nevada',
		abbreviation: 'NV'
	}, {
		title: 'New Hampshire',
		abbreviation: 'NH'
	}, {
		title: 'New Jersey',
		abbreviation: 'NJ'
	}, {
		title: 'New Mexico',
		abbreviation: 'NM'
	}, {
		title: 'New York',
		abbreviation: 'NY'
	}, {
		title: 'North Carolina',
		abbreviation: 'NC'
	}, {
		title: 'North Dakota',
		abbreviation: 'ND'
	}, {
		title: 'Ohio',
		abbreviation: 'OH'
	}, {
		title: 'Oklahoma',
		abbreviation: 'OK'
	}, {
		title: 'Oregon',
		abbreviation: 'OR'
	}, {
		title: 'Pennsylvania',
		abbreviation: 'PA'
	}, {
		title: 'Rhode Island',
		abbreviation: 'RI'
	}, {
		title: 'South Carolina',
		abbreviation: 'SC'
	}, {
		title: 'South Dakota',
		abbreviation: 'SD'
	}, {
		title: 'Tennessee',
		abbreviation: 'TN'
	}, {
		title: 'Texas',
		abbreviation: 'TX'
	}, {
		title: 'Utah',
		abbreviation: 'UT'
	}, {
		title: 'Vermont',
		abbreviation: 'VT'
	}, {
		title: 'Virginia',
		abbreviation: 'VA'
	}, {
		title: 'Washington',
		abbreviation: 'WA'
	}, {
		title: 'West Virginia',
		abbreviation: 'WV'
	}, {
		title: 'Wisconsin',
		abbreviation: 'WI'
	}, {
		title: 'Wyoming',
		abbreviation: 'WY'
	// }, { // testing for shy bear shipping
	// 	title: 'Washington DC',
	// 	abbreviation: 'DC'
	}, {
		title: 'District of Columbia',
		abbreviation: 'DC'
	}];

	constructor() { }

	getDeliveryZone(brewery: Brewery, place: Place) {
		if(!brewery.deliveryEnabled || brewery.deliveryZones.length < 1) {
			return null;
		}
		return brewery.deliveryZones.sort((a, b) => { return a.deliveryFee < b.deliveryFee ? -1 : 1 }).find(deliveryZone => {

			// Maximum radius
			var originLatitude = deliveryZone.originLatitude ? deliveryZone.originLatitude : brewery.latitude;
			var originLongitude = deliveryZone.originLongitude ? deliveryZone.originLongitude : brewery.longitude;
			var miles = this.getDistanceKm(originLatitude, originLongitude, place.latitude, place.longitude) / 1.609;
			if(deliveryZone.maximumRadius && miles > deliveryZone.maximumRadius) {
				return false;
			}

			// State limit
			if(deliveryZone.stateLimit && deliveryZone.stateLimit != place.details.administrative_area_level_1) {
				return false;
			}

			if(deliveryZone.postalCode) {
				// Postal Code - handle wildcards
				var postalCode = deliveryZone.postalCode.split('*')[0];
				if(place.details && place.details.postal_code && place.details.postal_code.indexOf(postalCode) == 0) {
					return true;
				}
			} else if(deliveryZone.county) {
				// County
				if(
					place.details.country == deliveryZone.country &&
					place.details.administrative_area_level_1 == deliveryZone.state &&
					place.details.administrative_area_level_2 == deliveryZone.county
				) {
					return true;
				}
			} else if(deliveryZone.state) {
				// State
				if(
					place.details.country == deliveryZone.country &&
					place.details.administrative_area_level_1 == deliveryZone.state
				) {
					return true;
				}
			} else if(deliveryZone.radius) {
				// Radius
				if(miles <= deliveryZone.radius) {
					return true;
				}
			} else if(deliveryZone.minLatitude && deliveryZone.maxLatitude && deliveryZone.minLongitude && deliveryZone.maxLongitude) {
				// Box
				if(
					place.latitude >= deliveryZone.minLatitude &&
					place.latitude <= deliveryZone.maxLatitude &&
					place.longitude >= deliveryZone.minLongitude &&
					place.longitude <= deliveryZone.maxLongitude
				) {
					return true;
				}
			}

			return false;
		});
	}

	getShippingZone(brewery: Brewery, place: Place) {
		if(!brewery.shippingEnabled) {
			return;
		}
		var stateTitle = place.details.administrative_area_level_1;
		var mappedState = this.states.find(s => s.title == stateTitle);
		if(!mappedState) {
			return;
		}
		var shippingProviders = brewery.shippingProviders.filter(sp =>
			sp[`availableState${mappedState.abbreviation}`]
			|| sp[`availableWineState${mappedState.abbreviation}`]
			|| sp[`availableSpiritsState${mappedState.abbreviation}`]
			|| sp[`availableCiderState${mappedState.abbreviation}`]
			|| sp[`availableNonAlcoholState${mappedState.abbreviation}`]
		);
		if(shippingProviders.length < 1) {
			return;
		}
		return shippingProviders;
	}

	getDistanceKm(latitude1, longitude1, latitude2, longitude2) {
		var R = 6371; // km
		var dLat = this.toRadians(latitude2 - latitude1);
		var dLon = this.toRadians(longitude2 - longitude1);
		var lat1 = this.toRadians(latitude1);
		var lat2 = this.toRadians(latitude2);

		var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
			Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
		var d = R * c;
		return d;
    }

    // Converts numeric degrees to radians
    toRadians(Value) {
        return Value * Math.PI / 180;
    }
}
